import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { PersonSearchOptionData } from '@scholastic/volume-react';

import { useGetStudentOptions } from './use-get-student-options';

export interface UseCreateLoadOptionsProps {
  getStudentOptions: ReturnType<typeof useGetStudentOptions>;
}

export interface LoadOptionsProps {
  isSearchEnabled: boolean;
  name: string;
}

export type LoadOptions = (
  value: string,
  callback: (options: PersonSearchOptionData[]) => void,
) => void;

export type CreateLoadOptions = (props: LoadOptionsProps) => LoadOptions;

export const useCreateLoadOptions = ({
  getStudentOptions,
}: UseCreateLoadOptionsProps): CreateLoadOptions => {
  return useCallback(
    ({ isSearchEnabled, name }: LoadOptionsProps): LoadOptions => {
      return debounce((value, callback) => {
        // if search functionality is disabled, skip making the HTTP request
        if (!isSearchEnabled || !value) {
          callback([]);

          return;
        }
        return getStudentOptions({ [name]: value }).then(results => {
          callback(results);
          return results;
        });
      }, 300);
    },
    [getStudentOptions],
  );
};

export default useCreateLoadOptions;
