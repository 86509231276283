import React, { SyntheticEvent } from 'react';
import {
  Application,
  ProgramCard,
  Subscription,
  SubscriptionData,
  useClasses,
  useWindowQuery,
} from '@scholastic/volume-react';

import '../../components/ProgramCardList/ProgramCardList.scss';

export interface AdminProgramCardListProps {
  subscriptions: SubscriptionData[];
  onCardClick: (e: SyntheticEvent, application: Application) => void;
  onManageClick?: (e: SyntheticEvent, subscription: Subscription) => void;
}

export const AdminProgramCardList = ({
  subscriptions,
  onCardClick,
  onManageClick,
}: AdminProgramCardListProps) => {
  const baseClass = 'sdm-program-card-list';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const classes = useClasses({ baseClass }, [responsiveClassName]);

  return (
    <div className={classes}>
      <div className={`${baseClass}__inner`}>
        {subscriptions.map((subscriptionData: SubscriptionData) => (
          <ProgramCard
            key={subscriptionData.subscription.id}
            layout={isMedium ? 'large' : 'small'}
            subscriptionData={subscriptionData}
            onCardClick={onCardClick}
            onManageClick={onManageClick}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminProgramCardList;
