import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Button, Form, GradeKey, notify, Student, useWindowQuery } from '@scholastic/volume-react';

import { ClassDetailsProps } from '../ClassDetails';
import StudentForm, {
  getStudentFormSchema,
  StudentFormData,
} from '../../../components/StudentForm/StudentForm';
import Box from '../../../components/Box/Box';
import useOnInputChange from '../use-on-input-change';
import addPeriodToString from '../../../utils/add-period-to-string';
import './ClassDetailsEditStudentForm.scss';

export interface ClassDetailsEditStudentFormProps
  extends Required<Pick<ClassDetailsProps, 'updateStudent' | 'selectedClass'>> {
  className?: string;
  onCloseButtonClick?: () => void;
  selectedStudent: Student;
  selectedStudentAvatar: ReactNode;
  onCancelClick: () => void;
}

const ClassDetailsEditStudentForm = ({
  updateStudent,
  onCloseButtonClick,
  selectedClass,
  selectedStudent,
  selectedStudentAvatar,
  onCancelClick,
  className,
}: ClassDetailsEditStudentFormProps) => {
  const baseClass = 'sdm-class-details-edit-student-form';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  const {
    handleSubmit,
    errors,
    control,
    formState: { isValid },
    watch,
    getValues,
  } = useForm<StudentFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(getStudentFormSchema()),
    defaultValues: {
      firstName: selectedStudent.firstName,
      lastName: selectedStudent.lastName,
      studentId: selectedStudent.studentId,
      grade: selectedStudent.grade as GradeKey,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onInputChange = useOnInputChange();

  return (
    <Box
      className={classNames(responsiveClassName, className)}
      onCloseButtonClick={onCloseButtonClick}
      title={selectedStudentAvatar}
    >
      <Form
        className={`${baseClass}__form`}
        onSubmit={handleSubmit(async () => {
          const formData = getValues();

          formData.firstName = formData.firstName.trim();
          formData.lastName = formData.lastName.trim();
          formData.studentId = formData.studentId?.trim();

          const fullName = `${formData.firstName} ${formData.lastName}`;

          const modifiedSelectedStudent = {
            ...selectedStudent,
            firstName: formData.firstName,
            lastName: formData.lastName,
            grade: formData.grade as string,
            studentId: formData.studentId,
          };

          try {
            setIsLoading(true);

            await updateStudent(
              modifiedSelectedStudent,
              selectedClass.id,
              modifiedSelectedStudent.id,
            );

            onCancelClick();

            notify.success(
              <>
                Student <strong>{fullName}</strong> updated.
              </>,
            );
          } catch (e) {
            notify.warning(addPeriodToString(e.message));
          } finally {
            setIsLoading(false);
          }
        })}
      >
        <StudentForm
          onInputChange={onInputChange}
          watch={watch}
          control={control}
          errors={errors}
        />

        <div className={`${baseClass}__controls`}>
          <Button
            className={`${baseClass}__submit-button`}
            aria-label="Add student"
            color="primary"
            disabled={!isValid || isLoading}
            type="submit"
          >
            SAVE
          </Button>

          <Button
            className={`${baseClass}__cancel-button`}
            aria-label="Cancel add student"
            color="primary"
            link
            type="button"
            onClick={onCancelClick}
          >
            CANCEL
          </Button>
        </div>
      </Form>
    </Box>
  );
};

export default ClassDetailsEditStudentForm;
