import React from 'react';
import classNames from 'classnames';

import './CustomBulletList.scss';

export interface CustomBulletListProps {
  className?: string;
}

export const CustomBulletList = ({ className }: CustomBulletListProps) => {
  const baseClass = 'sdm-custom-bullet-list';

  const items = [
    'Pre-K to Grade 2 classes can log in quickly and easily',
    'Each student has a recognizable icon and picture password',
    'Less time spent logging in means more time spent learning',
  ];

  return (
    <ul className={classNames(baseClass, className)}>
      {items.map(item => (
        <li className={`${baseClass}__item`} key={item}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CustomBulletList;
