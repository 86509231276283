import React, { useMemo, useState } from 'react';
import {
  AvatarRole,
  FormFeedback,
  GradeKey,
  PersonSearch,
  PersonSearchOnInputChangeAction,
  PersonSearchProps,
} from '@scholastic/volume-react';
import { Controller } from 'react-hook-form';

import { StudentFormData, StudentFormProps } from '../StudentForm';
import { CreateLoadOptions } from '../../../views/ClassDetails/ClassDetailsAddStudentForm/use-create-load-options';
import { OnChange } from '../../../views/ClassDetails/ClassDetailsAddStudentForm/use-on-change';
import { OnInputChange } from '../../../views/ClassDetails/use-on-input-change';

export interface StudentFormInputProps extends Pick<StudentFormProps, 'formResetKey'> {
  name: keyof StudentFormData;
  label: string;
  placeholder: string;
  createLoadOptions?: CreateLoadOptions;
  onChange?: OnChange;
  onInputChange: OnInputChange;
  personSearchProps?: Partial<PersonSearchProps>;
  watch?: StudentFormProps['watch'];
  control: StudentFormProps['control'];
  errors: StudentFormProps['errors'];
  className?: string;
}

export interface StudentFormInputStudent {
  id: string;
  studentId?: string;
  firstName: string;
  lastName: string;
  active: boolean;
  grade: GradeKey;
  identifiers: { studentId: string };
}

export const StudentFormInput = ({
  formResetKey,
  label,
  name,
  placeholder,
  createLoadOptions,
  onChange,
  onInputChange,
  personSearchProps,
  watch,
  control,
  errors,
  className,
}: StudentFormInputProps) => {
  const baseClass = 'sdm-student-form';

  const isSearchable = !!createLoadOptions;

  const [isSearchEnabled, setIsSearchEnabled] = useState(isSearchable);

  const async = useMemo(() => {
    const loadOptions = createLoadOptions?.({ isSearchEnabled, name });

    return createLoadOptions ? { cache: true, loadOptions } : undefined;
  }, [createLoadOptions, isSearchEnabled]);

  return (
    <div className={className}>
      <label className={`${baseClass}__input-label`}>
        <span className={`${baseClass}__input-label-text`}>{label}</span>

        <Controller
          control={control}
          name={name}
          render={({ onChange: controllerOnChange }) => (
            <PersonSearch
              // the `key` prop is needed for resetting the
              // underlying <ReactSelect /> component
              //
              // @see https://stackoverflow.com/a/55142916
              key={formResetKey}
              className={`${baseClass}__input`}
              personRole={AvatarRole.Student}
              value={null}
              invalid={!!errors.grade}
              inputValue={watch?.(name)}
              name={name}
              placeholder={placeholder}
              noOptionsMessage={() => 'No results found'}
              openMenuOnClick={false}
              async={async}
              isLoading={isSearchEnabled ? undefined : false}
              menuIsOpen={isSearchEnabled ? undefined : false}
              onChange={onChange}
              onInputChange={(value: string, actionObject: PersonSearchOnInputChangeAction) => {
                onInputChange({ onChange: controllerOnChange, value, actionObject });

                if (isSearchable && !value) setIsSearchEnabled(true);
              }}
              menuButton={{ onClick: () => setIsSearchEnabled(false) }}
              styles={{ loadingIndicator: () => (isSearchEnabled ? {} : { display: 'none' }) }}
              persistInputValueOnBlur
              {...personSearchProps}
            />
          )}
        />
      </label>

      <FormFeedback className={`${baseClass}__feedback`}>{errors[name]?.message}</FormFeedback>
    </div>
  );
};

export default StudentFormInput;
