import React, { ReactNode } from 'react';
import {
  Button,
  ModalBody,
  ModalDialog,
  ModalHeader,
  useWindowQuery,
} from '@scholastic/volume-react';
import classNames from 'classnames';

import iconClose from '../../assets/img/icon-close.svg';
import './Box.scss';

export interface BoxProps {
  /**
   * Children
   */
  children: ReactNode;

  /**
   * Title
   */
  title: ReactNode;

  /**
   * Close button click handler
   */
  onCloseButtonClick?: () => void;

  /**
   * Additional className
   */
  className?: string;
}

const Box = ({ children, title, onCloseButtonClick, className }: BoxProps) => {
  const baseClass = 'sdm-box';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  return (
    <ModalDialog className={classNames(responsiveClassName, className)}>
      <ModalHeader
        className={`${baseClass}__modal-header`}
        close={
          onCloseButtonClick && (
            <Button
              className={`${baseClass}__close-button`}
              close
              type="button"
              aria-label="Navigate out"
              onClick={onCloseButtonClick}
            >
              <span aria-hidden="true">
                <img className={`${baseClass}__close-icon`} src={iconClose} alt="Navigate out" />
              </span>
            </Button>
          )
        }
      >
        {title}
      </ModalHeader>

      <ModalBody>{children}</ModalBody>
    </ModalDialog>
  );
};

export default Box;
