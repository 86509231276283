import React, { useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import {
  Button,
  Container,
  Form,
  FormGroup,
  ModalBody,
  ModalDialog,
  ModalHeader,
  Notifier,
  notify,
  registerDeferredNotification,
  ResponsivityProvider,
  Student,
  useWindowQuery,
} from '@scholastic/volume-react';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import FontAwesome from 'react-fontawesome';

import './StudentDetails.scss';
import StudentDetailsPageHeader from './StudentDetailsPageHeader/StudentDetailsPageHeader';
import {
  ClassDetailsSelectedClass,
  ClassDetailsSelectedSchool,
} from '../ClassDetails/ClassDetails';
import useGetStudentsWithAvatars, {
  GetStudentsInClassWithAvatarsType,
} from '../../behaviors/use-get-students-with-avatars/use-get-students-with-avatars';
import useGetShowEasyLogin from '../ClassDetails/use-get-show-easy-login';
import useGetSelectedClassWithEasyLoginInfo, {
  SelectedClassWithEasyLoginInfo,
} from '../ClassDetails/use-get-selected-class-with-easy-login-info';
import { LoginInfoPopoverClass } from '../../components/LoginInfoPopover/LoginInfoPopover';
import useGrades from '../../behaviors/use-grades/use-grades';
import CloseIcon from '../../assets/img/icon-close.svg';
import EditIcon from '../../assets/img/icon-pencil-dark.svg';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import useGetEasyLoginAvatars, {
  EasyLoginAvatar,
} from '../../behaviors/use-get-easy-login-avatars/use-get-easy-login-avatars';
import useGetEasyLoginSecrets from '../../behaviors/use-get-easy-login-secrets/use-get-easy-login-secrets';
import SelectFa from '../../components/SelectFa/SelectFa';
import capitalize from '../../utils/capitalize';

export interface StudentDetailsProps {
  isEditMode?: boolean;
  navigateTo: (path: string, shouldReplace?: boolean) => void;
  getEasyLoginInfo: (selectedClass?: ClassDetailsSelectedClass) => Promise<LoginInfoPopoverClass>;
  getShowEasyLogin: () => Promise<boolean>;
  getStudentsInClassWithAvatars: GetStudentsInClassWithAvatarsType;
  saveStudent: (selectedClassId: string, selectedStudent: Student) => Promise<Student>;
  school?: ClassDetailsSelectedSchool;
  selectedClass?: ClassDetailsSelectedClass;
  selectedStudentId?: string;
  removeStudentFromClass: (id: string) => Promise<void>;
}

export interface StudentDetailsInnerProps extends StudentDetailsProps {
  defaultFormValues: StudentDetailsFormData;
  selectedClassWithEasyLoginInfo: SelectedClassWithEasyLoginInfo;
  selectedStudent: Student;
}

export interface StudentDetailsEasyLoginAvatar {
  id: string;
  class: string;
  description: string;
  color: string;
}

export interface StudentDetailsFormData {
  firstName: string;
  lastName: string;
  grade: string;
  studentId: string;
  username: string;
  password: string;
  avatar?: StudentDetailsEasyLoginAvatar;
  secret?: StudentDetailsEasyLoginAvatar;
}

export const studentDetailsSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required()
    .label('First name'),
  lastName: yup
    .string()
    .trim()
    .required()
    .label('Last name'),
  grade: yup
    .string()
    .trim()
    .required()
    .label('Grade'),
  studentId: yup
    .string()
    .trim()
    .label('Student ID'),
  username: yup
    .string()
    .trim()
    .label('Username'),
  password: yup
    .string()
    .trim()
    .label('Password')
    .min(7)
    .required()
    .matches(/\d/, 'Password must contain at least one number'),
});

export const StudentDetails = (props: StudentDetailsProps) => {
  const {
    getEasyLoginInfo,
    getStudentsInClassWithAvatars,
    selectedClass,
    selectedStudentId,
  } = props;

  const studentsWithAvatars = useGetStudentsWithAvatars({
    getStudentsInClassWithAvatars,
    selectedClass,
  });

  const selectedClassWithEasyLoginInfo = useGetSelectedClassWithEasyLoginInfo({
    selectedClass,
    getEasyLoginInfo,
  });

  const selectedStudent = useMemo(
    () => studentsWithAvatars?.find(({ id }) => id === selectedStudentId),
    [selectedStudentId, studentsWithAvatars],
  );

  const [defaultFormValues, setDefaultFormValues] = useState<StudentDetailsFormData>();

  useEffect(() => {
    if (!selectedStudent) return;

    setDefaultFormValues({
      firstName: selectedStudent.firstName,
      lastName: selectedStudent.lastName,
      grade: selectedStudent.grade,
      studentId: selectedStudent.studentId || '',
      username: selectedStudent.credentials.username,
      password: selectedStudent.credentials.password,
      avatar: selectedStudent.avatar,
      secret: selectedStudent.secret,
    });
  }, [selectedStudent]);

  return defaultFormValues && selectedClassWithEasyLoginInfo && selectedStudent ? (
    <StudentDetailsInner
      {...props}
      defaultFormValues={defaultFormValues}
      selectedClassWithEasyLoginInfo={selectedClassWithEasyLoginInfo}
      selectedStudent={selectedStudent}
    />
  ) : null;
};

const StudentDetailsInner = ({
  isEditMode,
  navigateTo,
  defaultFormValues,
  getShowEasyLogin,
  saveStudent,
  school,
  selectedClass,
  selectedClassWithEasyLoginInfo,
  selectedStudent,
  removeStudentFromClass,
}: StudentDetailsInnerProps) => {
  const baseClass = 'sdm-student-details';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const showEasyLogin = useGetShowEasyLogin({ getShowEasyLogin });

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
    getValues,
    watch,
    trigger,
  } = useForm<StudentDetailsFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(studentDetailsSchema),
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (isEditMode) trigger();
  }, []);

  const mapIconToOption = ({ id, class: className, description, color }: EasyLoginAvatar) => ({
    id,
    label: startCase(toLower(description)),
    image: (
      <div className={`${baseClass}__fa-wrapper`} style={{ background: color }}>
        <FontAwesome
          className={`${baseClass}__fa fa-inverse`}
          name={className.replace(/fa-/, '')}
        />
      </div>
    ),
    value: { id, class: className, description, color },
  });

  const easyLoginAvatars = useGetEasyLoginAvatars();
  const avatarOptions = useMemo(() => easyLoginAvatars.map(mapIconToOption), [
    useGetEasyLoginAvatars,
  ]);

  const easyLoginSecrets = useGetEasyLoginSecrets();
  const secretOptions = useMemo(() => easyLoginSecrets.map(mapIconToOption), [
    useGetEasyLoginSecrets,
  ]);

  const sortedGrades = useGrades();

  const avatar = watch('avatar');

  const secret = watch('secret');

  const navigateToEditStudentPage = () => navigateTo(`/student/${selectedStudent.id}/edit`, true);

  const navigateToClassDetailsPage = () => navigateTo('/students/show-details');

  return (
    <div className={responsiveClassName} role="main">
      <StudentDetailsPageHeader
        classDisplayName={selectedClass?.displayName}
        schoolName={school?.name}
        selectedClass={selectedClassWithEasyLoginInfo}
        selectedStudent={selectedStudent}
        showEasyLogin={showEasyLogin}
        removeStudentFromClass={removeStudentFromClass}
        navigateToEditStudentPage={isEditMode ? undefined : navigateToEditStudentPage}
        studentId={selectedStudent.id}
        navigateToClassDetailsPage={navigateToClassDetailsPage}
      />

      <Container className={`${baseClass}__container`}>
        <ModalDialog className={`${baseClass}__dialog`}>
          <ModalHeader
            className={`${baseClass}__modal-header`}
            close={
              isEditMode ? (
                <Button
                  className={`${baseClass}__modal-button`}
                  close
                  type="button"
                  aria-label="Navigate out"
                  onClick={() => window.history.back()}
                >
                  <span className={`${baseClass}__modal-button-inner`} aria-hidden="true">
                    <img
                      className={`${baseClass}__modal-icon`}
                      src={CloseIcon}
                      alt="Navigate out"
                    />
                  </span>
                </Button>
              ) : (
                <Button
                  className={`${baseClass}__modal-button`}
                  close
                  type="button"
                  aria-label="Navigate to edit student page"
                  onClick={navigateToEditStudentPage}
                >
                  <span className={`${baseClass}__modal-button-inner`} aria-hidden="true">
                    <img className={`${baseClass}__modal-icon`} src={EditIcon} alt="Edit student" />
                  </span>
                </Button>
              )
            }
          >
            {isEditMode ? 'Edit Student Info' : 'Student Info'}
          </ModalHeader>

          <ModalBody>
            <Form
              className={`${baseClass}__form`}
              onSubmit={handleSubmit(async () => {
                const formValues = getValues();

                formValues.firstName = formValues.firstName.trim();
                formValues.lastName = formValues.lastName.trim();
                formValues.grade = formValues.grade.trim();
                formValues.studentId = formValues.studentId?.trim();
                formValues.username = formValues.username.trim();
                formValues.password = formValues.password.trim();

                const updatedStudent: Student = {
                  ...selectedStudent,
                  firstName: formValues.firstName,
                  lastName: formValues.lastName,
                  grade: formValues.grade,
                  studentId: formValues.studentId,
                  credentials: {
                    ...selectedStudent.credentials,
                    username: formValues.username,
                    password: formValues.password,
                    easyLoginAvatarId: avatar?.id || selectedStudent.credentials.easyLoginAvatarId,
                    easyLoginSecret: secret?.id || selectedStudent.credentials.easyLoginSecret,
                  },
                  avatar,
                  secret,
                };

                try {
                  await saveStudent(selectedClass?.id || '', updatedStudent);

                  registerDeferredNotification({
                    content: `Successfully updated <strong>${capitalize(
                      formValues.firstName,
                    )} ${capitalize(formValues.lastName)}</strong>`,
                    options: { type: 'success' },
                  });

                  navigateToClassDetailsPage();
                } catch (e) {
                  notify.warning(
                    'Unable to update student information. Please try again with correct values',
                  );
                }
              })}
            >
              <div className={`${baseClass}__section`}>
                <FormGroup className={`${baseClass}__form-group`}>
                  <Input
                    readOnly={!isEditMode}
                    label="First Name"
                    placeholder="Enter first name"
                    name="firstName"
                    maxLength={30}
                    error={errors.firstName}
                    ref={register}
                  />
                </FormGroup>

                <FormGroup className={`${baseClass}__form-group`}>
                  <Input
                    readOnly={!isEditMode}
                    label="Last Name"
                    placeholder="Enter last name"
                    name="lastName"
                    maxLength={30}
                    error={errors.lastName}
                    ref={register}
                  />
                </FormGroup>

                <div className={`${baseClass}__form-group-wrapper`}>
                  <FormGroup className={`${baseClass}__form-group ${baseClass}__form-group--half`}>
                    <Select
                      menuIsOpen={isEditMode ? undefined : false}
                      error={errors.grade}
                      label="Grade"
                      name="grade"
                      placeholder="Select grade"
                      control={control}
                      options={sortedGrades}
                      selectedOptionValue={watch('grade')}
                    />
                  </FormGroup>

                  <FormGroup className={`${baseClass}__form-group ${baseClass}__form-group--half`}>
                    <Input
                      readOnly={!isEditMode}
                      label="Student ID (optional)"
                      placeholder={isMedium ? 'ID' : 'Enter ID'}
                      name="studentId"
                      maxLength={255}
                      error={errors.studentId}
                      ref={register}
                    />
                  </FormGroup>
                </div>
              </div>

              <div className={`${baseClass}__section`}>
                <FormGroup className={`${baseClass}__form-group`}>
                  <Input
                    readOnly
                    label="Username"
                    placeholder="Username"
                    name="username"
                    error={errors.username}
                    ref={register}
                  />
                </FormGroup>

                <div className={`${baseClass}__form-group`}>
                  <Input
                    readOnly={!isEditMode}
                    label="Password"
                    placeholder="Password"
                    name="password"
                    maxLength={16}
                    error={errors.password}
                    ref={register}
                  />
                </div>

                {/* for stylistic purposes */}
                <div className={`${baseClass}__form-group`} />
              </div>

              {(avatar || secret) && (
                <>
                  <hr className={`${baseClass}__separator`} />

                  <h2 className={`${baseClass}__sub-title`}>Easy Login</h2>

                  <div className={`${baseClass}__section`}>
                    {avatar && (
                      <FormGroup className={`${baseClass}__form-group`}>
                        <SelectFa
                          name="avatar"
                          label="User Icon"
                          placeholder={isMedium ? 'Select avatar' : 'Avatar'}
                          options={avatarOptions}
                          selectedOption={avatarOptions.find(({ id }) => id === avatar.id)}
                          readOnly={!isEditMode}
                          control={control}
                          error={errors.avatar?.id}
                        />
                      </FormGroup>
                    )}

                    {secret && (
                      <FormGroup className={`${baseClass}__form-group`}>
                        <SelectFa
                          name="secret"
                          label="Picture Password"
                          placeholder={isMedium ? 'Select secret' : 'Secret'}
                          options={secretOptions}
                          selectedOption={secretOptions.find(({ id }) => id === secret.id)}
                          readOnly={!isEditMode}
                          control={control}
                          error={errors.secret?.id}
                        />
                      </FormGroup>
                    )}

                    {/* for stylistic purposes */}
                    <div className={`${baseClass}__form-group`} />
                  </div>
                </>
              )}

              {isEditMode && (
                <div className={`${baseClass}__controls`}>
                  <Button
                    className={`${baseClass}__submit-button`}
                    aria-label="Update student"
                    color="primary"
                    disabled={!isValid}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              )}
            </Form>
          </ModalBody>
        </ModalDialog>
      </Container>
    </div>
  );
};

export const StudentDetailsWrapper = (props: StudentDetailsProps) => (
  <ResponsivityProvider>
    <StudentDetails {...props} />

    <Notifier />
  </ResponsivityProvider>
);

export default StudentDetailsWrapper;
