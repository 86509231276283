import React from 'react';
import classNames from 'classnames';
import { useWindowQuery, CollapsibleList } from '@scholastic/volume-react';

import './FaqSection.scss';
import iconFaq from '../../../../assets/img/icon-faq.svg';

export interface EasyLoginSwitchSectionProps {
  className?: string;
}

export const FaqSection = ({ className }: EasyLoginSwitchSectionProps) => {
  const baseClass = 'sdm-faq-section';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  return (
    <div className={classNames(responsiveClassName, className)}>
      <h2 className={`${baseClass}__title`}>
        <img className={`${baseClass}__title-icon`} src={iconFaq} alt="FAQ Icon" />
        FAQs
      </h2>

      <CollapsibleList
        className={`${baseClass}__collapsible-list`}
        items={[
          {
            title: 'How can my students access SDM using QR codes?',
            description:
              'A QR code is an easy way for students to sign in to SDM. Students can scan the QR code using the camera on a compatible device and open the Easy Login page in a browser. At that point, students can select their icon and picture passwords to sign in.',
          },
          {
            title: 'Can I change a student’s icon or picture password?',
            description:
              'Yes! You can edit a student’s icon and picture password by editing that student’s details.',
          },
          {
            title: 'How do I save my login page to my device?',
            description:
              'Your Easy Login page is a specific URL we’ve assigned to your class or school. You can save this URL to your devices by copying and bookmarking it on all devices used by your students. You can also save the bookmark as an icon on the device’s homepage or desktop.',
          },
          {
            title: 'How do I print my student’s Easy Login information?',
            description:
              'Select GET LOGIN INFO above and select the Badges option. These badges include everything students need to access their accounts in the classroom, including their Easy Login QR codes, icons, and picture passwords.',
          },
        ]}
      />
    </div>
  );
};

export default FaqSection;
