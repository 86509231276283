/**
 * Returns two strings hyphenated if they're both set and unique
 */
export const hyphenateStrings = (a?: string, b?: string) => {
  if (a && !b) return a;

  if (!a && b) return b;

  if (a === b) return a;

  return `${a}-${b}`;
};

export default hyphenateStrings;
