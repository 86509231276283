import React from 'react';

import { useClasses, useWindowQuery } from '@scholastic/volume-react';
import VolumeComponentProps from '@scholastic/volume-react/dist/utils/props';

import emptyPrograms from '../../../assets/img/empty-programs.png';
import emptyPrograms2x from '../../../assets/img/empty-programs@2x.png';
import emptyPrograms3x from '../../../assets/img/empty-programs@3x.png';

import './ProgramCardListEmpty.scss';

export interface ProgramCardListEmptyProps extends VolumeComponentProps {
  isAddNewProgramDisabled?: boolean;
}

export const ProgramCardListEmpty = ({
  children,
  isAddNewProgramDisabled,
}: ProgramCardListEmptyProps) => {
  const baseClass = 'sdm-program-card-list-empty';
  const { responsiveClassName } = useWindowQuery({ className: baseClass });
  const classes = useClasses({ baseClass }, [responsiveClassName]);

  return (
    <div className={classes}>
      <img
        alt="Empty Programs List"
        src={`${emptyPrograms}`}
        srcSet={`${emptyPrograms2x} 2x, ${emptyPrograms3x} 3x`}
        className="sdm-program-card-list-empty__img"
      />

      {!isAddNewProgramDisabled && (
        <>
          <h2 className="sdm-program-card-list-empty__text">
            Looks empty here.
            <br />
            Let’s add your first program.
          </h2>

          {children}
        </>
      )}
    </div>
  );
};

export default ProgramCardListEmpty;
