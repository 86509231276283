import { UseFormMethods } from 'react-hook-form';
import {
  getStoreItem,
  registerDeferredNotification,
  useStoreItem,
  useUser,
  notify,
} from '@scholastic/volume-react';

import { TeacherClassCreateFormData, TeacherClassCreateInnerProps } from './TeacherClassCreate';

export interface UseCreateNewClassFactoryProps
  extends Pick<TeacherClassCreateInnerProps, 'createClass' | 'addTeacherToClass'> {
  getValues: UseFormMethods<TeacherClassCreateFormData>['getValues'];
  showSingleGradeInput: boolean;
}

export const useCreateNewClassFactory = ({
  getValues,
  createClass,
  showSingleGradeInput,
  addTeacherToClass,
}: UseCreateNewClassFactoryProps) => {
  const dpOrgId = getStoreItem('dpOrgId');

  const [portalUrl] = useStoreItem(
    'dpPortalUrl',
    getStoreItem('dpPortalUrl') || 'https://digital.scholastic.com',
  );

  const { id } = useUser();

  return async () => {
    const {
      organizationId,
      schoolCalendarId,
      nickname,
      lowGrade,
      highGrade,
      coTeachers,
    } = getValues();

    try {
      const { id: newClassId } = await createClass({
        organizationId,
        schoolCalendarId,
        nickname,
        lowGrade,
        highGrade: showSingleGradeInput ? lowGrade : highGrade,
        staff: { primaryTeacherId: id as string },
        easyLogin: { enabled: false },
      });

      await Promise.all(
        coTeachers
          .filter(({ value: { value } }) => value)
          .map(({ value: { value } }) => addTeacherToClass(newClassId, dpOrgId || '', value)),
      );

      registerDeferredNotification({
        content: `Class <strong>${nickname}</strong> created.`,
        options: { type: 'success' },
      });

      window.location.assign(`${portalUrl}/#/students/show-details`);
    } catch (e) {
      notify.warning(e.message);
    }
  };
};

export default useCreateNewClassFactory;
