import { useEffect, useState } from 'react';

import { LoginInfoPopoverClass } from '../../components/LoginInfoPopover/LoginInfoPopover';
import { ClassDetailsProps } from './ClassDetails';

export type UseGetSelectedClassWithEasyLoginInfoProps = Pick<
  ClassDetailsProps,
  'selectedClass' | 'getEasyLoginInfo'
>;

export interface SelectedClassWithEasyLoginInfo extends LoginInfoPopoverClass {
  avatar: {};
  easyLoginEnabled: boolean;
  finalHostUrl: string;
  teacherDisplayName: string;
}

export const useGetSelectedClassWithEasyLoginInfo = ({
  selectedClass,
  getEasyLoginInfo,
}: UseGetSelectedClassWithEasyLoginInfoProps) => {
  const [data, setData] = useState<SelectedClassWithEasyLoginInfo>();

  useEffect(() => {
    (async () => {
      const easyLoginInfo = await getEasyLoginInfo(selectedClass);

      setData({ ...selectedClass, ...easyLoginInfo });
    })();
  }, [getEasyLoginInfo, selectedClass]);

  return data;
};

export default useGetSelectedClassWithEasyLoginInfo;
