import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';

import downloadCsv from './download-csv';

export interface GetClassDetailsCSVStudent {
  firstName: string;
  lastName: string;
  studentId?: string;
  grade: string;
  username: string;
  password: string;
}

export interface GetClassDetailsCSVProps {
  fileName?: string;
  students?: GetClassDetailsCSVStudent[];
}

export const getClassDetailsCSV = ({ fileName, students }: GetClassDetailsCSVProps) => {
  const studentsWithCapitalizedNames = students?.map(({ firstName, lastName, ...rest }) => ({
    firstName: startCase(lowerCase(firstName)),
    lastName: startCase(lowerCase(lastName)),
    ...rest,
  }));

  downloadCsv({
    fileName: `${fileName}_class-list`,
    headings: ['First Name', 'Last Name', 'Student ID', 'Grade', 'Username', 'Password'],
    rows: orderBy(studentsWithCapitalizedNames, [
      'firstName',
      'lastName',
    ]).map(({ firstName, lastName, studentId, grade, username, password }) => [
      firstName,
      lastName,
      studentId || '',
      grade,
      username,
      password,
    ]),
  });
};

export default getClassDetailsCSV;
