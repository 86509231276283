import { useState, useEffect } from 'react';

import {
  ApplicationsResponseShape,
  ApplicationsResponse,
  UserContext,
  fetchWithToken,
  getStoreItem,
  getStoreObject,
  logError,
  validateDataShape,
  validateResponse,
} from '@scholastic/volume-react';

export const useStudentProgramsApi = () => {
  const [appsResponseData, setAppsResponseData] = useState<ApplicationsResponse>([]);

  useEffect(() => {
    const abortController = new AbortController();
    const user = getStoreObject<UserContext>('dpUserContext');
    const org = getStoreItem('dpOrgId');

    const fetchData = async () => {
      try {
        if (!user) {
          return;
        }

        const { student } = user;

        if (!student) {
          return;
        }

        const appsResponse = await fetchWithToken(`composite/student/schools/${org}/apps`, {
          signal: abortController.signal,
        });

        if (!appsResponse.ok) {
          throw new Error(
            `failed to fetch student applications:\n\t${appsResponse.status}: ${appsResponse.statusText}`,
          );
        }

        const applications: ApplicationsResponse = await appsResponse.json();

        if (validateResponse(applications)) {
          setAppsResponseData(validateDataShape(ApplicationsResponseShape, applications));
        }
      } catch (error) {
        throw new Error(`could not fetch user data for StudentPrograms:\n\t${error.message}`);
      }
    };

    fetchData().catch(logError);

    return () => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, []);

  if (typeof appsResponseData === 'string') {
    throw new TypeError('"content-type" header is not set to "application/json"');
  }

  return { appsResponseData };
};

export default useStudentProgramsApi;
