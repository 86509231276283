import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Organization, useDeepEqualEffect, useStoreItem } from '@scholastic/volume-react';
import equal from 'fast-deep-equal';
import capitalize from 'lodash/capitalize';

export interface OrganizationSelectOption {
  value: string;
  label: string;
}

export interface UseOrganizationsProps {
  organizationsUnstable: Organization[];
}

export interface UseOrganizationsReturn {
  organizationOptions: OrganizationSelectOption[];
  selectedOrganization?: OrganizationSelectOption;
  setSelectedOrganization: Dispatch<SetStateAction<OrganizationSelectOption | undefined>>;
}

export const useOrganizations = ({ organizationsUnstable }: UseOrganizationsProps) => {
  const [organizationsStable, setOrganizationsStable] = useState<Organization[]>(
    organizationsUnstable,
  );

  // because the `organizationsUnstable` is constantly re-created (its reference changes
  // on each render) we are unable to utilize it "as is". To circumvent this limitation
  // the `useDeepEqualEffect` below caches the said array and ensures that re-renders
  // happen only when the contents of the array are truly changed
  useDeepEqualEffect(() => {
    if (equal(organizationsStable, organizationsUnstable)) return;

    setOrganizationsStable(organizationsUnstable);
  }, [organizationsUnstable]);

  const organizationOptions = useMemo<OrganizationSelectOption[]>(
    () =>
      organizationsStable
        .map(({ id, name }) => ({
          value: id,
          label: name
            .replaceAll('+', ' ')
            .split(/\s/)
            .map(word => word.toLowerCase())
            .map(capitalize)
            .join(' '),
        }))
        .sort(({ label: a }, { label: b }) => a.localeCompare(b)),
    [organizationsStable],
  );

  const [orgId] = useStoreItem('dpOrgId');
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationSelectOption>();

  useEffect(() => {
    setSelectedOrganization(organizationOptions.find(({ value }) => value === orgId));
  }, [orgId, organizationOptions]);

  return {
    organizationOptions,
    selectedOrganization,
    setSelectedOrganization,
  };
};

export default useOrganizations;
