import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Application,
  Container,
  ResponsivityProvider,
  Subscription,
  SubscriptionData,
  SubscriptionResponseData,
  SubscriptionsResponse,
  useStoreItem,
  useWindowQuery,
  SectionHeader,
  PageHeader,
  PageHeaderProps,
} from '@scholastic/volume-react';

import useAdminProgramsApi from '../../behaviors/use-api/use-admin-programs-api/use-admin-programs-api';
import ProgramCardListEmpty from '../../components/ProgramCardList/ProgramCardListEmpty/ProgramCardListEmpty';
import isClassroomMagazine from '../../utils/is-classroom-magazine';
import AdminProgramCardList from './AdminProgramCardList';
import './AdminPrograms.scss';

export interface AdminProgramsProps {
  onOrgSelected: PageHeaderProps['onOrgSelected'];
  onCardClick: (e: SyntheticEvent, application: Application) => void;
  onManageClick: (e: SyntheticEvent, subscription: Subscription) => void;
  setInitialized: (initialized: boolean) => void;
}

export const mapAdminPrograms = (
  orgId: string | null,
  subscriptionsResponseData: SubscriptionsResponse,
): SubscriptionData[] => {
  if (subscriptionsResponseData.length) {
    const sortAppCode = 'lba';

    const subscriptionsData: SubscriptionData[] = subscriptionsResponseData
      .filter(({ organization }: SubscriptionResponseData) => organization.id === orgId)
      .flatMap(({ subscriptionAndProduct }: SubscriptionResponseData) => subscriptionAndProduct);

    const hasAppCode = subscriptionsData.some(
      ({ application }: SubscriptionData) => application.applicationCode === sortAppCode,
    );

    if (hasAppCode) {
      const subscriptionsMap: { [key: string]: SubscriptionData[] } = {};

      subscriptionsData.forEach((sub: SubscriptionData) => {
        const key = sub.application.applicationCode;
        if (!subscriptionsMap[key]) {
          subscriptionsMap[key] = [];
        }
        subscriptionsMap[key].push(sub);
      });

      if (subscriptionsMap[sortAppCode].length > 1) {
        const sortedSubscriptions: SubscriptionData[] = subscriptionsData.filter(
          ({ application }: SubscriptionData) => application.applicationCode !== sortAppCode,
        );

        subscriptionsMap[sortAppCode][0].product.name = '';
        sortedSubscriptions.push(subscriptionsMap[sortAppCode][0]);
        return sortedSubscriptions;
      } else {
        return subscriptionsData;
      }
    } else {
      return subscriptionsData;
    }
  }

  return [];
};

export const AdminPrograms = ({
  onOrgSelected,
  onCardClick,
  onManageClick,
  setInitialized,
}: AdminProgramsProps) => {
  const [subscriptions, setSubscriptions] = useState<SubscriptionData[]>();
  const [curriculumResources, setCurriculumResources] = useState<SubscriptionData[]>([]);
  const [classroomMagazines, setClassroomMagazines] = useState<SubscriptionData[]>([]);

  const [orgId] = useStoreItem('dpOrgId');

  const { subscriptionsResponseData } = useAdminProgramsApi();

  const baseClass = 'sdm-admin-programs';
  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  useEffect(() => {
    setInitialized(false);

    setSubscriptions(mapAdminPrograms(orgId, subscriptionsResponseData));

    setInitialized(true);
  }, [orgId, setInitialized, subscriptionsResponseData]);

  useEffect(() => {
    if (!subscriptions) return;

    const curriculumResourcesArr: SubscriptionData[] = [];
    const classroomMagazinesArr: SubscriptionData[] = [];

    subscriptions.forEach((subscription: SubscriptionData) => {
      if (isClassroomMagazine(subscription.application.rosterSupport)) {
        classroomMagazinesArr.push(subscription);
      } else {
        curriculumResourcesArr.push(subscription);
      }
    });

    setCurriculumResources(curriculumResourcesArr);
    setClassroomMagazines(classroomMagazinesArr);
  }, [subscriptions, setInitialized]);

  return (
    <>
      <PageHeader filterByRole showWelcomeMessage onOrgSelected={onOrgSelected} />

      <div className={responsiveClassName}>
        <Container className={`${baseClass}__container`}>
          {subscriptions ? (
            <>
              {/* Curriculum Resources */}
              {!!curriculumResources.length && (
                <>
                  <div className={`${baseClass}__header`}>
                    <SectionHeader tabs={[{ label: 'Curriculum Resources', value: '' }]} />
                  </div>

                  <AdminProgramCardList
                    subscriptions={curriculumResources}
                    onCardClick={onCardClick}
                    onManageClick={onManageClick}
                  />
                </>
              )}

              {/* Classroom Magazines */}
              {!!classroomMagazines.length && (
                <>
                  <div className={`${baseClass}__header`}>
                    <SectionHeader tabs={[{ label: 'Classroom Magazines', value: '' }]} />
                  </div>

                  <AdminProgramCardList
                    subscriptions={classroomMagazines}
                    onCardClick={onCardClick}
                    onManageClick={onManageClick}
                  />
                </>
              )}

              {/* Display "empty" page if there are no subscriptions */}
              {!curriculumResources.length && !classroomMagazines.length && (
                <ProgramCardListEmpty />
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <div
                role="status"
                className="vol-spinner vol-spinner__border--large vol-spinner__border"
              >
                <span className="sr-only">
                  <h1>Loading</h1>
                </span>
              </div>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export const AdminProgramsWrapper = (props: AdminProgramsProps) => (
  <ResponsivityProvider>
    <AdminPrograms {...props} />
  </ResponsivityProvider>
);

export default AdminProgramsWrapper;
