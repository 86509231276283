import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionCard,
  ActionCardLayout,
  ActionCardType,
  Avatar,
  AvatarRole,
  Container,
  FabItem,
  FabMenu,
  PersonCard,
  ProgramHero,
  ProgramHeroMetrics,
  programHeroSubscriptionsRefreshTrigger,
  ResponsivityProvider,
  SectionHeader,
  SectionHeaderTabProps,
  useClasses,
  useWindowQuery,
} from '@scholastic/volume-react';
import classNames from 'classnames';
import TrashIconURL from '@scholastic/volume-react/src/assets/icons/trash.svg';

import './AdminProgramDetails.scss';
import { render } from 'enzyme';

export interface AdminProgramDetailsTeacher {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface AdminProgramDetailsProps {
  subscriptionId: string;
  removeTeacher: (teacher: AdminProgramDetailsTeacher) => void;
  teachers?: AdminProgramDetailsTeacher[];
}

export const Teachers = ({
  teachers,
  removeTeacher,
}: Pick<AdminProgramDetailsProps, 'teachers' | 'removeTeacher'>) => {
  const { isMedium } = useWindowQuery({ className: '_' });

  return (
    <div className="sdm-admin-program-details__teachers">
      {teachers?.map(teacher => {
        const initials = `${teacher.firstName[0].toUpperCase()}${teacher.lastName[0].toUpperCase()}`;

        return (
          <PersonCard
            key={teacher.id}
            className="sdm-admin-program-details__teacher"
            layout={isMedium ? 'medium' : 'horizontal'}
            fullName={`${teacher.firstName} ${teacher.lastName}`}
            avatar={
              <Avatar
                colorKey={initials}
                role={AvatarRole.Teacher}
                size={isMedium ? undefined : 'small'}
              >
                {initials}
              </Avatar>
            }
            dropdownItems={[
              {
                label: 'Remove',
                onClick: () => removeTeacher(teacher),
                icon: <img alt="Trash Can Icon" src={TrashIconURL} />,
              },
            ]}
            grade=""
          />
        );
      })}

      {/*  TODO: Implement add teacher function */}
      {/* <ActionCard  */}
      {/*  className="sdm-admin-program-details__teacher" */}
      {/*  type={ActionCardType.User} */}
      {/*  layout={isMedium ? ActionCardLayout.Small : ActionCardLayout.Block} */}
      {/*  heading="ADD TEACHER" */}
      {/* /> */}

      {/* empty placeholder divs */}
      {[...Array(10)].map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="sdm-admin-program-details__teacher sdm-admin-program-details__teacher--placeholder"
        />
      ))}
    </div>
  );
};



export const AdminProgramDetails = ({
  subscriptionId,
  teachers,
  removeTeacher,
}: AdminProgramDetailsProps) => {
  const baseClass = 'sdm-admin-program-details';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const classes = useClasses({ baseClass }, [responsiveClassName]);

  const teachersCount = teachers?.length;

  useEffect( () => {

    
    console.log('teacher', teachers)
    console.log('teacher count', teachersCount)
  }, [teachersCount]

  );

  const tabs = useMemo<SectionHeaderTabProps[]>(() => {
    const teachersTab = { label: 'Teachers', value: 'teachers', badgeValue: teachersCount };
    const subscriptionInfoTab = { label: 'Subscription Info', value: 'subscription-info' };

    return isMedium ? [teachersTab] : [teachersTab, subscriptionInfoTab];
  }, [isMedium, teachersCount]);

  const [selectedTab, setSelectedTab] = useState<SectionHeaderTabProps>(tabs[0]);

  // reset the tab selection on resize
  useEffect(() => {
    if (isMedium) setSelectedTab(tabs[0]);
  }, [isMedium, tabs]);

  const programHeroMetricsContainerRef = useRef<HTMLDivElement>(null);

  const teachersAreShown = isMedium || selectedTab.value === tabs[0].value;

  return (
    <div className={classes}>
      <ProgramHero
        className="sdm-admin-program-details__hero"
        ref={programHeroMetricsContainerRef}
        subscriptionId={subscriptionId}
        bannerContent={
          undefined
          //  TODO: Implement add teacher function
          // teachersAreShown ? (
          //   <FabMenu
          //     className={classNames('sdm-admin-program-details__fab-menu', {
          //       'sdm-admin-program-details__menu--extra-small': !isMedium,
          //       'sdm-admin-program-details__menu--large': isMedium,
          //     })}
          //     placement="bottom"
          //   >
          //     <FabItem>Add new teacher</FabItem>
          //   </FabMenu>
          // ) : (
          //   undefined
          // )
        }
      />

      <Container className="sdm-admin-program-details__container">
        <SectionHeader
          className="sdm-admin-program-details__header"
          onSelect={option => setSelectedTab(option)}
          activeTab={selectedTab}
          tabs={tabs}
        />

        {/* Teachers */}
        <div
          className={classNames({
            'sdm-admin-program-details__teachers--hidden': !teachersAreShown,
          })}
        >
          <Teachers teachers={teachers} removeTeacher={removeTeacher} />
        </div>

        {/* Metrics */}
        <div
          className={classNames('sdm-admin-program-details__program-hero-metrics-container', {
            'sdm-admin-program-details__program-hero-metrics-container--hidden': teachersAreShown,
          })}
          ref={programHeroMetricsContainerRef}
        />
      </Container>
    </div>
  );
};

export const AdminProgramDetailsWrapper = (props: AdminProgramDetailsProps) => (
  <ResponsivityProvider>
    <AdminProgramDetails {...props} />
  </ResponsivityProvider>
);

export default AdminProgramDetailsWrapper;
