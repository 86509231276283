import React, { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import * as yup from 'yup';
import { useMediaQuery } from 'react-responsive';
import { Button, Checkbox, Form, FormFeedback, FormGroup } from '@scholastic/volume-react';

import './StepAgreement.scss';

const schema = yup.object().shape({
  subscriptionIsConfirmed: yup
    .boolean()
    .required()
    .oneOf([true], 'Subscription confirmation is required'),
  agreementIsConfirmed: yup
    .boolean()
    .required()
    .oneOf([true], 'Terms and agreements must be accepted'),
});

export interface StepAgreementFormData {
  subscriptionIsConfirmed: boolean;
  agreementIsConfirmed: boolean;
}

export interface StepAgreementProps {
  subscriptionProductName: string;
  schoolName: string;
  promptToAcceptLicence: boolean;
  goToNext: (params: { formData: StepAgreementFormData }) => void;
  goToPrevious: () => void;
  productLogo: ReactElement;
  className?: string;
}

const StepAgreement = ({
  subscriptionProductName,
  schoolName,
  promptToAcceptLicence,
  goToNext,
  goToPrevious,
  productLogo,
  className,
}: StepAgreementProps) => {
  const {
    getValues,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
  } = useForm<StepAgreementFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      subscriptionIsConfirmed: false,
      agreementIsConfirmed: !promptToAcceptLicence,
    },
  });

  const isSmall = useMediaQuery({ minWidth: 520 });
  const isMedium = useMediaQuery({ minWidth: 720 });
  const isLarge = useMediaQuery({ minWidth: 960 });
  const isExtraLarge = useMediaQuery({ minWidth: 1280 });

  return (
    <div
      className={classNames(className, 'sdm-step-agreement', {
        'sdm-step-agreement--small': isSmall,
        'sdm-step-agreement--medium': isMedium,
        'sdm-step-agreement--large': isLarge,
        'sdm-step-agreement--extra-large': isExtraLarge,
      })}
    >
      <div className="sdm-step-agreement__column sdm-step-agreement__column--left">
        {productLogo}
      </div>

      <div className="sdm-step-agreement__column sdm-step-agreement__column--right">
        <h1 className="sdm-step-agreement__title">Confirm your subscription</h1>

        <Form
          className="sdm-step-agreement__form"
          onSubmit={handleSubmit(() => {
            goToNext({ formData: getValues() });
          })}
        >
          <FormGroup className="sdm-step-agreement__form-group">
            <div className="sdm-step-agreement__checkbox-wrapper">
              <Controller
                control={control}
                name="subscriptionIsConfirmed"
                render={({ onChange, value }) => (
                  <Checkbox
                    className="sdm-step-agreement__checkbox"
                    aria-label="Confirm subscription"
                    checked={value}
                    onInputChange={() => onChange(!value)}
                  />
                )}
              />

              <span className="sdm-step-agreement__label-text">
                I have a subscription for {subscriptionProductName} with{' '}
                <strong>{schoolName}</strong>
              </span>
            </div>

            <FormFeedback className="sdm-step-agreement__feedback">
              {errors.subscriptionIsConfirmed?.message}
            </FormFeedback>
          </FormGroup>

          <FormGroup
            className={classNames('sdm-step-agreement__form-group', {
              'sdm-step-agreement__form-group--hidden': !promptToAcceptLicence,
            })}
          >
            <div className="sdm-step-agreement__checkbox-wrapper">
              <Controller
                control={control}
                name="agreementIsConfirmed"
                render={({ onChange, value }) => (
                  <Checkbox
                    className="sdm-step-agreement__checkbox"
                    aria-label="Agree to terms and conditions"
                    checked={value}
                    onInputChange={() => onChange(!value)}
                  />
                )}
              />

              <span className="sdm-step-agreement__label-text">
                I have read and accept the{' '}
                <a
                  className="sdm-step-agreement__link"
                  href="resources/js/Modules/licenseAccept/licenseAcceptPDF/ProductTerms.pdf"
                  target="_blank"
                >
                  Product Terms
                </a>{' '}
                and{' '}
                <a
                  className="sdm-step-agreement__link"
                  href="resources/js/Modules/licenseAccept/licenseAcceptPDF/IndividualCustAgreement.pdf"
                  target="_blank"
                >
                  Individual Customer Agreement.
                </a>
              </span>
            </div>

            <FormFeedback className="sdm-step-agreement__feedback">
              {errors.agreementIsConfirmed?.message}
            </FormFeedback>
          </FormGroup>

          <div className="sdm-step-agreement__button-wrapper">
            <Button
              className="sdm-step-agreement__submit-button"
              color="primary"
              type="submit"
              disabled={!isValid}
            >
              CONFIRM
            </Button>

            <Button
              className="sdm-step-agreement__back-button"
              link
              color="primary"
              type="button"
              onClick={goToPrevious}
            >
              Previous Step
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StepAgreement;
