import { useState } from 'react';
import createTrigger from 'react-use-trigger';
import useTriggerEffect from 'react-use-trigger/useTriggerEffect';

import {
  EntitlementsResponse,
  EntitlementsResponseShape,
  UserContext,
  fetchWithToken,
  getStoreObject,
  logError,
  validateDataShape,
  validateResponse,
} from '@scholastic/volume-react';

export const teacherProgramsRefreshTrigger = createTrigger();

export const useTeacherProgramsApi = () => {
  const [entitlementsResponseData, setEntitlementsResponseData] = useState<EntitlementsResponse>(
    [],
  );

  useTriggerEffect(() => {
    const abortController = new AbortController();
    const user = getStoreObject<UserContext>('dpUserContext');

    const fetchData = async () => {
      try {
        if (!user) {
          return;
        }

        const { staff } = user;

        if (!staff) {
          return;
        }

        const entitlementsResponse = await fetchWithToken('composite/staff/entitlements', {
          signal: abortController.signal,
        });

        if (!entitlementsResponse.ok) {
          throw new Error(
            `failed to fetch staff entitlements:\n\t${entitlementsResponse.status}: ${entitlementsResponse.statusText}`,
          );
        }

        const entitlements: EntitlementsResponse = await entitlementsResponse.json();

        if (validateResponse(entitlements)) {
          setEntitlementsResponseData(validateDataShape(EntitlementsResponseShape, entitlements));
        }
      } catch (error) {
        throw new Error(`could not fetch user data for TeacherPrograms:\n\t${error.message}`);
      }
    };

    fetchData().catch(logError);

    return () => {
      if (!abortController.signal.aborted) {
        abortController.abort();
      }
    };
  }, teacherProgramsRefreshTrigger);

  if (typeof entitlementsResponseData === 'string') {
    throw new TypeError('"content-type" header is not set to "application/json"');
  }

  return { entitlementsResponseData };
};

export default useTeacherProgramsApi;
