import { useCallback } from 'react';
import { PersonSearchOptionData } from '@scholastic/volume-react';

import { StudentFormInputStudent } from '../../../components/StudentForm/StudentFormInput/StudentFormInput';
import {
  ClassDetailsAddStudentFormProps,
  notifyAddStudentError,
  notifyAddStudentSuccess,
} from './ClassDetailsAddStudentForm';

export interface UseOnChangeProps
  extends Pick<ClassDetailsAddStudentFormProps, 'addExistingStudent' | 'selectedClass'> {
  resetForm: () => void;
}

export type OnChange = (
  option?: PersonSearchOptionData & { originalStudentObject: StudentFormInputStudent },
) => void;

export const useOnChange = ({ addExistingStudent, selectedClass, resetForm }: UseOnChangeProps) => {
  const onStudentSelect = useCallback(
    async (selectedStudent: StudentFormInputStudent) => {
      const fullName = `${selectedStudent.firstName} ${selectedStudent.lastName}`;

      try {
        await addExistingStudent(selectedStudent, selectedClass?.id as string);

        notifyAddStudentSuccess(fullName);
      } catch (e) {
        notifyAddStudentError(fullName);
      }
    },
    [addExistingStudent, selectedClass],
  );

  const onChange: OnChange = useCallback(
    option => {
      if (!option) return;

      onStudentSelect(option.originalStudentObject);

      resetForm();
    },
    [onStudentSelect, resetForm],
  );

  return onChange;
};

export default useOnChange;
