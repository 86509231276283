import { useMemo } from 'react';
import { GradeKey, Grades } from '@scholastic/volume-react';

export const GRADES_ORDER: Record<GradeKey, number> = Object.freeze({
  pk: 0,
  k: 1,
  '1': 2,
  '2': 3,
  '3': 4,
  '4': 5,
  '5': 6,
  '6': 7,
  '7': 8,
  '8': 9,
  '9': 10,
  '10': 11,
  '11': 12,
  '12': 13,
});

export const useGrades = () => {
  return useMemo(
    () =>
      Object.keys(Grades)
        .map(key => ({ value: key, label: Grades[key as GradeKey] }))
        .sort(
          ({ value: a }, { value: b }) => GRADES_ORDER[a as GradeKey] - GRADES_ORDER[b as GradeKey],
        ),
    [],
  );
};

export default useGrades;
