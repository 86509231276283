import React, { useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import {
  ClassSection,
  IconButton,
  Popover,
  PopoverBody,
  SmallScreenStrategy,
  Student,
  useWindowQuery,
} from '@scholastic/volume-react';

import iconKey from '../../assets/img/icon-key.svg';
import iconHandouts from '../../assets/img/icon-handouts.svg';
import iconBadges from '../../assets/img/icon-badges.svg';
import iconClassList from '../../assets/img/icon-classlist.svg';

import StudentHandouts from './StudentHandouts/StudentHandouts';
import StudentBadges from './StudentBadges/StudentBadges';
import ClassList from './ClassList/ClassList';
import './LoginInfoPopover.scss';

export interface LoginInfoPopoverClass extends ClassSection {
  teacherDisplayName: string;
  avatar: { name?: string };
  easyLoginEnabled: boolean;
  finalHostUrl: string;
  easyLoginUrl?: string;
  schoolEasyLoginEnabled?: boolean;
}

export interface LoginInfoPopoverProps {
  students: Student[];
  selectedClass: LoginInfoPopoverClass;
  showEasyLogin: boolean;
  buttonClassName?: string;
  popoverClassName?: string;
  shouldRender?: boolean;
  isSingleStudentMode?: boolean;
}

export const LoginInfoPopover = ({
  students,
  selectedClass,
  showEasyLogin,
  buttonClassName,
  popoverClassName,
  shouldRender = true,
  isSingleStudentMode,
}: LoginInfoPopoverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { responsiveClassName, isMedium } = useWindowQuery({ className: 'sdm-login-info-popover' });

  if (!shouldRender) {
    return null;
  }

  const id = useMemo(() => `id-${nanoid()}`, []);

  return (
    <>
      <IconButton
        id={id}
        className={buttonClassName}
        renderIcon={() => (
          <div className="sdm-login-info-popover__icon-wrapper">
            <img className="sdm-login-info-popover__icon" src={iconKey} alt="Key Icon" />
          </div>
        )}
      >
        Get Login Info
      </IconButton>

      <Popover
        className={classNames('sdm-login-info-popover-override', popoverClassName)}
        container="inline"
        target={id}
        placement="bottom"
        boundariesElement="scrollParent"
        smallScreenStrategy={SmallScreenStrategy.ShowPopOut}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <PopoverBody className={responsiveClassName}>
          {[
            {
              key: '1',
              icon: <img alt="Handouts Icon" src={iconHandouts} />,
              title: isSingleStudentMode ? 'Handout' : 'Handouts',
              button: <StudentHandouts students={students} />,
              textMobile: isSingleStudentMode
                ? 'Instructional letter for family on how student can log in remotely'
                : 'Instructional letters for families on how students can log in remotely',
              textDesktop: isSingleStudentMode
                ? 'letter instructing family how to access student account at home'
                : 'letters instructing families how to access student accounts at home',
            },
            {
              key: '2',
              icon: <img alt="Badges Icon" src={iconBadges} />,
              title: isSingleStudentMode ? 'Badge' : 'Badges',
              button: (
                <StudentBadges
                  isSingleStudentMode={isSingleStudentMode}
                  students={students}
                  selectedClass={selectedClass}
                  showEasyLogin={showEasyLogin}
                />
              ),
              textMobile: isSingleStudentMode
                ? 'Student badge showing everything needed to log in from the classroom'
                : 'Student badges showing everything needed to log in from the classroom',
              textDesktop: isSingleStudentMode
                ? 'badge with everything a student needs to access their account in the classroom'
                : 'badges with everything students need to access their accounts in the classroom',
            },
            ...(isSingleStudentMode
              ? []
              : [
                  {
                    key: '3',
                    icon: <img alt="Class List Icon" src={iconClassList} />,
                    title: 'Class List',
                    button: <ClassList students={students} selectedClass={selectedClass} />,
                    textMobile:
                      'A spreadsheet with all your students’ login info for your reference',
                    textDesktop: 'spreadsheet with student login info you can copy and paste',
                  },
                ]),
          ].map(({ key, icon, title, button, textMobile, textDesktop }) => (
            <div key={key} className="sdm-login-info-popover__card">
              <div className="sdm-login-info-popover__logo-wrapper">{icon}</div>

              <div className="sdm-login-info-popover__text-container">
                <h2 className="sdm-login-info-popover__title">{title}</h2>

                {isMedium ? (
                  <p className="sdm-login-info-popover__text">
                    {button} {textDesktop}
                  </p>
                ) : (
                  <>
                    <p className="sdm-login-info-popover__text">{textMobile}</p>

                    {button}
                  </>
                )}
              </div>
            </div>
          ))}
        </PopoverBody>
      </Popover>
    </>
  );
};

export default LoginInfoPopover;
