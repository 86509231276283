import React from 'react';
import { Student } from '@scholastic/volume-react';

import './ClassList.scss';
import { LoginInfoPopoverClass } from '../LoginInfoPopover';
import getClassDetailsCSV from '../../../utils/get-class-details-csv';

export interface ClassListProps {
  students: Student[];
  selectedClass?: Pick<
    LoginInfoPopoverClass,
    'avatar' | 'displayName' | 'nickname' | 'teacherDisplayName'
  >;
}

export const ClassList = ({ students, selectedClass }: ClassListProps) => (
  <button
    className="sdm-class-list__print-button"
    type="button"
    onClick={() => {
      if (!(students.length > 0 && selectedClass)) return;

      getClassDetailsCSV({
        fileName: selectedClass.displayName,
        students: students.map(
          ({ studentId, grade, firstName, lastName, credentials: { username, password } }) => ({
            firstName,
            lastName,
            studentId,
            grade,
            username,
            password,
          }),
        ),
      });
    }}
  >
    Download
  </button>
);

export default ClassList;
