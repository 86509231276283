import './styles/styles.scss';

import 'whatwg-fetch';
import 'abortcontroller-polyfill';
import './polyfills/proxy.min';
import 'string.prototype.replaceall/auto';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import {
  GlobalNav,
  PageHeader,
  ProgramHero,
  ResponsivityProvider,
  deleteStoreItem,
  getStoreItem,
  getStoreObject,
  getUserDetails,
  globalNavRefreshTrigger,
  organizationSelectRefreshTrigger,
  setStoreItem,
  setStoreObject,
  programHeroSubscriptionsRefreshTrigger
} from '@scholastic/volume-react';

import SdmHeader from './components/SdmHeader/SdmHeader';

import AdminProgramDetails from './views/AdminProgramDetails/AdminProgramDetails';
import AdminPrograms from './views/AdminPrograms/AdminPrograms';
import ClassDetails from './views/ClassDetails/ClassDetails';
import StudentDetails from './views/StudentDetails/StudentDetails';
import StudentPrograms from './views/StudentPrograms/StudentPrograms';
import TeacherClassCreate from './views/TeacherClassCreate/TeacherClassCreate';
import TeacherClasses from './views/TeacherClasses/TeacherClasses';
import TeacherPrograms from './views/TeacherPrograms/TeacherPrograms';

export {
  AdminProgramDetails,
  AdminPrograms,
  ClassDetails,
  GlobalNav,
  PageHeader,
  ProgramHero,
  ResponsivityProvider,
  SdmHeader,
  StudentDetails,
  StudentPrograms,
  TeacherClassCreate,
  TeacherClasses,
  TeacherPrograms,
  deleteStoreItem,
  getStoreItem,
  getStoreObject,
  getUserDetails,
  globalNavRefreshTrigger,
  organizationSelectRefreshTrigger,
  setStoreItem,
  setStoreObject,
  programHeroSubscriptionsRefreshTrigger
};
