import { useEffect, useState } from 'react';

import { ClassDetailsPageHeaderProps } from '../../views/ClassDetails/ClassDetailsPageHeader/ClassDetailsPageHeader';
import { Student } from '@scholastic/volume-react';

export type GetStudentsInClassWithAvatarsType = (selectedClass: {
  id: string;
  easyLogin: { enabled: boolean };
}) => Promise<Student[]>;

export interface UseGetStudentsWithAvatarsProps {
  getStudentsInClassWithAvatars?: GetStudentsInClassWithAvatarsType;
  selectedClass?: Parameters<GetStudentsInClassWithAvatarsType>[0];
}

export const useGetStudentsWithAvatars = ({
  selectedClass,
  getStudentsInClassWithAvatars,
}: UseGetStudentsWithAvatarsProps) => {
  const [studentsWithAvatars, setStudentsWithAvatars] = useState<
    ClassDetailsPageHeaderProps['students']
  >([]);

  useEffect(() => {
    if (!getStudentsInClassWithAvatars || !selectedClass?.id) return;

    (async () => setStudentsWithAvatars(await getStudentsInClassWithAvatars(selectedClass)))();
  }, [getStudentsInClassWithAvatars, selectedClass]);

  return studentsWithAvatars;
};

export default useGetStudentsWithAvatars;
