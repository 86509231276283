import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { Student } from '@scholastic/volume-react';

import './StudentHandouts.scss';
import StudentHandoutsContent from './StudentHandoutsContent';

export interface StudentHandoutsProps {
  students: Student[];
}

export const StudentHandouts = ({ students }: StudentHandoutsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Class Credential Handouts",
    content: () => ref.current
  })

  return (
    <>
      <button className="sdm-student-handouts__print-button" type="button" onClick={handlePrint}>
        Print
      </button>
      <StudentHandoutsContent ref={ref} students={students} />
    </>
  );
};

export default StudentHandouts;
