import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import {
  IconButton,
  Popover,
  PopoverBody,
  SmallScreenStrategy,
  Tooltip,
  useWindowQuery,
} from '@scholastic/volume-react';
import iconQuestion from '@scholastic/volume-react/src/assets/icons/question.svg';

import './ShareDevicesSection.scss';
import iconRestricted from '../../../../assets/img/icon-restricted.svg';
import iconRestrictedLight from '../../../../assets/img/icon-restricted-light.svg';
import iconCheck from '../../../../assets/img/icon-check.svg';
import iconCheckLight from '../../../../assets/img/icon-check-light.svg';

export interface EasyLoginSwitchSectionProps {
  className?: string;
  areDevicesShared: boolean;
  toggleAreDevicesShared: (toggleState: boolean) => void;
}

export const ShareDevicesSection = ({
  className,
  areDevicesShared,
  toggleAreDevicesShared,
}: EasyLoginSwitchSectionProps) => {
  const baseClass = 'sdm-share-devices-section';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const accessCodeHelpRef = useRef<HTMLButtonElement>(null);
  const [accessCodeHelpOpen, setAccessCodeHelpOpen] = useState(false);

  return (
    <div className={classNames(responsiveClassName, className)}>
      <h2 className={`${baseClass}__title`}>
        <span className={`${baseClass}__title-text`}>Share devices with other classes?</span>

        <button
          type="button"
          className={`${baseClass}__help`}
          aria-controls="sdmShareDevicesHelp"
          aria-expanded={accessCodeHelpOpen}
          aria-haspopup="menu"
          tabIndex={0}
          ref={accessCodeHelpRef}
          data-for="sdm-share-devices__tooltip"
          data-tip="Help"
          data-event="mouseover"
          data-event-off="click mouseleave"
          onClick={e => {
            // prevent the input field focus
            // when clicking the question mark
            e.preventDefault();
          }}
        >
          <img className={`${baseClass}__help-icon`} alt="Help" src={iconQuestion} />
        </button>
      </h2>

      <div className={`${baseClass}__buttons-wrapper`}>
        <IconButton
          className={`${baseClass}__button`}
          onClick={() => toggleAreDevicesShared(false)}
          renderIcon={({ isSelected }) => (
            <img
              className={`${baseClass}__icon`}
              src={isSelected ? iconRestrictedLight : iconRestricted}
              alt="Restricted Icon"
            />
          )}
          selected={!areDevicesShared}
        >
          No
          {!areDevicesShared && (
            <div className={`${baseClass}__arrow-wrapper`}>
              <div className={`${baseClass}__arrow`} />
            </div>
          )}
        </IconButton>

        <IconButton
          className={`${baseClass}__button`}
          onClick={() => toggleAreDevicesShared(true)}
          renderIcon={({ isSelected }) => (
            <img
              className={`${baseClass}__icon`}
              src={isSelected ? iconCheckLight : iconCheck}
              alt="Check Icon"
            />
          )}
          selected={areDevicesShared}
        >
          Yes
          {areDevicesShared && (
            <div className={`${baseClass}__arrow-wrapper`}>
              <div className={`${baseClass}__arrow`} />
            </div>
          )}
        </IconButton>
      </div>

      <Popover
        id="sdmShareDevicesHelp"
        isOpen={accessCodeHelpOpen}
        placement={isMedium ? 'right-start' : 'auto'}
        popperClassName={`${baseClass}__popover`}
        target={accessCodeHelpRef}
        toggle={() => setAccessCodeHelpOpen(!accessCodeHelpOpen)}
        trigger="click"
        smallScreenStrategy={SmallScreenStrategy.ShowDialog}
      >
        <PopoverBody>
          <p className={`${baseClass}__popover-text`}>
            Select NO if your classroom has its own devices.
          </p>

          <p className={`${baseClass}__popover-text`}>
            Select YES if you share devices with other classrooms. This option asks students to
            select their class before signing in with an icon and picture passwords, enabling
            students in different classes to still access Easy Login on a shared device.
          </p>
        </PopoverBody>
      </Popover>

      <Tooltip id="sdm-share-devices__tooltip" disable={accessCodeHelpOpen} />
    </div>
  );
};

export default ShareDevicesSection;
