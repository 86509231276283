import React, { useCallback, useEffect, useRef, useState, ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useMediaQuery } from 'react-responsive';

import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';

import classNames from 'classnames';
import * as yup from 'yup';

import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Popover,
  PopoverBody,
  SearchableDropdown,
  SearchableDropdownOption,
  Tooltip,
  SmallScreenStrategy,
} from '@scholastic/volume-react';
import QuestionIcon from '@scholastic/volume-react/src/assets/icons/question.svg';

import { SchoolWithoutId } from '../../../views/TeacherPrograms/TeacherPrograms';
import './StepConfirmSubscription.scss';

const schema = yup.object().shape({
  schoolZipCode: yup
    .string()
    .label('School zip code')
    .transform(value => value || null)
    .nullable()
    .test(
      'length',
      // eslint-disable-next-line no-template-curly-in-string
      '${path} must be exactly 5 characters long',
      value => !value || value.length === 5,
    ),
  school: yup.object().shape({
    name: yup.string(),
    ucn: yup.string().required('Select a school'),
  }),
});

export interface StepConfirmSubscriptionFormData {
  schoolZipCode: string;
  school: {
    name: string;
    ucn: string;
  };
}

export interface StepConfirmSubscriptionMetaData {
  schools: SchoolWithoutId[];
  homeSchoolIsSelected: boolean;
  schoolFromZipSearchIsSelected: boolean;
  defaultSchoolFromProfileIsSelected: boolean;
}

export interface StepConfirmSubscriptionProps {
  goToNext: (props: {
    formData: StepConfirmSubscriptionFormData;
    metaData: StepConfirmSubscriptionMetaData;
  }) => void;
  subscriptionProductName: string;
  searchOrgsByZip: (zip: string) => Promise<SchoolWithoutId[]>;
  productLogo: ReactElement;
  homeSchoolUcn?: string;
  className?: string;
}

// filter out schools that are duplicated by both `name` and `ucn`
export const getUniqueSchools = (schools: SchoolWithoutId[]) =>
  uniqBy(schools, ({ name, identifiers: { ucn } }) => `${name}-${ucn}`);

export const generateUniqueDropdownItems = (schools: SchoolWithoutId[]) => {
  const uniqueSchools = getUniqueSchools(schools);

  return uniqueSchools
    .map(({ identifiers: { ucn }, name }) => ({
      value: ucn,
      label: name.replaceAll('+', ' '),
    }))
    .sort(({ label: labelA }, { label: labelB }) => labelA.localeCompare(labelB));
};

const StepConfirmSubscription = ({
  goToNext,
  subscriptionProductName,
  searchOrgsByZip,
  productLogo,
  homeSchoolUcn,
  className,
}: StepConfirmSubscriptionProps) => {
  const defaultValues: StepConfirmSubscriptionFormData = {
    schoolZipCode: '',
    school: { name: '', ucn: '' },
  };

  const {
    register,
    watch,
    setError,
    setValue,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
  } = useForm<StepConfirmSubscriptionFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const isSmall = useMediaQuery({ minWidth: 520 });
  const isMedium = useMediaQuery({ minWidth: 720 });
  const isLarge = useMediaQuery({ minWidth: 960 });
  const isExtraLarge = useMediaQuery({ minWidth: 1280 });

  const schoolNameHelpRef = useRef<HTMLDivElement>(null);
  const [schoolNameHelpOpen, setSchoolNameHelpOpen] = useState(false);

  const [defaultDropdownValue, setDefaultDropdownValue] = useState<string>();
  const zip = watch('schoolZipCode', '');

  // untransformed schools, returned directly from the API
  const [schools, setSchools] = useState<SchoolWithoutId[]>([]);

  // transformed and de-duped schools
  const [dropdownItems, setDropdownItems] = useState<SearchableDropdownOption[]>([]);

  // fetches schools and sets them as dropdown options. The function is
  // debounced so it doesn't get called too many times in a short time frame
  const populateSchoolsDropdownDebounced = useCallback(
    debounce(
      async (zipCode: string) => {
        // abort if the school zip code is incorrect
        if (!zipCode || errors.schoolZipCode) return;

        try {
          const orgsFoundByZip = await searchOrgsByZip(zipCode);

          if (!orgsFoundByZip.length) {
            setError('schoolZipCode', { type: 'manual', message: 'No schools found' });
          }

          setSchools(orgsFoundByZip);
          setDropdownItems(generateUniqueDropdownItems(orgsFoundByZip));
        } catch (error) {
          setError('schoolZipCode', { type: 'manual', message: 'Schools could not be fetched' });
        }
      },
      1000,
      { trailing: true, leading: true },
    ),
    [],
  );

  // initiates a re-fetch of schools each time the zip changes
  useEffect(() => {
    (async () => {
      await populateSchoolsDropdownDebounced(zip);
    })();
  }, [populateSchoolsDropdownDebounced, zip]);

  return (
    <div
      className={classNames(className, 'sdm-step-confirm-subscription', {
        'sdm-step-confirm-subscription--small': isSmall,
        'sdm-step-confirm-subscription--medium': isMedium,
        'sdm-step-confirm-subscription--large': isLarge,
        'sdm-step-confirm-subscription--extra-large': isExtraLarge,
      })}
    >
      <div className="sdm-step-confirm-subscription__column sdm-step-confirm-subscription__column--left">
        {productLogo}
      </div>

      <div className="sdm-step-confirm-subscription__column sdm-step-confirm-subscription__column--right">
        <h1 className="sdm-step-confirm-subscription__title">Confirm your subscription</h1>

        <p className="sdm-step-confirm-subscription__description">
          Please confirm your school before activating your{' '}
          <strong>{subscriptionProductName}</strong> subscription.
        </p>

        <Form
          className="sdm-step-confirm-subscription__form"
          onSubmit={handleSubmit(() => {
            const formData = watch();

            goToNext({
              formData,
              metaData: {
                schools,
                homeSchoolIsSelected: formData.school.ucn === homeSchoolUcn,
                schoolFromZipSearchIsSelected: !!schools.length,
                defaultSchoolFromProfileIsSelected: false,
              },
            });
          })}
        >
          <FormGroup className="sdm-step-confirm-subscription__form-group">
            <label
              className="vol-form__label sdm-step-confirm-subscription__label"
              htmlFor="schoolZipCodeInput"
            >
              <span className="sdm-step-confirm-subscription__label-text-wrapper">
                School ZIP Code
              </span>

              <input
                className={classNames('vol-input sdm-step-confirm-subscription__input', {
                  'vol-input--invalid': errors.schoolZipCode,
                })}
                id="schoolZipCodeInput"
                name="schoolZipCode"
                placeholder="12345"
                onChange={event => {
                  setDropdownItems([]);
                  setDefaultDropdownValue(undefined);
                  setValue('school', defaultValues.school);
                }}
                ref={register}
              />

              <div className="sdm-step-confirm-subscription__home-school-button-wrapper">
                <Button
                  link
                  color="default"
                  className="sdm-step-confirm-subscription__home-school-button"
                  type="button"
                  onClick={() => {
                    setDropdownItems([{ label: 'Home School', value: homeSchoolUcn || '' }]);
                    setDefaultDropdownValue(homeSchoolUcn);
                    setValue('schoolZipCode', '');
                    setValue(
                      'school',
                      { name: 'Home School', ucn: homeSchoolUcn },
                      { shouldValidate: true, shouldDirty: true },
                    );
                  }}
                >
                  My school is a home school
                </Button>
              </div>

              <FormFeedback className="sdm-step-confirm-subscription__feedback">
                {errors.schoolZipCode?.message}
              </FormFeedback>
            </label>

            <span className="vol-form__label sdm-step-confirm-subscription__label">
              <span className="sdm-step-confirm-subscription__label-text-wrapper">
                School Name
                <div
                  className="sdm-step-confirm-subscription__help"
                  aria-controls="sdmRedeemAccessModalSchoolNameHelp"
                  aria-expanded={schoolNameHelpOpen}
                  aria-haspopup="menu"
                  role="button"
                  tabIndex={0}
                  ref={schoolNameHelpRef}
                  data-for="sdm-step-confirm-subscription__tooltip"
                  data-tip="Help"
                  data-event="mouseover"
                  data-event-off="click mouseleave"
                >
                  <img alt="Help" src={QuestionIcon} />
                </div>
              </span>

              <Controller
                control={control}
                name="school"
                render={({ onChange }) => (
                  <SearchableDropdown
                    className="sdm-step-confirm-subscription__dropdown"
                    placeholder="Choose your school"
                    disabled={!dropdownItems.length}
                    invalid={!!errors.school?.ucn}
                    items={dropdownItems}
                    onItemSelect={({ label: name, value: ucn }) => onChange({ name, ucn })}
                    defaultValue={defaultDropdownValue}
                  />
                )}
              />

              <FormFeedback className="sdm-step-confirm-subscription__feedback">
                {errors.school?.ucn?.message}
              </FormFeedback>
            </span>

            <Popover
              id="sdmRedeemAccessModalSchoolNameHelp"
              isOpen={schoolNameHelpOpen}
              placement={isMedium ? 'right-start' : 'auto'}
              popperClassName="sdm-step-confirm-subscription__help-popover"
              target={schoolNameHelpRef}
              toggle={() => setSchoolNameHelpOpen(!schoolNameHelpOpen)}
              trigger="click"
              smallScreenStrategy={SmallScreenStrategy.ShowDialog}
            >
              <PopoverBody>
                <p>
                  If your school or district has purchased a digital product for your classroom,
                  please ask your administrator to provide the access code.
                </p>
                <p>
                  If you are a Classroom Magazines subscriber, you will receive the access code via
                  email, or find it in your Teacher&apos;s Guide.
                </p>
                <p>
                  You can also contact Customer Support by phone at{' '}
                  <a href="tel:18668268834">1-866-826-8834</a> or email at{' '}
                  <a href="mailto:digitalservice@scholastic.com">digitalservice@scholastic.com</a>.
                </p>
              </PopoverBody>
            </Popover>

            <Tooltip id="sdm-step-confirm-subscription__tooltip" disable={schoolNameHelpOpen} />
          </FormGroup>

          <Button
            className="sdm-step-confirm-subscription__submit-button"
            color="primary"
            type="submit"
            disabled={!isValid}
          >
            NEXT
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default StepConfirmSubscription;
