import React from 'react';
import { createConfirmationDialog } from '@scholastic/volume-react';

import {
  ClassDetailsPageHeaderProps,
  notifyArchiveClassError,
  notifyArchiveClassSuccess,
} from './ClassDetailsPageHeader';

export interface CreateArchiveClassDialogProps {
  classDisplayName: ClassDetailsPageHeaderProps['classDisplayName'];
  setActive: ClassDetailsPageHeaderProps['setActive'];
}

export const createArchiveClassDialog = ({
  classDisplayName,
  setActive,
}: CreateArchiveClassDialogProps) =>
  createConfirmationDialog({
    title: (
      <>
        Archive Class <strong>{classDisplayName}</strong>?
      </>
    ),
    message: 'Archived classes can’t be updated unless you restore them.',
    confirmButtonTitle: 'Archive',
    cancelButtonTitle: 'Cancel',
    onConfirm: async () => {
      try {
        await setActive(false);

        notifyArchiveClassSuccess(classDisplayName || '');
      } catch (e) {
        notifyArchiveClassError(classDisplayName || '');
      }
    },
  });

export default createArchiveClassDialog;
