import { useEffect, useMemo, useState } from 'react';
import { GradeKey, useStoreObject } from '@scholastic/volume-react';

import { ClassDetailsProps } from './ClassDetails';
import isInRange from '../../utils/is-in-range';

export interface UseShowEasyLogin extends Pick<ClassDetailsProps, 'selectedClass'> {}

const gradesDictionary: Record<GradeKey, number> = {
  pk: -1,
  k: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
};

export const useShowEasyLogin = ({ selectedClass }: UseShowEasyLogin) => {
  const [easyLoginTipsDictionary] = useStoreObject<Record<string, boolean>>(
    'sdmEasyLoginTipsDictionary',
  );

  const [bannerIsShown, setBannerIsShown] = useState(false);

  const isEasyLoginShown = useMemo(() => {
    if (!selectedClass) return false;

    const lowGradeValue = gradesDictionary[selectedClass.lowGrade as GradeKey];

    const min = gradesDictionary['pk'];
    const max = gradesDictionary['2'];

    return isInRange(lowGradeValue, min, max);
  }, [selectedClass]);

  useEffect(() => {
    if (!selectedClass || !isEasyLoginShown) {
      setBannerIsShown(false);

      return;
    }

    setBannerIsShown(!easyLoginTipsDictionary?.[selectedClass.id]);
  }, [selectedClass, isEasyLoginShown, easyLoginTipsDictionary]);

  return {
    isEasyLoginShown,
    bannerIsShown,
    hideBanner: () => setBannerIsShown(false),
  };
};

export default useShowEasyLogin;
