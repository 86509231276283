import { useCallback } from 'react';
import { notify } from '@scholastic/volume-react';

import { ClassDetailsProps } from '../../../views/ClassDetails/ClassDetails';
import getGenericErrorPrefix from '../../../utils/get-generic-error-prefix';

export const useGetStudentOptions = ({
  searchForStudent,
}: Pick<ClassDetailsProps, 'searchForStudent'>) => {
  return useCallback(
    async (...args: Parameters<typeof searchForStudent>) => {
      const promise = searchForStudent(...args);

      if (!promise) return Promise.resolve([]);

      try {
        const students = await promise;
        return students.map(student => ({
          label: `${student.firstName} ${student.lastName}`,
          value: student.id,
          dataPoints: [`Grade ${student.grade}`, student.studentId].filter(
            (value): value is string => !!value,
          ),
          firstName: student.firstName,
          lastName: student.lastName,
          originalStudentObject: student,
        }));
      } catch (e) {
        notify.warning(`${getGenericErrorPrefix()} Students could not be fetched.`);
        return [];
      }
    },
    [searchForStudent],
  );
};

export default useGetStudentOptions;
