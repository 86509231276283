import { useEffect, useState } from 'react';
import { SchoolCalendar, useStoreItem } from '@scholastic/volume-react';

import { isPastYear } from '../../utils/school-years';

export interface UseSchoolYearsData {
  calendarId: string | null;
  currentSchoolYear?: SchoolCalendar;
  selectedSchoolYear?: SchoolCalendar;
  isCurrentSchoolYear?: boolean;
  isPastSchoolYear?: boolean;
}

export const useSchoolYears = (schoolYears: SchoolCalendar[]): UseSchoolYearsData => {
  const [calendarId, setCalendarId] = useStoreItem('dpCalendarId');
  const [currentSchoolYear, setCurrentSchoolYear] = useState<SchoolCalendar>();
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SchoolCalendar>();

  const [isCurrentSchoolYear, setIsCurrentSchoolYear] = useState<boolean>(false);
  const [isPastSchoolYear, setIsPastSchoolYear] = useState<boolean>(false);

  useEffect(() => {
    setCurrentSchoolYear(schoolYears.find(year => year.currentCalendar));
  }, [schoolYears]);

  useEffect(() => {
    // set to selected calendar
    if (calendarId) {
      const selected = schoolYears.find(year => year.id === calendarId);

      if (selected) {
        setSelectedSchoolYear(selected);
        return;
      }
    }

    // set to current calendar
    if (currentSchoolYear) {
      if (currentSchoolYear.id !== calendarId) {
        setCalendarId(currentSchoolYear.id);
      }

      setSelectedSchoolYear(currentSchoolYear);
      return;
    }

    // set to first calendar
    if (schoolYears.length) {
      const first = schoolYears[0];

      if (first.id !== calendarId) {
        setCalendarId(first.id);
      }

      setSelectedSchoolYear(first);
    }
  }, [calendarId, currentSchoolYear, schoolYears, setCalendarId]);

  useEffect(() => {
    if (currentSchoolYear && selectedSchoolYear) {
      setIsCurrentSchoolYear(currentSchoolYear.id === selectedSchoolYear.id);
      setIsPastSchoolYear(isPastYear(currentSchoolYear, selectedSchoolYear));
    }
  }, [currentSchoolYear, selectedSchoolYear]);

  return {
    calendarId,
    currentSchoolYear,
    selectedSchoolYear,
    isCurrentSchoolYear,
    isPastSchoolYear,
  };
};

export default useSchoolYears;
