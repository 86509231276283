import React, { useRef, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { yupResolver } from '@hookform/resolvers';
import classNames from 'classnames';
import * as yup from 'yup';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Popover,
  PopoverBody,
  useUser,
  Tooltip,
  SmallScreenStrategy,
} from '@scholastic/volume-react';
import QuestionIcon from '@scholastic/volume-react/src/assets/icons/question.svg';

import EnterCode1x from '../../../assets/img/redeem-access_enter-code@1x.png';
import EnterCode2x from '../../../assets/img/redeem-access_enter-code@2x.png';
import EnterCode3x from '../../../assets/img/redeem-access_enter-code@3x.png';
import './StepEnterCode.scss';

const schema = yup.object().shape({
  accessCode: yup
    .string()
    .trim()
    .required()
    .label('Access code'),
});

export interface StepEnterCodeFormData {
  accessCode: string;
}

export interface StepEnterCodeMetaData {
  promptToAcceptLicense: boolean;
}

export interface StepEnterCodeProps {
  goToNext: (params: { formData: StepEnterCodeFormData }) => Promise<void>;
  className?: string;
}

const StepEnterCode = ({ className, goToNext }: StepEnterCodeProps) => {
  const {
    register,
    watch,
    setError,
    handleSubmit,
    errors,
    formState: { isValid },
  } = useForm<StepEnterCodeFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { firstName } = useUser();

  const isSmall = useMediaQuery({ minWidth: 520 });
  const isMedium = useMediaQuery({ minWidth: 720 });
  const isLarge = useMediaQuery({ minWidth: 960 });
  const isExtraLarge = useMediaQuery({ minWidth: 1280 });

  const accessCodeHelpRef = useRef<HTMLButtonElement>(null);
  const [accessCodeHelpOpen, setAccessCodeHelpOpen] = useState(false);

  return (
    <div
      className={classNames(className, 'sdm-step-enter-code', {
        'sdm-step-enter-code--small': isSmall,
        'sdm-step-enter-code--medium': isMedium,
        'sdm-step-enter-code--large': isLarge,
        'sdm-step-enter-code--extra-large': isExtraLarge,
      })}
    >
      {isMedium && (
        <div className="sdm-step-enter-code__image-wrapper">
          <img
            className="sdm-step-enter-code__enter-code-image sdm-step-enter-code__enter-code-image--full"
            alt="Enter Access Code"
            src={`${EnterCode1x}`}
            srcSet={`${EnterCode2x} 2x, ${EnterCode3x} 3x`}
          />
        </div>
      )}

      <div className="sdm-step-enter-code__inner">
        <h1 className="sdm-step-enter-code__title">
          Welcome, {capitalize(firstName || '')}. <br /> Let&apos;s get started.
        </h1>

        {!isMedium && (
          <img
            className="sdm-step-enter-code__enter-code-image sdm-step-enter-code__enter-code-image--inline"
            alt="Enter Access Code"
            src={`${EnterCode1x}`}
            srcSet={`${EnterCode2x} 2x, ${EnterCode3x} 3x`}
          />
        )}

        <Form
          className="sdm-step-enter-code__access-code-form"
          onSubmit={handleSubmit(async () => {
            try {
              const { accessCode } = watch();

              await goToNext({ formData: { accessCode: accessCode.trim() } });
            } catch (error) {
              setError('accessCode', { type: 'manual', message: error.message });
            }
          })}
        >
          <FormGroup className="sdm-step-enter-code__form-group">
            <label className="vol-form__label sdm-step-enter-code__label" htmlFor="accessCodeInput">
              <span className="sdm-step-enter-code__label-text-wrapper">
                Enter Access Code
                <button
                  type="button"
                  className="sdm-step-enter-code__access-code-help"
                  aria-controls="sdmRedeemAccessModalAccessCodeHelp"
                  aria-expanded={accessCodeHelpOpen}
                  aria-haspopup="menu"
                  tabIndex={0}
                  ref={accessCodeHelpRef}
                  data-for="sdm-step-enter-code__tooltip"
                  data-tip="Help"
                  data-event="mouseover"
                  data-event-off="click mouseleave"
                  onClick={e => {
                    // prevent the input field focus
                    // when clicking the question mark
                    e.preventDefault();
                  }}
                >
                  <img alt="Help" src={QuestionIcon} />
                </button>
              </span>

              <input
                className={classNames('vol-input sdm-step-enter-code__input', {
                  'vol-input--invalid': errors.accessCode,
                })}
                id="accessCodeInput"
                name="accessCode"
                placeholder="Enter access code"
                ref={register}
              />

              <FormFeedback className="sdm-step-enter-code__feedback">
                {errors.accessCode?.message}
              </FormFeedback>
            </label>

            <Popover
              id="sdmRedeemAccessModalAccessCodeHelp"
              isOpen={accessCodeHelpOpen}
              placement={isMedium ? 'right-start' : 'auto'}
              popperClassName="sdm-step-enter-code__access-code-help-popover"
              target={accessCodeHelpRef}
              toggle={() => setAccessCodeHelpOpen(!accessCodeHelpOpen)}
              trigger="click"
              smallScreenStrategy={SmallScreenStrategy.ShowDialog}
            >
              <PopoverBody>
                <p>
                  If your school or district has purchased a digital product for your classroom,
                  please ask your administrator to provide the access code.
                </p>
                <p>
                  If you are a Classroom Magazines subscriber, you will receive the access code via
                  email, or find it in your Teacher&apos;s Guide.
                </p>
                <p>
                  You can also contact Customer Support by phone at{' '}
                  <a href="tel:18668268834">1-866-826-8834</a> or email at{' '}
                  <a href="mailto:digitalservice@scholastic.com">digitalservice@scholastic.com</a>.
                </p>
              </PopoverBody>
            </Popover>

            <Tooltip id="sdm-step-enter-code__tooltip" disable={accessCodeHelpOpen} />
          </FormGroup>

          <Button
            className="sdm-step-enter-code__submit-button"
            color="primary"
            type="submit"
            disabled={!isValid}
          >
            ACTIVATE
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default StepEnterCode;
