import React, { FunctionComponent, SyntheticEvent } from 'react';

import {
  Application,
  ProgramCard,
  SubscriptionData,
  useClasses,
  useWindowQuery,
} from '@scholastic/volume-react';

import '../../components/ProgramCardList/ProgramCardList.scss';
import './StudentProgramCardList.scss';

export interface StudentProgramCardListProps {
  subscriptions: SubscriptionData[];
  onCardClick: (e: SyntheticEvent, application: Application) => void;
}

export const StudentProgramCardList: FunctionComponent<StudentProgramCardListProps> = ({
  subscriptions,
  onCardClick,
}: StudentProgramCardListProps) => {
  const baseClass = 'sdm-program-card-list';

  const { responsiveClassName, isMedium } = useWindowQuery({
    className: baseClass,
  });

  const classes = useClasses({ baseClass, className: 'sdm-student-program-card-list' }, [
    responsiveClassName,
  ]);

  return (
    <div className={classes}>
      <div className={`${baseClass}__inner`}>
        {subscriptions.map((subscriptionData: SubscriptionData) => (
          <ProgramCard
            key={subscriptionData.subscription.id}
            layout={isMedium ? 'large' : 'small'}
            subscriptionData={subscriptionData}
            onCardClick={onCardClick}
          />
        ))}
      </div>
    </div>
  );
};

export default StudentProgramCardList;
