import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  SchoolCalendar,
  SelectOption,
  useDeepEqualEffect,
  useStoreItem,
} from '@scholastic/volume-react';
import equal from 'fast-deep-equal';

export interface CalendarSelectOption {
  current: boolean;
  label: string;
  value: string;
}

export interface UseCalendarsProps {
  calendarsUnstable: SchoolCalendar[];
  selectedOrganization?: SelectOption;
}

export interface UseCalendarsReturn {
  calendarOptions: CalendarSelectOption[];
  selectedCalendarOption?: CalendarSelectOption;
  setSelectedCalendarOption: Dispatch<SetStateAction<CalendarSelectOption | undefined>>;
  selectedCalendar?: SchoolCalendar;
}

const getCalendarsForOrg = (calendars: SchoolCalendar[], orgId: string | null) =>
  calendars
    .filter(({ orgId: calendarOrgId, endDate }) => {
      return calendarOrgId === orgId && parseInt(endDate.split('-')[0], 10) >= moment().year();
    })
    .map(({ id, currentCalendar, startDate, endDate }) => ({
      label: `${startDate.split('-')[0]}-${endDate.split('-')[0]}`,
      value: id,
      current: currentCalendar,
    }));

const getCalendarForOrg = (calendarOptions: CalendarSelectOption[], calendarId: string | null) =>
  calendarOptions.find(option => option.value === calendarId) ||
  calendarOptions.find(option => option.current) ||
  calendarOptions[0];

export const useCalendars = ({
  calendarsUnstable,
  selectedOrganization,
}: UseCalendarsProps): UseCalendarsReturn => {
  const [calendarsStable, setCalendarsStable] = useState<SchoolCalendar[]>(calendarsUnstable);

  // because the `calendarsUnstable` is constantly re-created (its reference changes
  // on each render) we are unable to utilize it "as is". To circumvent this limitation
  // the `useDeepEqualEffect` below caches the said array and ensures that re-renders
  // happen only when the contents of the array are truly changed
  useDeepEqualEffect(() => {
    if (equal(calendarsStable, calendarsUnstable)) return;

    setCalendarsStable(calendarsUnstable);
  }, [calendarsUnstable]);

  const selectedOrganizationId = selectedOrganization?.value as string | undefined;

  const calendarOptions = useMemo<CalendarSelectOption[]>(
    () => getCalendarsForOrg(calendarsStable, selectedOrganizationId || null),
    [calendarsStable, selectedOrganizationId],
  );

  const [calendarId] = useStoreItem('dpCalendarId');
  const [selectedCalendarOption, setSelectedCalendarOption] = useState<CalendarSelectOption>();

  useEffect(() => {
    setSelectedCalendarOption(getCalendarForOrg(calendarOptions, calendarId));
  }, [calendarId, calendarOptions]);

  const selectedCalendar = useMemo(() => calendarsStable.find(({ id }) => id === calendarId), [
    calendarsStable,
    calendarId,
  ]);

  return {
    calendarOptions,
    selectedCalendarOption,
    setSelectedCalendarOption,
    selectedCalendar,
  };
};

export default useCalendars;
