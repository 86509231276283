import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import {
  Button,
  ClassPassCodeCard,
  ClassPassCodeCardProps,
  notify,
} from '@scholastic/volume-react';

import './StepFinal.scss';
import getGenericErrorPrefix from '../../../utils/get-generic-error-prefix';

export interface StepFinalProps {
  goToNext: () => void;
  productLogo: ReactElement;
  subscriptionProductName: string;
  onSavePassCode: ClassPassCodeCardProps['onSave'];
  className?: string;
}

const StepFinal = ({
  goToNext,
  productLogo,
  subscriptionProductName,
  onSavePassCode,
  className,
}: StepFinalProps) => {
  const isSmall = useMediaQuery({ minWidth: 520 });
  const isMedium = useMediaQuery({ minWidth: 720 });
  const isLarge = useMediaQuery({ minWidth: 960 });
  const isExtraLarge = useMediaQuery({ minWidth: 1280 });

  return (
    <div
      className={classNames(className, 'sdm-step-final', {
        'sdm-step-final--small': isSmall,
        'sdm-step-final--medium': isMedium,
        'sdm-step-final--large': isLarge,
        'sdm-step-final--extra-large': isExtraLarge,
      })}
    >
      <div className="sdm-step-final__column sdm-step-final__column--left">{productLogo}</div>

      <div className="sdm-step-final__column sdm-step-final__column--right">
        <h1 className="sdm-step-final__title">
          <strong>{subscriptionProductName}</strong> has been activated!
        </h1>

        <p className="sdm-step-final__description">
          Students use this <strong>Shared Magazine Password</strong> to access all of your active
          classroom magazine subscriptions.
        </p>

        <ClassPassCodeCard
          className="sdm-step-final__class-mag"
          layout="small"
          onSave={onSavePassCode}
          onGetError={(error: Error) => {
            if (error.name === 'AbortError') {
              return;
            }

            notify.warning(
              `${getGenericErrorPrefix()} We could not get your Classroom Magazine Password. Please try again later.`,
            );
          }}
          onCopy={() =>
            notify.success('The Classroom Magazine Password has been copied to your clipboard.')
          }
        />

        <Button
          className="sdm-step-final__done-button"
          color="primary"
          type="button"
          onClick={goToNext}
        >
          DONE
        </Button>
      </div>
    </div>
  );
};

export default StepFinal;
