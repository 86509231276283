import { useEffect, useState } from 'react';

import {
  fetchWithToken,
  logError,
  SubscriptionsResponse,
  validateResponse,
} from '@scholastic/volume-react';

export const useAdminProgramsApi = () => {
  const [subscriptionsResponseData, setSubscriptionsResponseData] = useState<SubscriptionsResponse>(
    [],
  );

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const subscriptionsResponse = await fetchWithToken('composite/staff/subscriptions', {
          signal: abortController.signal,
        });

        if (!subscriptionsResponse.ok) {
          throw new Error(
            `failed to fetch staff subscriptions:\n\t${subscriptionsResponse.status}: ${subscriptionsResponse.statusText}`,
          );
        }

        const subscriptions: SubscriptionsResponse = await subscriptionsResponse.json();

        if (validateResponse(subscriptions)) {
          // TODO: add io-ts validations when the shape gets fixed
          // `validateDataShape(SubscriptionsResponseShape, subscriptions)`
          setSubscriptionsResponseData(subscriptions);
        }
      } catch (error) {
        throw new Error(`could not fetch user data for AdminPrograms:\n\t${error.message}`);
      }
    };

    fetchData().catch(logError);

    return () => {
      if (abortController.signal.aborted) return;

      abortController.abort();
    };
  }, []);

  if (typeof subscriptionsResponseData === 'string') {
    throw new TypeError('"content-type" header is not set to "application/json"');
  }

  return { subscriptionsResponseData };
};

export default useAdminProgramsApi;
