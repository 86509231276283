import React from 'react';

import {
  GlobalNav,
  GlobalNavProps,
  ResponsivityProvider,
  UserTypeFeatureFlagsProvider,
  globalNavUserTypeFeatures,
} from '@scholastic/volume-react';

import './SdmHeader.scss';

export const SdmHeader = (props: GlobalNavProps) => (
  <ResponsivityProvider>
    <UserTypeFeatureFlagsProvider featuresSchema={globalNavUserTypeFeatures}>
      <GlobalNav {...props} />
    </UserTypeFeatureFlagsProvider>
  </ResponsivityProvider>
);

export default SdmHeader;
