import { useCallback } from 'react';
import { PersonSearchOnInputChangeAction } from '@scholastic/volume-react';

export interface OnInputChangeProps {
  value: string;
  actionObject: PersonSearchOnInputChangeAction;
  onChange: (value: string) => void;
}

export type OnInputChange = (props: OnInputChangeProps) => void;

export const useOnInputChange = (): OnInputChange => {
  return useCallback(({ value, actionObject: { action }, onChange }: OnInputChangeProps) => {
    // set input only on user-initiated change and ignore other
    // changes like the one that's triggered on menu change, etc.
    if (action === 'input-change') onChange(value);
  }, []);
};

export default useOnInputChange;
