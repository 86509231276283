import React from 'react';
import { Controller } from 'react-hook-form';
import { FormFeedback, SelectControl } from '@scholastic/volume-react';

import useGrades from '../../../behaviors/use-grades/use-grades';
import { StudentFormData, StudentFormProps } from '../StudentForm';

export interface StudentFormSelectProps extends Pick<StudentFormProps, 'formResetKey'> {
  name: keyof StudentFormData;
  label: string;
  placeholder: string;
  watch?: StudentFormProps['watch'];
  control: StudentFormProps['control'];
  errors: StudentFormProps['errors'];
  className?: string;
}

export const StudentFormSelect = ({
  formResetKey,
  name,
  label,
  placeholder,
  watch,
  control,
  errors,
  className,
}: StudentFormSelectProps) => {
  const baseClass = 'sdm-student-form';

  const sortedGrades = useGrades();

  return (
    <div className={className}>
      <label className={`${baseClass}__input-label`}>
        <span className={`${baseClass}__input-label-text`}>{label}</span>

        <Controller
          control={control}
          name={name}
          render={({ onChange }) => (
            <SelectControl
              // the `key` prop is needed for resetting the
              // underlying <ReactSelect /> component
              //
              // @see https://stackoverflow.com/a/55142916
              key={formResetKey}
              className={`${baseClass}__select`}
              invalid={!!errors.grade}
              value={watch && sortedGrades.find(({ value }) => watch(name) === value)}
              name={name}
              placeholder={placeholder}
              options={sortedGrades}
              onChange={({ value }: { value: string }) => onChange(value)}
            />
          )}
        />
      </label>

      <FormFeedback className={`${baseClass}__feedback`}>{errors[name]?.message}</FormFeedback>
    </div>
  );
};

export default StudentFormSelect;
