import { useMemo } from 'react';

import hyphenateStrings from '../../utils/hyphenate-strings';
import { ClassDetailsClassListItem, ClassDetailsSelectedClass } from './ClassDetails';

export interface UseGetSelectedClassGradeProps {
  selectedClass?: ClassDetailsSelectedClass;
  classList?: ClassDetailsClassListItem[];
}

export const useGetSelectedClassGrade = ({
  selectedClass,
  classList,
}: UseGetSelectedClassGradeProps) => {
  const selectedClassId = selectedClass?.id;

  return useMemo(() => {
    const foundClass = classList?.find(({ id }) => id === selectedClassId);

    const hyphenatedGrades = hyphenateStrings(
      foundClass?.lowGrade?.toUpperCase(),
      foundClass?.highGrade?.toUpperCase(),
    );

    return hyphenatedGrades ? `Grade ${hyphenatedGrades}` : '';
  }, [classList, selectedClassId]);
};

export default useGetSelectedClassGrade;
