import { useCallback, useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
import { nanoid } from 'nanoid';

import { StudentFormData } from '../../components/StudentForm/StudentForm';

export interface UseFormResetProps {
  reset: UseFormMethods<StudentFormData>['reset'];
}

export const useFormReset = ({ reset }: UseFormResetProps) => {
  const [formResetKey, setFormResetKey] = useState(`key-${nanoid()}`);

  const resetForm = useCallback(() => {
    reset();

    setFormResetKey(`key-${nanoid()}`);
  }, [reset, setFormResetKey]);

  return { formResetKey, resetForm };
};

export default useFormReset;
