import React from 'react';
import classNames from 'classnames';
import { Control, DeepMap, FieldError, UseFormMethods } from 'react-hook-form';
import * as yup from 'yup';
import { GradeKey, useWindowQuery } from '@scholastic/volume-react';

import StudentFormInput from './StudentFormInput/StudentFormInput';
import StudentFormSelect from './StudentFormSelect/StudentFormSelect';
import { CreateLoadOptions } from '../../views/ClassDetails/ClassDetailsAddStudentForm/use-create-load-options';
import { OnChange } from '../../views/ClassDetails/ClassDetailsAddStudentForm/use-on-change';
import { OnInputChange } from '../../views/ClassDetails/use-on-input-change';
import './StudentForm.scss';

export const getStudentFormDefaultValues = (): StudentFormData => ({
  firstName: '',
  lastName: '',
  studentId: '',
  grade: null,
});

export const getStudentFormSchema = () =>
  yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required()
      .label('First name'),
    lastName: yup
      .string()
      .trim()
      .required()
      .label('Last name'),
    grade: yup
      .string()
      .trim()
      .required()
      .label('Grade'),
    studentId: yup
      .string()
      .trim()
      .nullable()
      .label('Student ID'),
  });

export interface StudentFormData {
  firstName: string;
  lastName: string;
  studentId: string;
  grade: GradeKey | null;
}

export interface StudentFormProps {
  /**
   * A key used for remounting the <ReactSelect /> fields
   */
  formResetKey?: string;

  /**
   * Creates student search results fetcher
   */
  createLoadOptions?: CreateLoadOptions;

  /**
   * Student select handler
   */
  onChange?: OnChange;

  /**
   * On input change
   */
  onInputChange: OnInputChange;

  /**
   * Watch
   */
  watch?: UseFormMethods<StudentFormData>['watch'];

  /**
   * Control
   */
  control: Control<StudentFormData>;

  /**
   * Errors
   */
  errors: DeepMap<StudentFormData, FieldError>;

  /**
   * Additional className
   */
  className?: string;
}

export const StudentForm = ({
  formResetKey,
  createLoadOptions,
  onChange,
  onInputChange,
  watch,
  control,
  errors,
  className,
}: StudentFormProps) => {
  const baseClass = 'sdm-student-form';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  return (
    <div className={classNames(responsiveClassName, className)}>
      <StudentFormInput
        formResetKey={formResetKey}
        className={`${baseClass}__control`}
        personSearchProps={{ inputProps: { maxLength: 30 } }}
        name="firstName"
        label="First Name"
        placeholder="Enter first name"
        createLoadOptions={createLoadOptions}
        onChange={onChange}
        onInputChange={onInputChange}
        watch={watch}
        control={control}
        errors={errors}
      />

      <StudentFormInput
        formResetKey={formResetKey}
        className={`${baseClass}__control`}
        personSearchProps={{ inputProps: { maxLength: 30 } }}
        name="lastName"
        label="Last Name"
        placeholder="Enter last name"
        createLoadOptions={createLoadOptions}
        onChange={onChange}
        onInputChange={onInputChange}
        watch={watch}
        control={control}
        errors={errors}
      />

      <StudentFormSelect
        formResetKey={formResetKey}
        className={`${baseClass}__control`}
        name="grade"
        label="Grade"
        placeholder="Select"
        watch={watch}
        control={control}
        errors={errors}
      />

      <StudentFormInput
        formResetKey={formResetKey}
        className={`${baseClass}__control`}
        personSearchProps={{ inputProps: { maxLength: 30 } }}
        name="studentId"
        label="Student ID (optional)"
        placeholder="Enter ID"
        createLoadOptions={createLoadOptions}
        onChange={onChange}
        onInputChange={onInputChange}
        watch={watch}
        control={control}
        errors={errors}
      />
    </div>
  );
};

export default StudentForm;
