import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Student, useWindowQuery } from '@scholastic/volume-react';
import StudentHandoutsContent from '../../../../components/LoginInfoPopover/StudentHandouts/StudentHandoutsContent';

import './PrivacyAndSecurity.scss';
import iconSecurity from '../../../../assets/img/icon-security.svg';
import iconCaret from '../../../../assets/img/icon-caret.svg';
import { useReactToPrint } from 'react-to-print';

export interface PrivacyAndSecurityProps {
  className?: string;
  students: Student[];
}

export const PrivacyAndSecurity = ({ className, students }: PrivacyAndSecurityProps) => {
  const baseClass = 'sdm-privacy-and-security';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });
  const printRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => printRef.current
  })

  const titleContent = (
    <div className={`${baseClass}__title-content`}>
      <img className={`${baseClass}__logo`} src={iconSecurity} alt="Security" />
      Privacy & Security
    </div>
  );

  return (
    <div
      className={classNames(responsiveClassName, className, {
        [`${baseClass}--is-open`]: isOpen,
      })}
    >
      {isMedium ? (
        <h2 className={`${baseClass}__title`}>{titleContent}</h2>
      ) : (
        <button
          className={`${baseClass}__title-button`}
          type="button"
          onClick={() => setIsOpen(prev => !prev)}
        >
          {titleContent}

          <img className={`${baseClass}__title-button-caret`} src={iconCaret} alt="Caret icon" />
        </button>
      )}

      {(isMedium || isOpen) && (
        <>
          <p className={`${baseClass}__text`}>
            We recommend that students use text-based sign-ins at home, as they are the most secure
            method of accessing Scholastic Digital Manager. We’ve included these credentials in your
            class’s{' '}
            <a className={`${baseClass}__link`} onClick={handlePrint} data-testid="classHandouts">
              handouts.
            </a>
          </p>
          <StudentHandoutsContent ref={printRef} students={students} />
        </>
      )}
    </div>
  );
};

export default PrivacyAndSecurity;
