import React, { useEffect } from 'react';
import { Control, Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { FormFeedback, SelectList } from '@scholastic/volume-react';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import { OrganizationSelectOption, UseOrganizationsReturn } from './use-organizations';
import { TeacherClassCreateFormData } from './TeacherClassCreate';

export interface SelectOrganizationProps extends UseOrganizationsReturn {
  baseClass: string;
  getCalendarForOrg: (organization: { id: string }) => void;
  control: Control<TeacherClassCreateFormData>;
  errors: DeepMap<TeacherClassCreateFormData, FieldError>;
  setValue: UseFormMethods<TeacherClassCreateFormData>['setValue'];
}

export const SelectOrganization = ({
  baseClass,
  organizationOptions,
  selectedOrganization,
  setSelectedOrganization,
  getCalendarForOrg,
  control,
  errors,
  setValue,
}: SelectOrganizationProps) => {
  useEffect(() => {
    if (!selectedOrganization) return;

    setValue('organizationId', selectedOrganization.value);
  }, [selectedOrganization, setValue]);

  return (
    <>
      <div className={`${baseClass}__select`}>
        <label
          className={`${baseClass}__select-label`}
          htmlFor="organizationId"
          id="teacher-class-create-organization"
        >
          Building
          <Controller
            control={control}
            name="organizationId"
            render={() => (
              <SelectList
                aria-labelledby="teacher-class-create-organization"
                className={`${baseClass}__select-input`}
                disabled={!organizationOptions.length}
                invalid={!!errors.organizationId}
                options={organizationOptions}
                name="organizationId"
                placeholder="Choose your school"
                value={selectedOrganization}
                onChange={(option: OrganizationSelectOption) => {
                  setSelectedOrganization(option);
                  getCalendarForOrg({ id: option.value });
                }}
              />
            )}
          />
        </label>
      </div>

      <FormFeedback className={`${baseClass}__feedback`}>
        {errors.organizationId?.message}
      </FormFeedback>
    </>
  );
};

export default SelectOrganization;
