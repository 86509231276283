import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  ActionCard,
  ActionCardLayout,
  ActionCardType,
  Avatar,
  AvatarRole,
  createConfirmationDialog,
  FeaturesSchema,
  getInitials,
  getStoreItem,
  PersonCard,
  registerDeferredNotification,
  SchoolCalendar,
  Student,
  useFeatures,
  UserType,
  useStoreItem,
  useWindowQuery,
} from '@scholastic/volume-react';

import './ClassDetailsStudents.scss';
import iconPencilDark from '../../../assets/img/icon-pencil-dark.svg';
import iconPencil from '../../../assets/img/icon-pencil.svg';
import iconTrashDark from '../../../assets/img/icon-trash-dark.svg';
import iconTrash from '../../../assets/img/icon-trash.svg';
import { ClassDetailsFeatures, ClassDetailsProps } from '../ClassDetails';
import ClassDetailsAddStudentForm from '../ClassDetailsAddStudentForm/ClassDetailsAddStudentForm';
import ClassDetailsEditStudentForm from '../ClassDetailsEditStudentForm/ClassDetailsEditStudentForm';
import useSchoolYears from '../../../behaviors/use-school-years/use-school-years';

export interface ClassDetailsStudentsProps
  extends Pick<
  ClassDetailsProps,
  | 'addExistingStudent'
  | 'editStudentId'
  | 'getOptionValue'
  | 'isAddEditMode'
  | 'navigateTo'
  | 'navigateToEditStudents'
  | 'removeStudentFromClass'
  | 'searchForStudent'
  | 'selectedClass'
  | 'students'
  | 'updateStudent'
  > {
  className?: string;
  schoolYears: SchoolCalendar[];
}

const getPersonCardLayout = (
  isAddEditMode: boolean,
  isMedium: boolean,
  isPastSchoolYear: boolean,
): 'horizontal' | 'medium' | 'small' => {
  if (!isPastSchoolYear && isAddEditMode) {
    return 'horizontal';
  }

  return isMedium ? 'medium' : 'small';
};

export interface CreateRemoveStudentConfirmationDialogProps {
  id: string;
  fullName: string;
  removeStudentFromClass: (id: string) => Promise<void>;
  onConfirm?: () => void;
}

export const createRemoveStudentConfirmationDialog = ({
  id,
  fullName,
  removeStudentFromClass,
  onConfirm,
}: CreateRemoveStudentConfirmationDialogProps) =>
  createConfirmationDialog({
    title: (
      <>
        Remove student <strong>{fullName}</strong>?
      </>
    ),
    message:
      'After the student is removed, you can no longer view their information or entitle them to programs.',
    confirmButtonTitle: 'Remove',
    cancelButtonTitle: 'Cancel',
    onConfirm: async () => {
      try {
        await removeStudentFromClass(id);

        registerDeferredNotification({
          content: `Student <strong>${fullName}</strong> removed.`,
          options: { type: 'success' },
        });

        onConfirm?.();
      } catch (e) {
        registerDeferredNotification({
          content: e.message,
          options: { type: 'warning' },
        });
      }
    },
  });

const ClassDetailsStudents = ({
  addExistingStudent,
  className,
  editStudentId,
  getOptionValue,
  isAddEditMode,
  navigateTo,
  navigateToEditStudents,
  removeStudentFromClass,
  schoolYears,
  searchForStudent,
  selectedClass,
  students,
  updateStudent,
}: ClassDetailsStudentsProps) => {
  const baseClass = 'sdm-class-details-students';

  const { isPastSchoolYear } = useSchoolYears(schoolYears);
  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const { canAddStudents, canEditStudents, canViewStudentDetails } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
  >() as ClassDetailsFeatures;

  const [canAddEditStudents, setCanAddEditStudents] = useState(canAddStudents && canEditStudents);
  useEffect(() => setCanAddEditStudents(canAddStudents && canEditStudents), [
    canAddStudents,
    canEditStudents,
  ]);

  const personCardLayout = getPersonCardLayout(
    isAddEditMode || false,
    isMedium,
    isPastSchoolYear || false,
  );

  const avatarSize = personCardLayout === 'horizontal' ? 'small' : 'medium';

  const [portalUrl] = useStoreItem(
    'dpPortalUrl',
    getStoreItem('dpPortalUrl') || 'https://digital.scholastic.com',
  );

  const isActive = selectedClass?.active;

  const getStudentCard = (student: Student) => {
    const { id, studentId, firstName, lastName, grade } = student;

    const fullName = `${firstName} ${lastName}`;

    const initials = getInitials(fullName);

    const avatar = (
      <Avatar colorKey={initials} role={AvatarRole.Student} size={avatarSize}>
        {initials}
      </Avatar>
    );

    const personCardProps = {
      key: id,
      id: studentId,
      className: `${baseClass}__student`,
      layout: personCardLayout,
      fullName,
      grade: `Grade ${grade.toUpperCase()}`,
      avatar,
    };

    if (isPastSchoolYear || !isActive) {
      return <PersonCard {...personCardProps} />;
    }

    if (selectedClass && editStudentId && editStudentId === id && canAddEditStudents) {
      return (
        <ClassDetailsEditStudentForm
          className={`${baseClass}__edit-student-form`}
          selectedStudent={student}
          selectedStudentAvatar={avatar}
          updateStudent={updateStudent}
          selectedClass={selectedClass}
          onCancelClick={() => navigateTo('/student/show')}
        />
      );
    }

    const iconEdit = personCardLayout === 'horizontal' && isMedium ? iconPencilDark : iconPencil;
    const iconRemove = personCardLayout === 'horizontal' && isMedium ? iconTrashDark : iconTrash;
    const editStudent = () => navigateTo(`/student/${id}/edit`);
    const dropdownItems = [
      {
        label: 'Edit',
        onClick: editStudent,
        icon: <img src={iconEdit} alt="Edit Icon" />,
      },
      {
        label: 'Remove',
        onClick: () =>
          createRemoveStudentConfirmationDialog({ id, fullName, removeStudentFromClass })(),
        icon: <img src={iconRemove} alt="Remove Icon" />,
      },
    ];

    return (
      <PersonCard
        key={id}
        id={studentId}
        className={`${baseClass}__student`}
        layout={personCardLayout}
        fullName={fullName}
        grade={`Grade ${grade.toUpperCase()}`}
        avatar={avatar}
        onClick={
          canViewStudentDetails && getOptionValue('isReactStudentDetails')
            ? () => navigateTo(`/student/${id}/show`)
            : undefined
        }
        dropdownItems={canAddEditStudents ? dropdownItems : undefined}
      />
    );
  };

  return (
    <div
      className={classNames(responsiveClassName, className, {
        [`${baseClass}--edit-mode`]:
          isPastSchoolYear || !canAddEditStudents ? false : isAddEditMode,
        [`${baseClass}--read-mode`]:
          isPastSchoolYear || !canAddEditStudents ? true : !isAddEditMode,
      })}
    >
      {!isPastSchoolYear && isAddEditMode && canAddEditStudents && (
        <ClassDetailsAddStudentForm
          searchForStudent={searchForStudent}
          addExistingStudent={addExistingStudent}
          selectedClass={selectedClass}
          updateStudent={updateStudent}
          onCloseButtonClick={() => window.location.assign(`${portalUrl}/#/students/show-details`)}
        />
      )}

      {!isPastSchoolYear && !isAddEditMode && isActive && canAddEditStudents && (
        <ActionCard
          className={`${baseClass}__student`}
          type={ActionCardType.User}
          layout={ActionCardLayout.Small}
          heading="ADD STUDENT"
          onClick={() => navigateToEditStudents()}
        />
      )}

      {students?.map(getStudentCard)}

      {(isPastSchoolYear || !isAddEditMode || !canAddEditStudents) && (
        <>
          {/* empty placeholder divs */}
          {[...Array(10)].map((_, index) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={`${baseClass}__student ${baseClass}__student--placeholder`}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ClassDetailsStudents;
