import { ClassDetailsSelectedClass } from './ClassDetails';

export interface UseGetCoTeacherFullNamesProps {
  selectedClass?: ClassDetailsSelectedClass;
}

export const useGetTeacherFullNames = ({ selectedClass }: UseGetCoTeacherFullNamesProps) => {
  const primaryTeacherId = selectedClass?.staff?.primaryTeacher?.id;

  const teachers = selectedClass?.staff?.teachers?.map(teacher => teacher) || [];

  return teachers
    .sort(
      (
        { id: idA, firstName: firstNameA, lastName: lastNameA },
        { id: idB, firstName: firstNameB, lastName: lastNameB },
      ) => {
        if (idA === primaryTeacherId) return -Infinity;

        if (idB === primaryTeacherId) return Infinity;

        return `${firstNameA} ${lastNameA}`.localeCompare(`${firstNameB} ${lastNameB}`);
      },
    )
    .map(({ firstName: firstNameInitial, lastName: lastNameInitial }) => {
      const firstName = firstNameInitial || ' ';
      const lastName = lastNameInitial || ' ';

      const firstNameCapitalized = `${firstName[0].toUpperCase()}${firstName.substring(1)}`;
      const lastNameCapitalized = `${lastName[0].toUpperCase()}${lastName.substring(1)}`;

      return `${firstNameCapitalized} ${lastNameCapitalized}`.trim();
    })
    .join(', ');
};

export default useGetTeacherFullNames;
