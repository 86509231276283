import React, { FunctionComponent, SyntheticEvent } from 'react';
import {
  ActionCard,
  ClassPassCodeCard,
  ProgramCard,
  SubscriptionData,
  useClasses,
  useWindowQuery,
  ActionCardLayout,
  ActionCardType,
  SectionHeader,
  notify,
  ClassPassCodeCardProps, useFeatures, UserType, FeaturesSchema,
} from '@scholastic/volume-react';

import '../../components/ProgramCardList/ProgramCardList.scss';
import TeacherProgramCardList from './TeacherProgramCardList';
import getGenericErrorPrefix from '../../utils/get-generic-error-prefix';
import { TeacherProgramsFeatures } from './TeacherPrograms';

export interface TeacherClassroomMagazineListProps extends TeacherProgramCardList {
  onSavePassCode: (e: SyntheticEvent) => void;
  isAddNewProgramDisabled: boolean;
}

export const TeacherClassroomMagazineList: FunctionComponent<TeacherClassroomMagazineListProps> = ({
  subscriptions,
  onCardClick,
  onProgramCardClick,
  onSavePassCode,
  isAddNewProgramDisabled,
}: TeacherClassroomMagazineListProps) => {
  const baseClass = 'sdm-program-card-list';

  const { responsiveClassName, isMedium } = useWindowQuery({
    className: baseClass,
  });

  const classes = useClasses({ baseClass }, [responsiveClassName]);

  const { canUseClassPasscode } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
    >() as TeacherProgramsFeatures;

  const classPassCodeCardProps: ClassPassCodeCardProps = {
    onSave: onSavePassCode,
    onGetError: (error: Error) => {
      if (error.name === 'AbortError') {
        return;
      }

      notify.warning(
        `${getGenericErrorPrefix()} We could not get your Classroom Magazine Password. Please try again later.`,
      );
    },
    onCopy: () =>
      notify.success('The Classroom Magazine Password has been copied to your clipboard.'),
  };

  return (
    <>
      <div className={`${baseClass}__header`}>
        <SectionHeader tabs={[{ label: 'Classroom Magazines', value: '' }]} />
      </div>

      <div className={classes}>
        {canUseClassPasscode && !isMedium && (
          <div className={`${baseClass}__class-mag-wrapper`}>
            <ClassPassCodeCard {...classPassCodeCardProps} layout="small" />
          </div>
        )}

        <div className={`${baseClass}__inner`}>
          {canUseClassPasscode && isMedium && <ClassPassCodeCard {...classPassCodeCardProps} layout="large" />}

          {subscriptions.map((subscriptionData: SubscriptionData) => (
            <ProgramCard
              key={subscriptionData.subscription.id}
              layout={isMedium ? 'large' : 'small'}
              subscriptionData={subscriptionData}
              onCardClick={onCardClick}
            />
          ))}

          {!isAddNewProgramDisabled && (
            <ActionCard
              heading="ADD NEW PROGRAM"
              layout={isMedium ? ActionCardLayout.Large : ActionCardLayout.Small}
              subheading="with an access code"
              type={ActionCardType.Program}
              onClick={onProgramCardClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TeacherClassroomMagazineList;
