import { useMemo } from 'react';
import { Student } from '@scholastic/volume-react';

const useGetSortedStudents = (students?: Student[]) =>
  useMemo(
    () =>
      students?.sort(
        (
          { firstName: firstNameA, lastName: lastNameA },
          { firstName: firstNameB, lastName: lastNameB },
        ) => {
          const fullNameA = `${firstNameA} ${lastNameA}`;
          const fullNameB = `${firstNameB} ${lastNameB}`;

          return fullNameA.localeCompare(fullNameB);
        },
      ),
    [students],
  );

export default useGetSortedStudents;
