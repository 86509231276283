import React, { RefObject } from 'react';
import classNames from 'classnames';
import {
  FabItem,
  FabMenu,
  FeaturesSchema,
  IconButton,
  notify,
  PageHeader,
  SchoolCalendar,
  useClasses,
  useFeatures,
  UserType,
  useWindowQuery,
} from '@scholastic/volume-react';

import LoginInfoPopover, {
  LoginInfoPopoverProps,
} from '../../../components/LoginInfoPopover/LoginInfoPopover';
import iconCog from '../../../assets/img/icon-cog.svg';
import iconPencil from '../../../assets/img/icon-pencil.svg';
import iconExport from '../../../assets/img/icon-export.svg';
import iconExportDark from '../../../assets/img/icon-export-dark.svg';
import iconArchive from '../../../assets/img/icon-archive.svg';
import iconRestore from '../../../assets/img/icon-restore.svg';
import { ClassDetailsFeatures, ClassDetailsProps } from '../ClassDetails';
import { SelectedClassWithEasyLoginInfo } from '../use-get-selected-class-with-easy-login-info';
import getClassDetailsCSV from '../../../utils/get-class-details-csv';
import createArchiveClassDialog from './create-archive-class-dialog';
import createRestoreClassDialog from './create-restore-class-dialog';
import getGenericErrorPrefix from '../../../utils/get-generic-error-prefix';
import './ClassDetailsPageHeader.scss';
import useSchoolYears from '../../../behaviors/use-school-years/use-school-years';

export interface ClassDetailsPageHeaderProps
  extends Pick<ClassDetailsProps, 'navigateToEditStudents' | 'setActive'> {
  classDisplayName?: string;
  navigateToEditClassPage: () => void;
  selectedClass?: SelectedClassWithEasyLoginInfo;
  showAddStudent: boolean;
  showEasyLogin: boolean;
  schoolName?: string;
  schoolYears: SchoolCalendar[];
  students?: LoginInfoPopoverProps['students'];
}

export const notifyArchiveClassSuccess = (className: string) => {
  notify.success(
    <>
      Class <strong>{className}</strong> archived.
    </>,
  );
};

export const notifyArchiveClassError = (className: string) => {
  notify.warning(
    <>
      {getGenericErrorPrefix()} Class <strong>{className}</strong> could not be archived at this
      time. Please try again later.
    </>,
  );
};

export const notifyRestoreClassSuccess = (className: string) => {
  notify.success(
    <>
      Class <strong>{className}</strong> restored.
    </>,
  );
};

export const notifyRestoreClassError = (className: string) => {
  notify.warning(
    <>
      {getGenericErrorPrefix()} Class <strong>{className}</strong> could not be restored at this
      time. Please try again later.
    </>,
  );
};

export const ClassDetailsPageHeader = ({
  classDisplayName,
  navigateToEditClassPage,
  navigateToEditStudents,
  selectedClass,
  setActive,
  showAddStudent,
  showEasyLogin,
  schoolName,
  schoolYears,
  students,
}: ClassDetailsPageHeaderProps) => {
  const baseClass = 'sdm-class-details-page-header';

  const { isPastSchoolYear } = useSchoolYears(schoolYears);
  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const fabMenuClasses = useClasses({ baseClass: `${baseClass}__menu` }, [
    isMedium ? `${baseClass}__menu--large` : `${baseClass}__menu--extra-small`,
  ]);

  const { canGetLoginInfo, canManage, canAddStudents } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
  >() as ClassDetailsFeatures;

  const isActive = selectedClass?.active;
  const hasStudents = !!students && students.length > 0;

  return isActive === undefined ? null : (
    <PageHeader
      className={classNames(responsiveClassName, { [`${baseClass}--archived`]: !isActive })}
      filterByRole
      hideOrgSelect
      isArchived={!isActive}
    >
      <div className={`${baseClass}__column ${baseClass}__column--left`}>
        <span className={`${baseClass}__school-name`}>{schoolName}</span>

        <h1 className={`${baseClass}__class-name`}>{classDisplayName}</h1>
      </div>

      <div className={`${baseClass}__column ${baseClass}__column--right`}>
        {isPastSchoolYear ? (
          canGetLoginInfo &&
          hasStudents && (
            <IconButton
              renderIcon={() => (
                <div className={`${baseClass}__icon-wrapper`}>
                  <img className={`${baseClass}__icon`} src={iconExportDark} alt="Export Icon" />
                </div>
              )}
              onClick={() =>
                getClassDetailsCSV({
                  fileName: selectedClass?.displayName,
                  students: students?.map(
                    ({
                      firstName,
                      lastName,
                      studentId,
                      grade,
                      credentials: { username, password },
                    }) => ({
                      firstName,
                      lastName,
                      studentId,
                      grade,
                      username,
                      password,
                    }),
                  ),
                })
              }
            >
              Export Class (.csv)
            </IconButton>
          )
        ) : (
          <>
            {hasStudents && selectedClass && isActive && canGetLoginInfo && (
              <LoginInfoPopover
                popoverClassName={`${baseClass}__login-info-popover`}
                students={students || []}
                selectedClass={selectedClass}
                showEasyLogin={showEasyLogin}
              />
            )}

            {canManage && (
              <FabMenu
                className={classNames(`${baseClass}__manage-menu`, {
                  [`${baseClass}__manage-menu--no-margin`]: !isActive,
                })}
                placement="bottom"
                renderFab={(ref, props) => (
                  <IconButton
                    ref={ref as RefObject<HTMLButtonElement>}
                    {...props}
                    renderIcon={() => (
                      <div className={`${baseClass}__icon-wrapper`}>
                        <img className={`${baseClass}__icon`} src={iconCog} alt="Cog Icon" />
                      </div>
                    )}
                  >
                    Manage
                  </IconButton>
                )}
              >
                {isActive && (
                  <FabItem
                    icon={<img src={iconPencil} alt="Pencil Icon" />}
                    onClick={navigateToEditClassPage}
                  >
                    Edit class info
                  </FabItem>
                )}

                {isActive ? (
                  <FabItem
                    icon={<img src={iconArchive} alt="Archive Icon" />}
                    onClick={createArchiveClassDialog({ classDisplayName, setActive })}
                  >
                    Archive class
                  </FabItem>
                ) : (
                  <FabItem
                    icon={<img src={iconRestore} alt="Restore Icon" />}
                    onClick={createRestoreClassDialog({ classDisplayName, setActive })}
                  >
                    Restore
                  </FabItem>
                )}

                {hasStudents && (
                  <FabItem
                    icon={<img src={iconExport} alt="Export Icon" />}
                    onClick={() =>
                      getClassDetailsCSV({
                        fileName: selectedClass?.displayName,
                        students: students?.map(
                          ({
                            firstName,
                            lastName,
                            studentId,
                            grade,
                            credentials: { username, password },
                          }) => ({
                            firstName,
                            lastName,
                            studentId,
                            grade,
                            username,
                            password,
                          }),
                        ),
                      })
                    }
                  >
                    Export class (.csv)
                  </FabItem>
                )}
              </FabMenu>
            )}
          </>
        )}
      </div>

      {showAddStudent && isActive && canAddStudents && (
        <FabMenu className={fabMenuClasses} placement="bottom">
          <FabItem onClick={() => navigateToEditStudents()}>Add new student</FabItem>
        </FabMenu>
      )}
    </PageHeader>
  );
};

export default ClassDetailsPageHeader;
