import React, { useCallback } from 'react';
import { UseFormMethods } from 'react-hook-form';
import {
  getStoreItem,
  GradeKey,
  notify,
  registerDeferredNotification,
} from '@scholastic/volume-react';

import { TeacherClassCreateFormData, TeacherClassCreateInnerProps } from './TeacherClassCreate';
import getGenericErrorPrefix from '../../utils/get-generic-error-prefix';

export interface UseUpdateExistingClassFactoryProps
  extends Pick<
    TeacherClassCreateInnerProps,
    | 'existingCoTeachers'
    | 'updateClass'
    | 'selectedClass'
    | 'addTeacherToClass'
    | 'removeTeacherFromClass'
    | 'classId'
  > {
  getValues: UseFormMethods<TeacherClassCreateFormData>['getValues'];
  showSingleGradeInput: boolean;
}

export const useUpdateExistingClassFactory = ({
  getValues,
  updateClass,
  selectedClass,
  existingCoTeachers,
  addTeacherToClass,
  removeTeacherFromClass,
  classId,
  showSingleGradeInput,
}: UseUpdateExistingClassFactoryProps) => {
  const dpOrgId = getStoreItem('dpOrgId');

  const addTeacherToExistingClass = useCallback(
    (teacherId: string) => addTeacherToClass(classId || '', dpOrgId || '', teacherId),
    [addTeacherToClass, classId, dpOrgId],
  );

  const removeTeacherFromExistingClass = useCallback(
    (teacherId: string) => removeTeacherFromClass(classId || '', dpOrgId || '', teacherId),
    [removeTeacherFromClass, classId, dpOrgId],
  );

  return async () => {
    const {
      organizationId,
      schoolCalendarId,
      nickname,
      lowGrade,
      highGrade,
      coTeachers,
    } = getValues();

    const existingCoTeacherIds =
      existingCoTeachers?.filter(({ value }) => value).map(({ value }) => value) || [];

    const alteredCoTeacherIds = coTeachers
      .filter(({ value }) => value)
      .map(({ value: { value } }) => value);

    const reducer = (acc: Record<string, boolean>, id: string) => ({ ...acc, [id]: true });

    const existingTeacherIdsDictionary = existingCoTeacherIds.reduce(reducer, {});
    const alteredCoTeacherIdsDictionary = alteredCoTeacherIds.reduce(reducer, {});

    const removedCoTeacherIds = existingCoTeacherIds
      .filter(id => !!id)
      .filter(id => !alteredCoTeacherIdsDictionary[id]);

    const addedCoTeacherIds = alteredCoTeacherIds
      .filter(id => !!id)
      .filter(id => !existingTeacherIdsDictionary[id]);

    try {
      await Promise.all([
        ...removedCoTeacherIds.map(id => removeTeacherFromExistingClass(id)),
        ...addedCoTeacherIds.map(id => addTeacherToExistingClass(id)),
        updateClass?.({
          ...selectedClass,
          organizationId,
          schoolCalendarId,
          nickname,
          lowGrade: lowGrade as GradeKey,
          highGrade: (showSingleGradeInput ? lowGrade : highGrade || lowGrade) as GradeKey,
        }),
      ]);

      registerDeferredNotification({
        content: `Class <strong>${nickname}</strong> updated.`,
        options: { type: 'success' },
      });

      window.history.back();
    } catch (e) {
      notify.warning(e.message);
    }
  };
};

export default useUpdateExistingClassFactory;
