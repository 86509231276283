import { useMemo } from 'react';
import moment from 'moment';

import hyphenateStrings from '../../utils/hyphenate-strings';
import { ClassDetailsSelectedSchoolYear } from './ClassDetails';

export interface UseGetSelectedSchoolYearProps {
  selectedSchoolYear?: ClassDetailsSelectedSchoolYear;
}

export const useGetSelectedSchoolYear = ({ selectedSchoolYear }: UseGetSelectedSchoolYearProps) => {
  const selectedSchoolYearStartDate = selectedSchoolYear?.startDate;
  const selectedSchoolYearEndDate = selectedSchoolYear?.endDate;

  return useMemo(() => {
    const startYear = selectedSchoolYearStartDate
      ? moment(selectedSchoolYearStartDate).format('Y')
      : '';

    const endYear = selectedSchoolYearEndDate ? moment(selectedSchoolYearEndDate).format('Y') : '';

    return hyphenateStrings(startYear, endYear);
  }, [selectedSchoolYearEndDate, selectedSchoolYearStartDate]);
};

export default useGetSelectedSchoolYear;
