import React, { ClassAttributes, forwardRef, useMemo, HTMLProps } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { FieldError } from 'react-hook-form';
import { FormFeedback } from '@scholastic/volume-react';

import './Input.scss';

export interface InputProps extends HTMLProps<HTMLInputElement> {
  /**
   * Form error object
   */
  error?: FieldError;

  /**
   * Label
   */
  label: string;

  /**
   * Input field name
   */
  name: string;

  /**
   * Input field placeholder
   */
  placeholder: string;
}

export const Input = forwardRef(
  (
    { error, label, name, placeholder, maxLength = 30, readOnly, ...otherProps }: InputProps,
    ref: ClassAttributes<HTMLInputElement>['ref'],
  ) => {
    const id = useMemo(() => `id-${nanoid()}`, []);

    return (
      <>
        <label className="sdm-input__input-label" htmlFor={id} id={id}>
          <span className="sdm-input__input-label-text">{label}</span>

          <input
            ref={ref}
            aria-labelledby={id}
            className={classNames('vol-input sdm-input__input-field', {
              'vol-input--invalid': error,
            })}
            name={name}
            placeholder={placeholder}
            maxLength={maxLength}
            readOnly={readOnly}
            aria-readonly={readOnly}
            {...otherProps}
          />
        </label>

        <FormFeedback className="sdm-input__feedback">{error?.message}</FormFeedback>
      </>
    );
  },
);

export default Input;
