import React, { useEffect, useMemo } from 'react';
import { Control, Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FormFeedback, SelectControl } from '@scholastic/volume-react';

import { TeacherClassCreateFormData } from './TeacherClassCreate';

export interface GradeSelectOption {
  label: string;
  value: string;
}

export interface SelectLowGradeProps {
  baseClass: string;
  selectedLowGrade: string;
  selectedHighGrade?: string | null;
  isMedium: boolean;
  grades: GradeSelectOption[];
  control: Control<TeacherClassCreateFormData>;
  errors: DeepMap<TeacherClassCreateFormData, FieldError>;
  setValue: UseFormMethods<TeacherClassCreateFormData>['setValue'];
}

export const SelectLowGrade = ({
  baseClass,
  control,
  errors,
  grades,
  setValue,
  selectedLowGrade,
  selectedHighGrade,
  isMedium,
}: SelectLowGradeProps) => {
  const lowerGrades = useMemo(
    () =>
      grades.slice(
        0,
        grades.findIndex(({ value }) => value === selectedHighGrade),
      ),
    [grades, selectedHighGrade],
  );

  return (
    <>
      <label
        className={`${baseClass}__input-label`}
        htmlFor="lowGrade"
        id="teacher-class-create-multi-low-grade-label"
      >
        <span className={`${baseClass}__input-label-text`}>Low Grade</span>

        <Controller
          control={control}
          name="lowGrade"
          data-testid="lowGradeDropdown"
          render={({ onChange }) => (
            <SelectControl
              aria-labelledby="teacher-class-create-multi-low-grade-label"
              invalid={!!errors.lowGrade}
              value={grades.find(({ value }) => value === selectedLowGrade)}
              options={lowerGrades}
              placeholder={isMedium ? 'Select grade' : 'Grade'}
              onChange={({ value }: { value: string }) => {
                onChange(value);

                const lowGradeIndex = grades.findIndex(({ value: v }) => v === value);
                const highGradeIndex = grades.findIndex(({ value: v }) => v === selectedHighGrade);

                // if newly selected `lowGrade` is higher than the
                // currently selected `highGrade`, reset the latter
                if (lowGradeIndex > highGradeIndex) {
                  setValue('highGrade', null, { shouldValidate: true });
                }
              }}
            />
          )}
        />
      </label>

      <FormFeedback className={`${baseClass}__feedback`}>{errors.lowGrade?.message}</FormFeedback>
    </>
  );
};
export default SelectLowGrade;
