import React, {
  Dispatch,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import caretIcon from '@scholastic/volume-core/build/assets/icons/caret.svg';
import caretWhiteIcon from '../../assets/img/icon-caret-white.svg';

import {
  Badge,
  Button,
  Collapse,
  CollapseProps,
  FeaturesSchema,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  SchoolCalendar,
  ToggleSwitch,
  TransitionTimeout,
  useClasses,
  useFeatures,
  useOuterClick,
  UserType,
  useWindowQuery,
} from '@scholastic/volume-react';
import { TeacherClassesFeatures } from './TeacherClasses';

export interface FiltersProps extends HTMLAttributes<'div'> {
  baseClass: string;
  changeSchoolYear: (year: SchoolCalendar) => void;
  schoolYear?: SchoolCalendar;
  schoolYears: SchoolCalendar[];
  setFiltersMessage: Dispatch<SetStateAction<ReactNode | undefined>>;
  setShowArchivedClasses: Dispatch<{ value: boolean; } | null>
  showArchivedClasses: { value: boolean; } | null;
}

const getShortenedSchoolYear = (schoolYear: SchoolCalendar) => schoolYear.description.split(' ')[0];

export const TeacherClassesFiltersArchivedKey = 'sdmTeacherClassesArchived';

export const Filters: FunctionComponent<FiltersProps> = ({
  baseClass,
  changeSchoolYear,
  schoolYear,
  schoolYears,
  setFiltersMessage,
  setShowArchivedClasses,
  showArchivedClasses,
}: FiltersProps) => {
  const filterMenuButtonRef = useRef<HTMLButtonElement>(null);

  const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isSchoolYearMenuOpen, setSchoolYearMenuOpen] = useState(false);

  const { responsiveClassName, isMedium } = useWindowQuery({ className: `${baseClass}__filters` });

  const classes = useClasses({ baseClass: `${baseClass}__filters` }, [responsiveClassName]);

  const filtersCaretClasses = useClasses({ baseClass: 'vol-caret' }, [
    isFilterMenuOpen && 'vol-caret--show',
  ]);

  const schoolYearsCaretClasses = useClasses({ baseClass: 'vol-caret' }, [
    isSchoolYearMenuOpen && 'vol-caret--show',
  ]);

  const popoverOuterClickRef: RefObject<HTMLDivElement> = useOuterClick<HTMLDivElement>(e => {
    if (isMedium && !popoverOuterClickRef.current?.contains(e.target as Node)) {
      setFilterMenuOpen(false);
      setSchoolYearMenuOpen(false);
    }
  });

  const collapseProps: CollapseProps = {
    isOpen: isSchoolYearMenuOpen,
    timeout: TransitionTimeout.Collapse,
  };

  const shortenedSchoolYear = schoolYear && getShortenedSchoolYear(schoolYear);

  const { canSeeArchivedClasses } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
  >() as TeacherClassesFeatures;

  const [activeFilters, setActiveFilters] = useState(
    (schoolYear ? 1 : 0) + (showArchivedClasses?.value ? 1 : 0),
  );

  useEffect(() => setActiveFilters((schoolYear ? 1 : 0) + (showArchivedClasses?.value ? 1 : 0)), [
    schoolYear,
    showArchivedClasses?.value,
  ]);

  useEffect(() => {
    setFiltersMessage(
      <>
        Viewing <strong>School Year {shortenedSchoolYear}</strong>. Change school year in filters.
      </>,
    );
  }, [setFiltersMessage, shortenedSchoolYear]);

  const FiltersMenus = useMemo(() => {
    const onSchoolYearsMenuEntered = (node: HTMLElement) => {
      (node.children[0] as HTMLElement).focus();
      if (schoolYear) {
        const schoolYearButton = node.querySelector<HTMLButtonElement>(
          `#schoolYearOption${schoolYear.id}`,
        );

        if (schoolYearButton) {
          // eslint-disable-next-line no-param-reassign
          node.scrollTop = schoolYearButton.offsetTop;
        }
      }
    };

    const isSelectedSchoolYear = (year: SchoolCalendar) =>
      year.id === (schoolYear && schoolYear.id);

    const schoolYearOptionClasses = (year: SchoolCalendar) =>
      classNames(
        `${baseClass}__filters-calendars-option`,
        year.id === (schoolYear && schoolYear.id) &&
        `${baseClass}__filters-calendars-option--selected`,
      );

    return (
      <>
        <div className={`${baseClass}__filters-calendars`}>
          <Button
            aria-controls="schoolYearsMenu"
            aria-expanded={isSchoolYearMenuOpen}
            aria-label="School Years"
            className={`${baseClass}__filters-calendars-toggle`}
            color="light"
            link
            onClick={e => {
              e.preventDefault();
              setSchoolYearMenuOpen(!isSchoolYearMenuOpen);
            }}
          >
            School Years
            <img src={caretWhiteIcon} aria-hidden alt="caret" className={schoolYearsCaretClasses} />
          </Button>

          {shortenedSchoolYear && !isSchoolYearMenuOpen ? (
            <div className={`${baseClass}__filters-calendars-value`}>{shortenedSchoolYear}</div>
          ) : (
            <Collapse
              className={`${baseClass}__filters-calendars-options`}
              id="schoolYearsMenu"
              role="menu"
              onEntered={onSchoolYearsMenuEntered}
              {...collapseProps}
            >
              {schoolYears.map(year => (
                <button
                  aria-current={isSelectedSchoolYear(year)}
                  aria-label={year.description}
                  className={schoolYearOptionClasses(year)}
                  key={year.id}
                  id={`schoolYearOption${year.id}`}
                  role="menuitem"
                  type="button"
                  onClick={() => changeSchoolYear(year)}
                >
                  {getShortenedSchoolYear(year)}
                </button>
              ))}
            </Collapse>
          )}
        </div>
        {canSeeArchivedClasses && (
          <>
            <hr />
            <div className={`${baseClass}__filters-archived`}>
              <div className={`${baseClass}__filters-archived-switch`}>
                <span className={`${baseClass}__filters-archived-label`}>Archived Classes</span>
                <ToggleSwitch
                  className={`${baseClass}__filters-archived-control`}
                  toggled={showArchivedClasses?.value}
                  onToggle={(e, value) => setShowArchivedClasses({ value })}
                />
                <span
                  className={classNames(
                    `${baseClass}__filters-archived-status`,
                    showArchivedClasses?.value && `${baseClass}__filters-archived-status--active`,
                  )}
                >
                  {showArchivedClasses?.value ? 'On' : 'Off'}
                </span>
              </div>
            </div>
          </>
        )}
      </>
    );
  }, [
    baseClass,
    canSeeArchivedClasses,
    changeSchoolYear,
    collapseProps,
    isSchoolYearMenuOpen,
    schoolYear,
    schoolYears,
    schoolYearsCaretClasses,
    shortenedSchoolYear,
    setShowArchivedClasses,
    showArchivedClasses,
    showArchivedClasses?.value,
  ]);

  return (
    <div className={classes}>
      {isMedium && <span className={`${baseClass}__filters-label`}>FILTERS</span>}

      <button
        type="button"
        className={`${baseClass}__filters-toggle`}
        ref={filterMenuButtonRef}
        onClick={e => {
          e.preventDefault();
          if (!isMedium) {
            setFilterMenuOpen(!isFilterMenuOpen);
          }
        }}
      >
        {isMedium ? (
          <>
            {shortenedSchoolYear || 'No Filters'}
            <img src={caretIcon} aria-hidden alt="caret" className={filtersCaretClasses} />
          </>
        ) : (
          <>
            FILTERS <Badge>{activeFilters}</Badge>
          </>
        )}
      </button>

      {isMedium ? (
        <Popover
          hideArrow
          container="inline"
          className={`${baseClass}__filters-popover`}
          boundariesElement="viewport"
          isOpen={isFilterMenuOpen}
          placement="bottom-end"
          target={filterMenuButtonRef}
          toggle={() => setFilterMenuOpen(!isFilterMenuOpen)}
        >
          <div ref={popoverOuterClickRef}>
            <PopoverBody className={`${baseClass}__filters-menu`}>{FiltersMenus}</PopoverBody>
          </div>
        </Popover>
      ) : (
        <Modal
          className={`${baseClass}__filters-modal`}
          isOpen={isFilterMenuOpen}
          toggle={() => setFilterMenuOpen(!isFilterMenuOpen)}
        >
          <ModalHeader>
            FILTERS <Badge>{activeFilters}</Badge>
          </ModalHeader>
          <ModalBody className={`${baseClass}__filters-menu`}>{FiltersMenus}</ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default Filters;
