import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import { Control, Controller, FieldError } from 'react-hook-form';
import { FormFeedback, SelectControl, SelectOption } from '@scholastic/volume-react';

import './Select.scss';

export interface SelectProps {
  /**
   * Form error object
   */
  error?: FieldError;

  /**
   * Label
   */
  label: string;

  /**
   * Input field name
   */
  name: string;

  /**
   * Input field placeholder
   */
  placeholder: string;

  /**
   * React hook forms control
   */
  control: Control;

  /**
   * Options
   */
  options: SelectOption[];

  /**
   * Selected option value
   */
  selectedOptionValue?: string;

  /**
   * Whether the menu is open or not
   */
  menuIsOpen?: boolean;
}

export const Select = ({
  error,
  label,
  name,
  placeholder,
  control,
  options,
  selectedOptionValue,
  ...otherProps
}: SelectProps) => {
  const id = useMemo(() => `id-${nanoid()}`, []);

  return (
    <>
      <label className="sdm-select__input-label" htmlFor={name} id={id}>
        <span className="sdm-select__input-label-text">{label}</span>

        <Controller
          control={control}
          name={name}
          render={({ onChange }) => (
            <SelectControl
              {...otherProps}
              aria-labelledby={id}
              invalid={!!error}
              value={options.find(({ value }) => value === selectedOptionValue)}
              options={options}
              placeholder={placeholder}
              onChange={({ value }: { value: string }) => onChange(value)}
            />
          )}
        />
      </label>

      <FormFeedback className="sdm-select__feedback">{error?.message}</FormFeedback>
    </>
  );
};

export default Select;
