import { SchoolCalendar } from '@scholastic/volume-react';
import moment from 'moment';

export function getDifferenceInYears(origin: SchoolCalendar, target: SchoolCalendar): number {
  return moment(target.endDate).year() - moment(origin.endDate).year();
}

export function isFutureYear(current: SchoolCalendar, target: SchoolCalendar): boolean {
  return getDifferenceInYears(current, target) > 0;
}

export function isPastYear(current: SchoolCalendar, target: SchoolCalendar): boolean {
  return getDifferenceInYears(current, target) < 0;
}
