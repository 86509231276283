import React, { RefObject } from 'react';
import classNames from 'classnames';
import {
  Avatar,
  AvatarRole,
  FabItem,
  FabMenu,
  getInitials,
  IconButton,
  PageHeader,
  Student,
  useWindowQuery,
} from '@scholastic/volume-react';

import './StudentDetailsPageHeader.scss';
import iconCog from '../../../assets/img/icon-cog.svg';
import iconPencil from '../../../assets/img/icon-pencil.svg';
import iconTrash from '../../../assets/img/icon-trash.svg';
import capitalize from '../../../utils/capitalize';
import LoginInfoPopover, {
  LoginInfoPopoverClass,
} from '../../../components/LoginInfoPopover/LoginInfoPopover';
import { createRemoveStudentConfirmationDialog } from '../../ClassDetails/ClassDetailsStudents/ClassDetailsStudents';

export interface ClassDetailsPageHeaderProps {
  classDisplayName?: string;
  schoolName?: string;
  selectedClass?: LoginInfoPopoverClass;
  selectedStudent?: Student;
  showEasyLogin: boolean;
  navigateToEditStudentPage?: () => void;
  studentId: string;
  removeStudentFromClass: (id: string) => Promise<void>;
  navigateToClassDetailsPage: () => void;
}

export const StudentDetailsPageHeader = ({
  classDisplayName,
  schoolName,
  selectedClass,
  selectedStudent,
  showEasyLogin,
  navigateToEditStudentPage,
  studentId,
  removeStudentFromClass,
  navigateToClassDetailsPage,
}: ClassDetailsPageHeaderProps) => {
  const baseClass = 'sdm-student-details-page-header';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  const fullName = `${capitalize(selectedStudent?.firstName)} ${capitalize(
    selectedStudent?.lastName,
  )}`;

  const initials = getInitials(fullName);

  return (
    <PageHeader className={classNames(responsiveClassName)} filterByRole hideOrgSelect>
      <div className={`${baseClass}__column ${baseClass}__column--left`}>
        <div className={`${baseClass}__avatar-wrapper`}>
          <Avatar
            className={`${baseClass}__avatar`}
            colorKey={initials}
            role={AvatarRole.Student}
            size="medium"
          >
            {initials}
          </Avatar>

          <div className={`${baseClass}__school-info`}>
            <span className={`${baseClass}__school-name`}>
              <span className={`${baseClass}__school-name-inner`}>{schoolName}</span>
            </span>

            <h1 className={`${baseClass}__student-name`}>{fullName}</h1>
          </div>
        </div>
      </div>

      <div className={`${baseClass}__column ${baseClass}__column--right`}>
        {selectedClass && selectedStudent && (
          <LoginInfoPopover
            popoverClassName={`${baseClass}__login-info-popover`}
            selectedClass={selectedClass}
            showEasyLogin={showEasyLogin}
            students={[selectedStudent]}
            isSingleStudentMode
          />
        )}

        <FabMenu
          className={`${baseClass}__manage-menu`}
          placement="bottom"
          renderFab={(ref, props) => (
            <IconButton
              ref={ref as RefObject<HTMLButtonElement>}
              {...props}
              renderIcon={() => (
                <div className={`${baseClass}__icon-wrapper`}>
                  <img className={`${baseClass}__icon`} src={iconCog} alt="Cog Icon" />
                </div>
              )}
            >
              Manage
            </IconButton>
          )}
        >
          {navigateToEditStudentPage && (
            <FabItem
              icon={<img src={iconPencil} alt="Pencil Icon" />}
              onClick={navigateToEditStudentPage}
            >
              Edit student info
            </FabItem>
          )}

          <FabItem
            icon={<img src={iconTrash} alt="Remove Icon" />}
            onClick={() =>
              createRemoveStudentConfirmationDialog({
                id: studentId,
                fullName,
                removeStudentFromClass,
                onConfirm: navigateToClassDetailsPage,
              })()
            }
          >
            Remove student
          </FabItem>
        </FabMenu>
      </div>
    </PageHeader>
  );
};

export default StudentDetailsPageHeader;
