import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Badge, Button, ToggleSwitch, useWindowQuery, notify } from '@scholastic/volume-react';

import './AppCard.scss';
import IconClass from '../../../../assets/img/icon-class.svg';
import IconCaret from '../../../../assets/img/icon-caret.svg';
import capitalize from '../../../../utils/capitalize';
import { StudentWithEntitlementData } from '../ClassDetailsApps';

export interface AppCardProps {
  areAllStudentsEntitled: boolean;
  areNoStudentsEntitled: boolean;
  className?: string;
  disableAllEntitlements: () => void;
  enableAllEntitlements: () => void;
  entitledStudentsCount: number;
  isExpanded: boolean;
  name: string;
  onCaretClick: () => void;
  studentsWithEntitlementData: StudentWithEntitlementData[];
  thumbnail: string;
  toggleStudentEnrollment: (studentId: string, isToggled: boolean) => Promise<void>;
  isArchived?: boolean;
}

const AppCard = ({
  areAllStudentsEntitled,
  areNoStudentsEntitled,
  className,
  disableAllEntitlements,
  enableAllEntitlements,
  entitledStudentsCount,
  isExpanded,
  name,
  onCaretClick,
  studentsWithEntitlementData,
  thumbnail,
  toggleStudentEnrollment,
  isArchived,
}: AppCardProps) => {
  const baseClass = 'sdm-app-card';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const [dictionary, setDictionary] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const newDictionary = studentsWithEntitlementData.reduce<Record<string, boolean>>(
      (accLower, { id: studentId, isEntitled }) => ({ ...accLower, [studentId]: isEntitled }),
      {},
    );

    setDictionary(newDictionary);
  }, [studentsWithEntitlementData]);

  const enableAllElement = (
    <Button
      className={`${baseClass}__button`}
      color="default"
      link
      onClick={enableAllEntitlements}
      disabled={!studentsWithEntitlementData.length}
    >
      Enable all
    </Button>
  );

  const deactivateAllElement = (
    <Button
      className={`${baseClass}__button`}
      color="default"
      link
      onClick={disableAllEntitlements}
    >
      Deactivate all
    </Button>
  );

  const caret = (
    <button
      className={`${baseClass}__caret`}
      type="button"
      onClick={onCaretClick}
      disabled={!studentsWithEntitlementData.length}
    >
      <img className={`${baseClass}__caret-icon`} src={IconCaret} alt="Caret" />
    </button>
  );

  let studentAccessText;
  if (areAllStudentsEntitled && entitledStudentsCount < 2) {
    studentAccessText = (
      <>
        <strong>All</strong> students have
      </>
    );
  } else if (areAllStudentsEntitled && entitledStudentsCount >= 2) {
    studentAccessText = (
      <>
        <strong>All {entitledStudentsCount}</strong> students have
      </>
    );
  } else if (!areAllStudentsEntitled && entitledStudentsCount === 1) {
    studentAccessText = (
      <>
        <strong>{entitledStudentsCount}</strong> student has
      </>
    );
  } else {
    studentAccessText = (
      <>
        <strong>{entitledStudentsCount}</strong> students have
      </>
    );
  }

  const controls = (
    <div className={`${baseClass}__controls`}>
      <div className={`${baseClass}__title`}>{studentAccessText} access</div>

      <div className={`${baseClass}__buttons`}>
        {areAllStudentsEntitled && deactivateAllElement}

        {areNoStudentsEntitled && enableAllElement}

        {!areAllStudentsEntitled && !areNoStudentsEntitled && (
          <>
            {enableAllElement}

            <span className={`${baseClass}__buttons-separator`} />

            {deactivateAllElement}
          </>
        )}
      </div>
    </div>
  );

  const studentsWrapper = !isArchived && isExpanded && studentsWithEntitlementData.length && (
    <div className={`${baseClass}__students-wrapper`}>
      <h2 className={`${baseClass}__students-title`}>ACCESS</h2>

      <ul className={`${baseClass}__students`}>
        {studentsWithEntitlementData.map(({ id, firstName, lastName }) => {
          const fullName = `${capitalize(firstName)} ${capitalize(lastName)}`;

          return (
            <li key={id} className={`${baseClass}__student`}>
              <span className={`${baseClass}__student-name`}>{fullName}</span>

              <ToggleSwitch
                disabled={isArchived}
                className={`${baseClass}__toggle`}
                onToggle={async (e, changeTo) => {
                  try {
                    await toggleStudentEnrollment(id, changeTo);

                    setDictionary(prev => ({ ...prev, [id]: changeTo }));
                  } catch (e) {
                    notify.warning(
                      `The subscription could not be ${
                        changeTo ? 'enabled' : 'disabled'
                      } for ${fullName}`,
                    );
                  }
                }}
                toggled={dictionary[id]}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <div
      className={classNames(responsiveClassName, className, {
        [`${baseClass}--expanded`]: isExpanded,
      })}
    >
      <div className={`${baseClass}__inner`}>
        <div className={`${baseClass}__thumbnail-wrapper`}>
          <img className={`${baseClass}__thumbnail`} src={thumbnail} alt={name} />
        </div>

        <div className={`${baseClass}__wrapper`}>
          <div className={`${baseClass}__general-info`}>
            <div className={`${baseClass}__middle-section`}>
              <div
                className={classNames(
                  `${baseClass}__students-with-badge`,
                  isArchived && `${baseClass}__students-with-badge--archived)`,
                )}
              >
                <img className={`${baseClass}__students-icon`} src={IconClass} alt="Students" />

                <Badge
                  className={classNames(`${baseClass}__students-badge`, {
                    [`${baseClass}__students-badge--success`]: entitledStudentsCount,
                  })}
                >
                  {entitledStudentsCount}
                </Badge>

                {!isMedium && !isArchived && caret}
              </div>

              {isMedium && !isArchived && controls}
            </div>

            {isMedium && (
              <div className={`${baseClass}__caret-wrapper`}>{!isArchived && caret}</div>
            )}
          </div>

          {isMedium && studentsWrapper}
        </div>

        {!isMedium && !isArchived && controls}

        {!isMedium && studentsWrapper}
      </div>
    </div>
  );
};

export default AppCard;
