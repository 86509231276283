import React from 'react';
import {
  ActionCard,
  ActionCardLayout,
  ActionCardType,
  ProgramCard,
  SectionHeader,
  SubscriptionData,
  useClasses,
  useWindowQuery,
} from '@scholastic/volume-react';

import TeacherProgramCardList from './TeacherProgramCardList';
import '../../components/ProgramCardList/ProgramCardList.scss';

export interface TeacherCurriculumResourceListProps extends TeacherProgramCardList {
  isAddNewProgramDisabled: boolean;
}

export const TeacherCurriculumResourceList = ({
  subscriptions,
  onCardClick,
  onProgramCardClick,
  isAddNewProgramDisabled,
}: TeacherCurriculumResourceListProps) => {
  const baseClass = 'sdm-program-card-list';

  const { responsiveClassName, isMedium } = useWindowQuery({
    className: baseClass,
  });

  const classes = useClasses({ baseClass }, [responsiveClassName]);

  return (
    <>
      <div className="sdm-program-card-list__header">
        <SectionHeader tabs={[{ label: 'Curriculum Resources', value: '' }]} />
      </div>

      <div className={classes}>
        <div className={`${baseClass}__inner`}>
          {subscriptions.map((subscriptionData: SubscriptionData) => (
            <ProgramCard
              key={subscriptionData.subscription.id}
              layout={isMedium ? 'large' : 'small'}
              subscriptionData={subscriptionData}
              onCardClick={onCardClick}
            />
          ))}

          {!isAddNewProgramDisabled && (
            <ActionCard
              heading="ADD NEW PROGRAM"
              layout={isMedium ? ActionCardLayout.Large : ActionCardLayout.Small}
              subheading="with an access code"
              type={ActionCardType.Program}
              onClick={onProgramCardClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TeacherCurriculumResourceList;
