import { useEffect, useState } from 'react';

import { ClassDetailsProps } from './ClassDetails';

export type UseGetShowEasyLoginProps = Pick<ClassDetailsProps, 'getShowEasyLogin'>;

export const useGetShowEasyLogin = ({ getShowEasyLogin }: UseGetShowEasyLoginProps) => {
  const [shouldShowEasyLogin, setShouldShowEasyLogin] = useState(false);

  useEffect(() => {
    (async () => setShouldShowEasyLogin(await getShowEasyLogin()))();
  }, [getShowEasyLogin]);

  return shouldShowEasyLogin;
};

export default useGetShowEasyLogin;
