import React, { useEffect, useMemo } from 'react';
import { Control, Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { FormFeedback, SelectControl } from '@scholastic/volume-react';

import { TeacherClassCreateFormData } from './TeacherClassCreate';

export interface GradeSelectOption {
  label: string;
  value: string;
}

export interface SelectHighGradeProps {
  baseClass: string;
  grades: GradeSelectOption[];
  selectedLowGrade: string;
  selectedHighGrade?: string | null;
  isMedium: boolean;
  control: Control<TeacherClassCreateFormData>;
  errors: DeepMap<TeacherClassCreateFormData, FieldError>;
}

export const SelectHighGrade = ({
  baseClass,
  control,
  errors,
  selectedLowGrade,
  selectedHighGrade,
  grades,
  isMedium,
}: SelectHighGradeProps) => {
  const equalOrHigherGrades = useMemo(
    () => grades.slice(grades.findIndex(({ value }) => value === selectedLowGrade) + 1),
    [grades, selectedLowGrade],
  );

  return (
    <>
      <label
        className={`${baseClass}__input-label`}
        htmlFor="highGrade"
        id="teacher-class-create-high-grade-label"
      >
        <span className={`${baseClass}__input-label-text`}>High Grade</span>

        <Controller
          control={control}
          name="highGrade"
          render={({ onChange }) => (
            <SelectControl
              // passing in a key makes the underlying <ReactSelect />
              // component to re-mount, thereby clearing the selected value.
              //
              // In this case, we only re-mount (and thereby clear the selected
              // value) whenever the `selectedHighGrade` switches between being
              // set and being `undefined`.
              //
              // @see https://stackoverflow.com/a/55142916
              key={`${selectedHighGrade}`}
              aria-labelledby="teacher-class-create-high-grade-label"
              invalid={!!errors.highGrade}
              value={grades.find(({ value }) => value === selectedHighGrade)}
              options={equalOrHigherGrades}
              placeholder={isMedium ? 'Select grade' : 'Grade'}
              onChange={({ value }: { value: string }) => onChange(value)}
            />
          )}
        />
      </label>

      <FormFeedback className={`${baseClass}__feedback`}>{errors.highGrade?.message}</FormFeedback>
    </>
  );
};

export default SelectHighGrade;
