import { useEffect, useMemo, useState } from 'react';
import { SectionHeaderTabProps, Student } from '@scholastic/volume-react';

import { ClassDetailsProps, ClassDetailsSelectedClass } from './ClassDetails';
import useShowEasyLogin from './use-show-easy-login';

export interface UseGetTabsProps
  extends Pick<ClassDetailsProps, 'isAppsPage' | 'isEasyLoginPage' | 'subscriptions'> {
  canViewEasyLogin?: boolean;
  isClassInfoPage?: boolean;
  isCurrentSchoolYear?: boolean;
  isMedium?: boolean;
  isPastSchoolYear?: boolean;
  selectedClass?: ClassDetailsSelectedClass;
  students?: Student[];
}

export const useGetTabs = ({
  canViewEasyLogin,
  isAppsPage,
  isClassInfoPage,
  isCurrentSchoolYear,
  isEasyLoginPage,
  isMedium,
  isPastSchoolYear,
  selectedClass,
  students,
  subscriptions,
}: UseGetTabsProps) => {
  const numberOfStudents = students?.length;
  const easyLoginEnabled = selectedClass?.easyLogin?.enabled;

  const { bannerIsShown, isEasyLoginShown } = useShowEasyLogin({ selectedClass });

  const studentsTab = useMemo<SectionHeaderTabProps>(
    () => ({
      label: 'Students',
      value: 'students',
      badgeProps: numberOfStudents ? { children: `${numberOfStudents}` } : undefined,
    }),
    [numberOfStudents],
  );

  const easyLoginTab = useMemo<SectionHeaderTabProps>(
    () => ({
      label: 'Easy Login',
      value: 'easy-login',
      badgeProps: {
        children: easyLoginEnabled ? 'on' : 'off',
        hasCircle: bannerIsShown,
      },
    }),
    [easyLoginEnabled, bannerIsShown],
  );

  const subscriptionsCount = subscriptions?.length || 0;
  const isActive = selectedClass?.active;

  const appsTab = useMemo<SectionHeaderTabProps>(
    () => ({
      label: 'Apps',
      value: 'apps',
      badgeProps: { children: subscriptionsCount },
    }),
    [subscriptionsCount],
  );

  const classInfoTab = useMemo<SectionHeaderTabProps>(
    () => ({
      label: 'Class Info',
      value: 'class-info',
    }),
    [],
  );

  const tabs = useMemo<SectionHeaderTabProps[]>(
    () => [
      studentsTab,
      ...(!isPastSchoolYear && isCurrentSchoolYear && subscriptionsCount && isActive
        ? [appsTab]
        : []),
      ...(!isPastSchoolYear && isEasyLoginShown && canViewEasyLogin && isActive
        ? [easyLoginTab]
        : []),
      ...(!isMedium ? [classInfoTab] : []),
    ],
    [
      appsTab,
      canViewEasyLogin,
      classInfoTab,
      easyLoginTab,
      isCurrentSchoolYear,
      isEasyLoginShown,
      isMedium,
      isPastSchoolYear,
      studentsTab,
      subscriptionsCount,
      isActive,
    ],
  );

  const [activeTab, setActiveTab] = useState<SectionHeaderTabProps>(tabs[0]);

  useEffect(() => {
    if (isEasyLoginPage && isActive) {
      setActiveTab(easyLoginTab);

      return;
    }

    if (isAppsPage && isActive) {
      setActiveTab(appsTab);

      return;
    }

    if (!isMedium && isClassInfoPage) {
      setActiveTab(classInfoTab);

      return;
    }

    setActiveTab(studentsTab);
  }, [
    appsTab,
    classInfoTab,
    easyLoginTab,
    isAppsPage,
    isClassInfoPage,
    isEasyLoginPage,
    isMedium,
    studentsTab,
    isActive,
  ]);

  return { tabs, activeTab };
};

export default useGetTabs;
