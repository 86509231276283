import React, { ReactNode, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { Control, Controller, FieldError } from 'react-hook-form';
import { FormFeedback, SelectWithImages } from '@scholastic/volume-react';

import './SelectFa.scss';

export interface SelectFaOptionValue {
  id: string;
  class: string;
  description: string;
  color: string;
}

export interface SelectFaOption {
  id: string;
  label: string;
  image: ReactNode;
  value: SelectFaOptionValue;
}

export interface SelectFaProps {
  name: string;
  label: string;
  placeholder: string;
  options: SelectFaOption[];
  selectedOption?: SelectFaOption;
  readOnly?: boolean;
  control: Control<object>;
  error?: FieldError;
}

export const SelectFa = ({
  name,
  label,
  placeholder,
  options,
  selectedOption,
  readOnly,
  control,
  error,
}: SelectFaProps) => {
  const baseClass = 'sdm-select-fa';

  const id = useMemo(() => `sdm-select-fa-${nanoid()}`, []);

  return (
    <>
      <label className={`${baseClass}__input-label`} htmlFor={name} id={id}>
        <span className={`${baseClass}__input-label-text`}>{label}</span>

        <Controller
          control={control}
          name={name}
          render={({ onChange }) => (
            <SelectWithImages
              aria-labelledby={id}
              invalid={!!error}
              placeholder={placeholder}
              onChange={({ value }: SelectFaOption) => onChange(value)}
              value={options.find(({ id }) => id === selectedOption?.id)}
              options={options}
              menuIsOpen={readOnly ? false : undefined}
            />
          )}
        />
      </label>

      <FormFeedback className={`${baseClass}__feedback`}>{error?.message}</FormFeedback>
    </>
  );
};

export default SelectFa;
