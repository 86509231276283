import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import {
  Button,
  Container,
  Form,
  FormGroup,
  getGrades,
  GradeKey,
  Metric,
  ModalBody,
  ModalDialog,
  ModalHeader,
  Notifier,
  Organization,
  PageBanner,
  PersonSearchOptionData,
  ResponsivityProvider,
  SchoolCalendar,
  useClasses,
  useUser,
  useWindowQuery,
} from '@scholastic/volume-react';

import './TeacherClassCreate.scss';
import useOrganizations, { UseOrganizationsReturn } from './use-organizations';
import useCalendars, { UseCalendarsReturn } from './use-calendars';
import SelectOrganization from './SelectOrganization';
import SelectCalendar from './SelectCalendar';
import SelectLowGrade from './SelectLowGrade';
import SelectHighGrade from './SelectHighGrade';
import AddCoTeachers from './AddCoTeachers';
import useGrades from '../../behaviors/use-grades/use-grades';
import CloseIcon from '../../assets/img/icon-close.svg';
import useCreateNewClassFactory from './use-create-new-class-factory';
import useUpdateExistingClassFactory from './use-update-existing-class-factory';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

export interface TeacherClassCreateFormData {
  organizationId: string;
  schoolCalendarId: string;
  nickname: string;
  lowGrade: string;
  highGrade?: string | null;
  coTeachers: { value: { value: string; email: string } }[];
  staff: { primaryTeacherId: string };
  easyLogin: { enabled: false };
  showSingleGradeInput: boolean;
}

export interface TeacherClassCreateDefaultValues
  extends Omit<TeacherClassCreateFormData, 'staff' | 'easyLogin'> { }

export interface TeacherClassCreateTeacher {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface TeacherClassCreateSelectedClassStaff {
  primaryTeacher: TeacherClassCreateTeacher;
  teachers: TeacherClassCreateTeacher[];
}

export interface TeacherClassCreateSelectedClass {
  nickname: string;
  organizationId: string;
  schoolCalendarId: string;
  lowGrade: GradeKey;
  highGrade: GradeKey | null;
  staff?: TeacherClassCreateSelectedClassStaff;
}

export interface TeacherClassCreateProps {
  createClass: (
    classSection: Omit<TeacherClassCreateFormData, 'coTeachers' | 'showSingleGradeInput'>,
  ) => Promise<{ id: string }>;
  addTeacherToClass: (classSectionId: string, orgId: string, teacherId: string) => Promise<void>;
  removeTeacherFromClass: (
    classSectionId: string,
    orgId: string,
    teacherId: string,
  ) => Promise<void>;
  getCalendarForOrg: (org: { id: string }) => Promise<void>;
  calendars?: SchoolCalendar[];
  organizations?: Organization[];
  loadSelectedClass: (calendar: SchoolCalendar) => TeacherClassCreateSelectedClass;
  classId?: string;
  updateClass?: (classSection?: TeacherClassCreateSelectedClass) => Promise<void>;
}

export interface TeacherClassCreateInnerProps
  extends TeacherClassCreateProps,
  UseOrganizationsReturn,
  UseCalendarsReturn {
  selectedClass?: TeacherClassCreateSelectedClass;
  existingCoTeachers?: PersonSearchOptionData[];
  isEditMode: boolean;
  defaultFormValues: TeacherClassCreateDefaultValues;
}

export const getEmptyFormTeacher = () => ({ value: { value: '', email: '' } });

export const teacherClassCreateSchema = yup.object().shape({
  coTeachers: yup.array().of(
    yup.object({
      value: yup.object({
        value: yup.string().when('email', {
          is: val => !!val,
          then: yup.string().required(),
        }),
        email: yup
          .string()
          .matches(/^$|^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/, 'Please enter a valid email address.'),
      }),
    }),
  ),
  lowGrade: yup
    .string()
    .label('Low grade')
    .test('valid', 'Please select a valid grade.', value => {
      const grades = getGrades(value);

      return !!grades[0];
    }),
  highGrade: yup
    .string()
    .nullable()
    .when('showSingleGradeInput', {
      is: val => !val,
      then: yup.string().required(),
    })
    .label('High grade')
    .test('valid', 'Please select a valid grade.', value => {
      if (value === undefined || value === null) return true;

      const grades = getGrades(value);

      return !!grades[0];
    }),
  nickname: yup
    .string()
    .label('Class name')
    .max(30)
    .required()
    .matches(
      /^[a-zA-Z0-9-#_.' ]+$/,
      "Only letters (a-z), numbers (0-9), and symbols (#_.') are allowed",
    ),
  organizationId: yup
    .string()
    .label('Building')
    .required(),
  schoolCalendarId: yup
    .string()
    .label('School year')
    .required(),
});

export const TeacherClassCreateInner = ({
  createClass,
  getCalendarForOrg,
  addTeacherToClass,
  removeTeacherFromClass,
  classId,
  organizationOptions,
  selectedOrganization,
  setSelectedOrganization,
  calendarOptions,
  selectedCalendarOption,
  setSelectedCalendarOption,
  selectedClass,
  existingCoTeachers,
  isEditMode,
  defaultFormValues,
  updateClass,
}: TeacherClassCreateInnerProps) => {
  const baseClass = 'sdm-teacher-class-create';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });
  const classes = useClasses({ baseClass }, [responsiveClassName]);

  const { firstName, lastName } = useUser();
  const { firstName: primaryFirstName, lastName: primaryLastName } = selectedClass?.staff
    ?.primaryTeacher || { firstName: '', lastName: '' };

  const {
    register,
    handleSubmit,
    errors,
    control,
    formState: { isValid },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<TeacherClassCreateFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(teacherClassCreateSchema),
    defaultValues: defaultFormValues,
  });

  const lowGrade = watch('lowGrade') || 'pk';
  const highGrade = watch('highGrade');
  const showSingleGradeInput = watch('showSingleGradeInput');

  const sortedGrades = useGrades();

  useEffect(() => {
    if (!showSingleGradeInput && !isEditMode) {
      if (lowGrade === sortedGrades[sortedGrades.length - 1].value) {
        setValue('highGrade', sortedGrades[sortedGrades.length - 1].value);
        setValue('lowGrade', sortedGrades[sortedGrades.length - 2].value);

        return;
      }
      const lowGradeIndex = sortedGrades.findIndex(({ value }) => value === lowGrade);
      setValue('highGrade', sortedGrades[lowGradeIndex + 1].value);
    } else {
      setValue('lowGrade', lowGrade);
    }
  }, [showSingleGradeInput, setValue, sortedGrades, lowGrade]);

  const updateExistingClassHandler = useUpdateExistingClassFactory({
    getValues,
    updateClass,
    selectedClass,
    existingCoTeachers,
    addTeacherToClass,
    removeTeacherFromClass,
    classId,
    showSingleGradeInput,
  });

  const createNewClassHandler = useCreateNewClassFactory({
    getValues,
    createClass,
    showSingleGradeInput,
    addTeacherToClass,
  });

  return (
    <div className={classes}>
      <PageBanner className={`${baseClass}__banner`} />

      <Container className={`${baseClass}__container`}>
        <ModalDialog className={`${baseClass}__dialog`}>
          <ModalHeader
            className={`${baseClass}__modal-header`}
            close={
              <Button
                className={`${baseClass}__close-button`}
                close
                type="button"
                aria-label="Navigate out"
                onClick={() => window.history.back()}
              >
                <span aria-hidden="true">
                  <img className={`${baseClass}__close-icon`} src={CloseIcon} alt="Navigate out" />
                </span>
              </Button>
            }
          >
            {isEditMode ? 'Edit Class Info' : 'New Class Info'}
          </ModalHeader>

          <ModalBody>
            <Form
              className={`${baseClass}__form`}
              onSubmit={handleSubmit(
                isEditMode ? updateExistingClassHandler : createNewClassHandler,
              )}
            >
              <div className={`${baseClass}__section`}>
                <FormGroup className={`${baseClass}__building`}>
                  {isEditMode ? (
                    <Metric
                      className={`${baseClass}__metric`}
                      headingClassName={`${baseClass}__metric-heading`}
                      contentClassName={`${baseClass}__metric-content`}
                      datum={selectedOrganization?.label}
                      definition="Building"
                      reversed
                    />
                  ) : (
                    <SelectOrganization
                      baseClass={baseClass}
                      control={control}
                      errors={errors}
                      organizationOptions={organizationOptions}
                      selectedOrganization={selectedOrganization}
                      setSelectedOrganization={setSelectedOrganization}
                      getCalendarForOrg={getCalendarForOrg}
                      setValue={setValue}
                    />
                  )}
                </FormGroup>

                <FormGroup className={`${baseClass}__calendar`}>
                  {isEditMode ? (
                    <Metric
                      className={`${baseClass}__metric`}
                      headingClassName={`${baseClass}__metric-heading`}
                      contentClassName={`${baseClass}__metric-content`}
                      datum={selectedCalendarOption?.label}
                      definition="School Year"
                      reversed
                    />
                  ) : (
                    <SelectCalendar
                      baseClass={baseClass}
                      control={control}
                      errors={errors}
                      calendarOptions={calendarOptions}
                      selectedCalendarOption={selectedCalendarOption}
                      setSelectedCalendarOption={setSelectedCalendarOption}
                      setValue={setValue}
                    />
                  )}
                </FormGroup>

                <FormGroup className={`${baseClass}__primary-teacher`}>
                  <Metric
                    className={`${baseClass}__metric`}
                    headingClassName={`${baseClass}__metric-heading`}
                    contentClassName={`${baseClass}__metric-content`}
                    datum={
                      isEditMode
                        ? `${primaryFirstName} ${primaryLastName}`
                        : `${firstName} ${lastName}`
                    }
                    definition="Primary Teacher"
                    reversed
                  />
                </FormGroup>
              </div>

              <div className={`${baseClass}__section ${baseClass}__section--no-margin`}>
                <FormGroup className={`${baseClass}__class-name`}>
                  <Input
                    error={errors.nickname}
                    label="Class Name"
                    name="nickname"
                    placeholder="Enter class name"
                    ref={register}
                  />
                </FormGroup>

                <div className={`${baseClass}__grades-wrapper`}>
                  {showSingleGradeInput ? (
                    <FormGroup className={`${baseClass}__single-grade`}>
                      <Select
                        error={errors.lowGrade}
                        label="Grade"
                        name="lowGrade"
                        placeholder="Select grade"
                        control={control}
                        options={sortedGrades}
                        selectedOptionValue={lowGrade}
                      />
                    </FormGroup>
                  ) : (
                    <div className={`${baseClass}__grades`}>
                      <FormGroup className={`${baseClass}__grade ${baseClass}__grade--multiple`}>
                        <SelectLowGrade
                          data-testid="lowGradeDropdown"
                          baseClass={baseClass}
                          control={control}
                          errors={errors}
                          grades={sortedGrades}
                          selectedLowGrade={lowGrade}
                          selectedHighGrade={highGrade}
                          setValue={setValue}
                          isMedium={isMedium}
                        />
                      </FormGroup>

                      <span className={`${baseClass}__grades-separator`}>to</span>

                      <FormGroup className={`${baseClass}__grade ${baseClass}__grade--multiple`}>
                        <SelectHighGrade
                          baseClass={baseClass}
                          control={control}
                          errors={errors}
                          grades={sortedGrades}
                          selectedLowGrade={lowGrade}
                          selectedHighGrade={highGrade}
                          isMedium={isMedium}
                        />
                      </FormGroup>
                    </div>
                  )}
                </div>
              </div>

              <div className={`${baseClass}__section ${baseClass}__section--margin-small`}>
                <Button
                  className={`${baseClass}__grade-input-toggle`}
                  color="default"
                  link
                  onClick={() => {
                    const showSingleGradeInputToggled = !showSingleGradeInput;

                    setValue('showSingleGradeInput', showSingleGradeInputToggled);

                    if (showSingleGradeInputToggled) {
                      setValue('lowGrade', lowGrade);
                      setValue('highGrade', undefined);
                    }

                    // if `lowGrade` is unset, it means that a new class
                    // is being created, so no need to set the `highGrade`
                    else if (lowGrade) {
                      const highestGradeValue = sortedGrades[sortedGrades.length - 1].value;
                      const secondHighestGradeValue = sortedGrades[sortedGrades.length - 2].value;

                      // if `lowGrade` is set to the highest possible grade, decrement
                      // it and set the `highGrade` to the highest possible grade
                      if (lowGrade === highestGradeValue) {
                        setValue('highGrade', highestGradeValue);
                        setValue('lowGrade', secondHighestGradeValue);
                      }

                      // else - set the highGrade to `lowGrade` + 1
                      else {
                        const lowGradeIndex = sortedGrades.findIndex(
                          ({ value }) => value === lowGrade,
                        );

                        setValue('highGrade', sortedGrades[lowGradeIndex + 1].value);
                      }
                    }

                    setTimeout(() => trigger(['lowGrade', 'highGrade']));
                  }}
                >
                  {showSingleGradeInput
                    ? 'My class has multiple grades'
                    : 'My class has only one grade'}
                </Button>
              </div>

              <div
                className={`${baseClass}__section ${baseClass}__section--no-margin ${baseClass}__section--fill-right`}
              >
                <FormGroup className={`${baseClass}__co-teachers`}>
                  <AddCoTeachers
                    baseClass={baseClass}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    existingCoTeachers={existingCoTeachers}
                    isEditMode={isEditMode}
                    filterTeacherId={selectedClass?.staff?.primaryTeacher.id}
                  />
                </FormGroup>
              </div>

              <div className={`${baseClass}__controls`}>
                <Button
                  className={`${baseClass}__submit-button`}
                  aria-label="Create Class"
                  color="primary"
                  disabled={!isValid}
                  type="submit"
                >
                  {isEditMode ? 'SAVE' : 'CREATE CLASS'}
                </Button>
              </div>
            </Form>
          </ModalBody>
        </ModalDialog>
      </Container>
    </div>
  );
};

export const TeacherClassCreate = (props: TeacherClassCreateProps) => {
  const {
    // "unstable" suffix, because the prop constantly
    // changes (it's reference changes on each render)
    calendars: calendarsUnstable = [],
    // "unstable" suffix, because the prop constantly
    // changes (it's reference changes on each render)
    organizations: organizationsUnstable = [],
    classId,
    loadSelectedClass,
  } = props;

  const isEditMode = !!classId;

  const { organizationOptions, selectedOrganization, setSelectedOrganization } = useOrganizations({
    organizationsUnstable,
  });

  const {
    calendarOptions,
    selectedCalendarOption,
    setSelectedCalendarOption,
    selectedCalendar,
  } = useCalendars({
    calendarsUnstable,
    selectedOrganization,
  });

  const [selectedClass, setSelectedClass] = useState<TeacherClassCreateSelectedClass>();
  const [areDefaultFormValuesInitialized, setAreDefaultFormValuesInitialized] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<TeacherClassCreateDefaultValues>({
    coTeachers: [getEmptyFormTeacher()],
    showSingleGradeInput: true,
    lowGrade: '',
    highGrade: undefined,
    nickname: '',
    organizationId: '',
    schoolCalendarId: '',
  });

  useEffect(() => {
    if (!isEditMode) return;

    (async () => {
      if (!selectedCalendar) return;

      const data = await loadSelectedClass(selectedCalendar);

      setSelectedClass(data);

      const coTeachers = data.staff?.teachers
        .filter(({ id }) => data.staff?.primaryTeacher.id !== id)
        .map(({ firstName, lastName, email, id }) => ({
          fullName: `${firstName} ${lastName}`,
          value: id,
          email,
        }))
        .sort(({ fullName: a }, { fullName: b }) => a.localeCompare(b))
        .map(({ value, email }) => ({ value: { value, email } })) || [getEmptyFormTeacher()];

      if (!coTeachers.length) coTeachers.push(getEmptyFormTeacher());

      setDefaultFormValues({
        coTeachers,
        showSingleGradeInput: !data.highGrade || data.highGrade === data.lowGrade,
        lowGrade: data.lowGrade,
        highGrade: data.highGrade,
        nickname: data.nickname,
        organizationId: data.organizationId,
        schoolCalendarId: data.schoolCalendarId,
      });

      setAreDefaultFormValuesInitialized(true);
    })();
  }, [
    loadSelectedClass,
    selectedCalendar,
    setDefaultFormValues,
    setAreDefaultFormValuesInitialized,
  ]);

  const existingCoTeachers = useMemo(() => {
    return selectedClass?.staff?.teachers
      .filter(({ id }) => selectedClass.staff?.primaryTeacher.id !== id)
      .map(({ id, firstName, lastName, email: teacherEmail }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
        dataPoints: [teacherEmail, id],
        firstName,
        lastName,
      }));
  }, [selectedClass]);

  const enhancedProps = {
    ...props,
    organizationOptions,
    selectedOrganization,
    setSelectedOrganization,
    calendarOptions,
    selectedCalendarOption,
    setSelectedCalendarOption,
    selectedCalendar,
    existingCoTeachers,
    selectedClass,
    isEditMode,
    defaultFormValues,
  };

  return (
    <>
      {/* if "normal" mode, render straight away */}
      {!isEditMode && <TeacherClassCreateInner {...enhancedProps} />}

      {/* if "edit" mode, make sure that the "default form values" object */}
      {/* is initialized before rendering the page. This is needed in order */}
      {/* for the "co-teachers" fields to correctly render the existing data */}
      {isEditMode && areDefaultFormValuesInitialized && (
        <TeacherClassCreateInner {...enhancedProps} />
      )}
    </>
  );
};

const TeacherClassCreateWrapper = (props: TeacherClassCreateProps) => (
  <ResponsivityProvider>
    <TeacherClassCreate {...props} />

    <Notifier />
  </ResponsivityProvider>
);

export default TeacherClassCreateWrapper;
