import React, { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Application,
  ResponsivityProvider,
  SubscriptionData,
  useClasses,
  PageHeader,
} from '@scholastic/volume-react';

import useStudentProgramsApi from '../../behaviors/use-api/use-student-programs-api/use-student-programs-api';
import ProgramCardListEmpty from '../../components/ProgramCardList/ProgramCardListEmpty/ProgramCardListEmpty';
import StudentProgramCardList from './StudentProgramCardList';

export interface StudentProgramsProps {
  onCardClick: (e: SyntheticEvent, application: Application) => void;
  setInitialized: (initialized: boolean) => void;
}

export const StudentPrograms: FunctionComponent<StudentProgramsProps> = ({
  onCardClick,
  setInitialized,
}: StudentProgramsProps) => {
  const baseClass = 'sdm-student-programs';

  const [applications, setApplications] = useState<Application[]>();
  const [subscriptions, setSubscriptions] = useState<SubscriptionData[]>();

  const { appsResponseData } = useStudentProgramsApi();

  const classes = useClasses({ baseClass });

  useEffect(() => {
    setInitialized(false);
    setApplications(appsResponseData);
  }, [appsResponseData, setInitialized]);

  useEffect(() => {
    if (applications && applications.length) {
      const subs = applications.map((application: Application) => {
        // We use empty subscription and product objects because the ProgramCard is built to accept
        // them, but the apps endpoint does not include them. We need an updated apps endpoint that
        // includes the product data, and to make the subscription prop on the ProgramCard accept
        // either response type

        const { id, name } = application;

        return {
          subscription: {
            id,
            subscriptionProductId: id,
            startDate: '',
            expireDate: '',
            expired: false,
            gracePeriodDays: 0,
            rosterSource: '',
            teacherDirected: false,
            license: {
              type: '',
              quantity: 0,
              remaining: 0,
            },
          },
          product: { id, name },
          application,
        };
      });

      setSubscriptions(subs);
    } else {
      setSubscriptions([]);
    }

    setInitialized(true);
  }, [applications, setInitialized]);

  return (
    <ResponsivityProvider>
      <PageHeader filterByRole showWelcomeMessage />

      <div className={classes}>
        {applications ? (
          <>
            {subscriptions && subscriptions.length ? (
              <StudentProgramCardList subscriptions={subscriptions} onCardClick={onCardClick} />
            ) : (
              <ProgramCardListEmpty />
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <div
              role="status"
              className="vol-spinner vol-spinner__border--large vol-spinner__border"
            >
              <span className="sr-only">
                <h1>Loading</h1>
              </span>
            </div>
          </div>
        )}
      </div>
    </ResponsivityProvider>
  );
};

export default StudentPrograms;
