import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Button, Form, notify, useWindowQuery } from '@scholastic/volume-react';

import { ClassDetailsProps } from '../ClassDetails';
import StudentForm, {
  getStudentFormDefaultValues,
  getStudentFormSchema,
  StudentFormData,
} from '../../../components/StudentForm/StudentForm';
import Box from '../../../components/Box/Box';
import useGetStudentOptions from './use-get-student-options';
import useCreateLoadOptions from './use-create-load-options';
import useFormReset from '../use-form-reset';
import useOnChange from './use-on-change';
import useOnInputChange from '../use-on-input-change';
import addPeriodToString from '../../../utils/add-period-to-string';
import getGenericErrorPrefix from '../../../utils/get-generic-error-prefix';
import './ClassDetailsAddStudentForm.scss';

export interface ClassDetailsAddStudentFormProps
  extends Pick<
    ClassDetailsProps,
    'updateStudent' | 'searchForStudent' | 'addExistingStudent' | 'selectedClass'
  > {
  onCloseButtonClick: () => void;
  className?: string;
}

export const notifyAddStudentSuccess = (fullName: string) => {
  notify.success(
    <>
      Student <strong>{fullName}</strong> added.
    </>,
  );
};

export const notifyAddStudentError = (fullName: string) => {
  notify.warning(
    <>
      {getGenericErrorPrefix()} Student <strong>{fullName}</strong> could not be added at this time.
      Please try again later.
    </>,
  );
};

const ClassDetailsAddStudentForm = ({
  updateStudent,
  onCloseButtonClick,
  searchForStudent,
  addExistingStudent,
  selectedClass,
  className,
}: ClassDetailsAddStudentFormProps) => {
  const baseClass = 'sdm-class-details-add-student-form';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  const {
    handleSubmit,
    errors,
    control,
    formState: { isValid },
    getValues,
    reset,
  } = useForm<StudentFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
    resolver: yupResolver(getStudentFormSchema()),
    defaultValues: getStudentFormDefaultValues(),
  });

  const { resetForm, formResetKey } = useFormReset({ reset });

  const getStudentOptions = useGetStudentOptions({ searchForStudent });

  const createLoadOptions = useCreateLoadOptions({ getStudentOptions });
  const onChange = useOnChange({ addExistingStudent, selectedClass, resetForm });
  const onInputChange = useOnInputChange();

  return (
    <Box
      className={responsiveClassName}
      onCloseButtonClick={onCloseButtonClick}
      title="Add Students"
    >
      <Form
        className={`${baseClass}__form`}
        onSubmit={handleSubmit(async () => {
          const formData = getValues();

          formData.firstName = formData.firstName.trim();
          formData.lastName = formData.lastName.trim();
          formData.studentId = formData.studentId?.trim();

          const fullName = `${formData.firstName} ${formData.lastName}`;

          resetForm();

          try {
            await updateStudent(formData, selectedClass?.id as string);

            notifyAddStudentSuccess(fullName);
          } catch (e) {
            notify.warning(addPeriodToString(e.message));
          }
        })}
      >
        <StudentForm
          formResetKey={formResetKey}
          createLoadOptions={createLoadOptions}
          onChange={onChange}
          onInputChange={onInputChange}
          control={control}
          errors={errors}
          className={className}
        />

        <div className={`${baseClass}__controls`}>
          <Button
            className={`${baseClass}__submit-button`}
            aria-label="Add student"
            color="primary"
            disabled={!isValid}
            type="submit"
          >
            ADD STUDENT
          </Button>
        </div>
      </Form>
    </Box>
  );
};

export default ClassDetailsAddStudentForm;
