import React from 'react';
import classNames from 'classnames';
import { ToggleSwitch, useWindowQuery } from '@scholastic/volume-react';

import './EasyLoginSwitchSection.scss';

export interface EasyLoginSwitchSectionProps {
  className?: string;
  classDisplayName?: string;
  isToggled: boolean;
  toggle: (toggleState: boolean) => void;
}

export const EasyLoginSwitchSection = ({
  className,
  classDisplayName,
  isToggled,
  toggle,
}: EasyLoginSwitchSectionProps) => {
  const baseClass = 'sdm-easy-login-switch-section';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  return (
    <div className={classNames(responsiveClassName, className)}>
      <h2 className={`${baseClass}__title`}>Easy Login for {classDisplayName}</h2>

      <div className={`${baseClass}__wrapper`}>
        <ToggleSwitch onToggle={(e, changeTo) => toggle(changeTo)} toggled={isToggled} />

        <span
          className={classNames(`${baseClass}__label`, {
            [`${baseClass}__label--on`]: isToggled,
          })}
        >
          {isToggled ? 'On' : 'Off'}
        </span>
      </div>
    </div>
  );
};

export default EasyLoginSwitchSection;
