import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import {
  ActionCard,
  ActionCardLayout,
  ActionCardType,
  BannerMessage,
  BannerMessageTheme,
  Button,
  ClassCard,
  ClassCardSize,
  ClassSection,
  Container,
  FeaturesMap,
  FeaturesSchema,
  FabItem,
  FabMenu,
  Notifier,
  PageHeader,
  PageHeaderProps,
  ResponsivityProvider,
  SchoolCalendar,
  SectionHeader,
  Student,
  UserTypeFeatureFlagsProvider,
  getGrades,
  setStoreItem,
  useFeatures,
  useObjectStorage,
  useStoreItem,
  useWindowQuery,
  PageLoader,
  UserType,
} from '@scholastic/volume-react';

import backgroundImageArchivedUrl from '@scholastic/volume-core/src/assets/class-card/background-archived.png';
import backgroundImageUrl0 from '@scholastic/volume-core/src/assets/class-card/background-0.png';
import backgroundImageUrl1 from '@scholastic/volume-core/src/assets/class-card/background-1.png';
import backgroundImageUrl2 from '@scholastic/volume-core/src/assets/class-card/background-2.png';
import backgroundImageUrl3 from '@scholastic/volume-core/src/assets/class-card/background-3.png';
import backgroundImageUrl4 from '@scholastic/volume-core/src/assets/class-card/background-4.png';

import addIcon from '../../assets/img/icon-add.svg';
import csvIcon from '../../assets/img/icon-export.svg';
import editIcon from '../../assets/img/icon-pencil.svg';
import restoreIcon from '../../assets/img/icon-restore.svg';

import useSchoolYears from '../../behaviors/use-school-years/use-school-years';
import ClassCardListEmpty from '../../components/ClassCardList/ClassCardListEmpty/ClassCardListEmpty';
import { getClassDetailsCSV } from '../../utils/get-class-details-csv';
import createRestoreClassDialog from '../ClassDetails/ClassDetailsPageHeader/create-restore-class-dialog';

import Filters, { TeacherClassesFiltersArchivedKey } from './Filters';

import './TeacherClasses.scss';

const backgroundImageUrls = [
  backgroundImageUrl0,
  backgroundImageUrl1,
  backgroundImageUrl2,
  backgroundImageUrl3,
  backgroundImageUrl4,
];

export interface useFetchClassesData {
  classesData: ClassSection[];
  schoolYearsData: SchoolCalendar[];
}

export interface TeacherClassesProps {
  changeClass: (classSection: ClassSection) => void;
  changeSchoolYear: (year: SchoolCalendar) => void;
  createUpdateClass: (sectionId?: string) => void;
  loadClasses: () => Promise<useFetchClassesData>;
  loadEntitlements: (classId: string) => Promise<{ students: Student[] }>;
  navigateToAddStudents: (classSection: ClassSection) => void;
  onOrgSelected: PageHeaderProps['onOrgSelected'];
  setActive: (
    isActive: boolean,
    classSection: Omit<ClassSection, 'students' | 'staff'>,
  ) => Promise<void>;
}

const MAX_CARDS_IN_ROW = 5;

export const teacherClassesFeatureSchema: FeaturesSchema<UserType> = {
  canAddClass: ['manual'],
  canEditClass: ['manual'],
  canSeeArchivedClasses: ['manual'],
};

export interface TeacherClassesFeatures
  extends FeaturesMap<UserType, typeof teacherClassesFeatureSchema> {
  canAddClass: boolean;
  canEditClass: boolean;
  canSeeArchivedClasses: boolean;
}

export const useFetchClasses = ({ loadClasses }: Pick<TeacherClassesProps, 'loadClasses'>) => {
  const [classes, setClasses] = useState<ClassSection[] | null>(null);
  const [schoolYears, setSchoolYears] = useState<SchoolCalendar[]>([]);
  const [counter, setCounter] = useState(0);

  const [calendarId] = useStoreItem('dpCalendarId');
  const [orgId] = useStoreItem('dpOrgId');

  const refetch = () => setCounter(prev => prev + 1);

  useEffect(() => {
    (async () => {
      // load the classes
      const data = await loadClasses();

      if (data) {
        const { classesData, schoolYearsData } = data;

        setClasses(classesData);
        setSchoolYears(schoolYearsData);
      }
    })();
  }, [loadClasses, calendarId, orgId, counter]);

  return { classes, schoolYears, refetch };
};

const TeacherClasses = ({
  changeClass,
  changeSchoolYear,
  createUpdateClass,
  loadClasses,
  loadEntitlements,
  navigateToAddStudents,
  onOrgSelected,
  setActive,
}: TeacherClassesProps) => {
  const baseClass = 'sdm-teacher-classes';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });
  const { classes, schoolYears, refetch } = useFetchClasses({ loadClasses });
  const { selectedSchoolYear, isCurrentSchoolYear, isPastSchoolYear } = useSchoolYears(schoolYears);
  const [filtersMessage, setFiltersMessage] = useState<ReactNode>();

  const [showArchivedClasses, setShowArchivedClasses] = useObjectStorage<{
    value: boolean;
  }>(TeacherClassesFiltersArchivedKey);

  const filterClass = useCallback(
    // eslint-disable-next-line no-confusing-arrow
    (item: ClassSection): boolean =>
      showArchivedClasses ? item.active !== showArchivedClasses.value : !!item.active,
    [showArchivedClasses],
  );

  const [filteredClasses, setFilteredClasses] = useState<ClassSection[] | null>(null);
  useEffect(() => setFilteredClasses(classes === null ? null : classes.filter(filterClass)), [
    classes,
    filterClass,
  ]);

  const { canAddClass, canEditClass } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
  >() as TeacherClassesFeatures;

  const TeacherClassesInner = () => {
    if (filteredClasses === null) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <PageLoader size="large">Loading</PageLoader>
        </div>
      );
    }

    if (filteredClasses.length <= 0) {
      return (
        <ClassCardListEmpty canAddClass={!isPastSchoolYear && canAddClass}>
          {!isPastSchoolYear && canAddClass && (
            <Button color="primary" onClick={() => createUpdateClass()}>
              ADD NEW CLASS
            </Button>
          )}
        </ClassCardListEmpty>
      );
    }

    return (
      <>
        <div className="sdm-teacher-classes__container-outer">
          <div className="sdm-teacher-classes__container-inner">
            {filteredClasses.map((item: ClassSection) => {
              const {
                id,
                displayName,
                active,
                students,
                lowGrade,
                highGrade,
                staff: {
                  teachers,
                  primaryTeacher: { firstName, lastName },
                },
              } = item;

              const exportCSVMenuItem = {
                icon: <img src={csvIcon} alt="Export CSV Icon" />,
                label: 'Export class (.csv)',
                onClick: () => {
                  (async () => {
                    const { students: entitlementStudents } = await loadEntitlements(id);

                    getClassDetailsCSV({
                      fileName: displayName,
                      students: entitlementStudents?.map(
                        ({
                          id: entitlementStudentId,
                          firstName: entitlementFirstName,
                          lastName: entitlementLastName,
                          grade,
                          credentials: { username, password },
                          studentId,
                        }) => ({
                          id: entitlementStudentId,
                          firstName: entitlementFirstName || '',
                          lastName: entitlementLastName || '',
                          studentId,
                          grade,
                          username,
                          password,
                        }),
                      ),
                    });
                  })();
                },
              };

              const editClassInfoMenuItem = {
                icon: <img src={editIcon} alt="Edit Class Icon" />,
                label: 'Edit class info',
                onClick: () => createUpdateClass(id),
              };

              const addStudentsMenuItem = {
                icon: <img src={addIcon} alt="Add Students Icon" />,
                label: 'Add students',
                onClick: () => navigateToAddStudents(item)
              };

              const restoreClassMenuItem = {
                icon: <img src={restoreIcon} alt="Restore Class Icon" />,
                label: 'Restore class',
                onClick: createRestoreClassDialog({
                  classDisplayName: displayName,
                  setActive: async () => {
                    const { staff, ...classSectionOther } = item;

                    await setActive(!active, classSectionOther);

                    refetch();
                  },
                }),
              };

              // classes in past school years should be read-only and only allow exporting
              const menuItems = [exportCSVMenuItem];

              // classes in the present or future school years should be editable and
              // archivable/restorable
              if (!isPastSchoolYear) {
                if (active) {
                  menuItems.unshift(editClassInfoMenuItem);
                  menuItems.push(addStudentsMenuItem);
                } else {
                  menuItems.unshift(restoreClassMenuItem);
                }
              }

              return (
                <ClassCard
                  key={id}
                  className="sdm-teacher-classes__class-card"
                  size={isMedium ? ClassCardSize.Large : ClassCardSize.Small}
                  colorKey={id}
                  title={displayName}
                  students={students?.map(({ student }) => student) || []}
                  isArchived={!active}
                  primaryTeacher={`${firstName} ${lastName}`}
                  teachersTotal={teachers.length}
                  grades={getGrades(lowGrade, highGrade)}
                  backgroundImageUrls={backgroundImageUrls}
                  backgroundImageArchivedUrl={backgroundImageArchivedUrl}
                  onStudentsClick={() => {
                    setStoreItem('dpClassId', id);
                    changeClass(item);
                  }}
                  menuItems={canEditClass ? menuItems : undefined}
                />
              );
            })}

            {!isPastSchoolYear && canAddClass && (
              <ActionCard
                className="sdm-teacher-classes__class-card"
                heading="ADD NEW CLASS"
                layout={isMedium ? ActionCardLayout.Large : ActionCardLayout.Small}
                type={ActionCardType.Program}
                onClick={() => createUpdateClass()}
              />
            )}

            {[...Array(MAX_CARDS_IN_ROW)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="sdm-teacher-classes__class-card-placeholder"
                aria-hidden
              />
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <PageHeader
        filterByRole
        className="sdm-teacher-classes__page-header"
        onOrgSelected={org => {
          setFilteredClasses(null);
          onOrgSelected?.(org);
        }}
      >
        {!isPastSchoolYear && canAddClass && (
          <FabMenu placement="bottom">
            <FabItem onClick={() => createUpdateClass()}>Add new class</FabItem>
          </FabMenu>
        )}
      </PageHeader>

      <div className={classNames(responsiveClassName)}>
        <Container className="sdm-teacher-classes__container">
          <SectionHeader
            className="sdm-teacher-classes__section-header"
            tabs={[{ label: 'Classes', value: '', badgeProps: { children: classes?.length || 0 } }]}
          />

          <div className="sdm-teacher-classes__options">
            <Filters
              baseClass="sdm-teacher-classes"
              changeSchoolYear={changeSchoolYear}
              schoolYear={selectedSchoolYear}
              schoolYears={schoolYears}
              setFiltersMessage={setFiltersMessage}
              setShowArchivedClasses={setShowArchivedClasses}
              showArchivedClasses={showArchivedClasses}
            />
          </div>

          <TeacherClassesInner />

          {!!filtersMessage && selectedSchoolYear && !isCurrentSchoolYear && (
            <BannerMessage
              className="sdm-teacher-classes__filters-message"
              theme={BannerMessageTheme.Info}
              onClose={() => setFiltersMessage(null)}
            >
              {filtersMessage}
            </BannerMessage>
          )}
        </Container>
      </div>
    </>
  );
};

const TeacherClassesWrapper = (props: TeacherClassesProps) => (
  <ResponsivityProvider>
    <UserTypeFeatureFlagsProvider featuresSchema={teacherClassesFeatureSchema}>
      <TeacherClasses {...props} />
    </UserTypeFeatureFlagsProvider>
    <Notifier />
  </ResponsivityProvider>
);

export default TeacherClassesWrapper;
