import React from 'react';
import classNames from 'classnames';
import { CopyLink, notify, QrCode, useWindowQuery } from '@scholastic/volume-react';

import './LoginSection.scss';

export interface EasyLoginSwitchSectionProps {
  className?: string;
  classDisplayName?: string;
  title: string;
  titleIcon: string;
  shareType: string;
  url: string;
}

export const LoginSection = ({
  className,
  classDisplayName,
  titleIcon,
  title,
  shareType,
  url,
}: EasyLoginSwitchSectionProps) => {
  const baseClass = 'sdm-login-section';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  return (
    <div className={classNames(responsiveClassName, className)}>
      <h2 className={`${baseClass}__title`}>
        <img className={`${baseClass}__title-icon`} src={titleIcon} alt="Icon" />

        {title}
      </h2>

      <div className={`${baseClass}__description`}>
        <p className={`${baseClass}__description-text`}>
          We recommend bookmarking your Login Page URL on all student devices. If your devices are
          compatible, you can also encourage your students to scan the Login Page QR code.
        </p>

        <div className={`${baseClass}__wrapper`}>
          <CopyLink
            className={`${baseClass}__copy-link`}
            label="URL"
            url={url}
            onCopy={() => notify.success('Link copied to clipboard')}
          />

          <span className={`${baseClass}__separator`} />

          <QrCode
            className={`${baseClass}__qr-code`}
            value={url}
            printConfig={{
              title: 'Scholastic Digital Manager',
              description: (
                <>
                  {shareType} Easy Login QR Code for <strong>{classDisplayName}</strong>
                </>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginSection;
