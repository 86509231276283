import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import ReactToPrint from 'react-to-print';
import QRCode from 'qrcode.react';
import classNames from 'classnames';

import { LoginInfoPopoverClass } from '../LoginInfoPopover';
import './StudentBadges.scss';
import { Student } from '@scholastic/volume-react';

export interface StudentBadgesProps {
  selectedClass: LoginInfoPopoverClass;
  students: Student[];
  showEasyLogin: boolean;
  isSingleStudentMode?: boolean;
}

export const StudentBadges = ({
  students,
  showEasyLogin,
  isSingleStudentMode,
  selectedClass: {
    easyLoginUrl,
    schoolEasyLoginEnabled,
    easyLoginEnabled,
    nickname,
    avatar,
    teacherDisplayName,
    finalHostUrl,
  },
}: StudentBadgesProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <button className="sdm-student-badges__print-button" type="button">
            Print
          </button>
        )}
        content={() => ref.current}
      />

      {createPortal(
        <div className="sdm-student-badges">
          <div ref={ref}>
            <div className="sdm-student-badges__header">
              <h4>
                {easyLoginEnabled && showEasyLogin && <span>Easy Login</span>}{' '}
                {isSingleStudentMode ? 'Badge' : 'Badges'} for {nickname}
              </h4>

              {easyLoginEnabled && showEasyLogin && (
                <div className="sdm-student-badges__text">
                  For detailed instructions on how to use Easy Login, go to the Class Management
                  page in Scholastic Digital Manager and select Easy Login Settings.
                </div>
              )}
            </div>

            <div className="sdm-student-badges__content">
              {students.map((student, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className="sdm-student-badges__student"
                  style={index % 2 === 0 ? { borderRight: '2px dashed #000000' } : {}}
                >
                  <div className="sdm-student-badges__name">
                    {student.firstName} {student.lastName}
                  </div>

                  {easyLoginEnabled && showEasyLogin && (
                    <div>
                      <div className="sdm-student-badges__easy-login-section">
                        <div className="sdm-student-badges__easy-login-column">
                          <div className="sdm-student-badges__easy-login-text">
                            Type your Easy Login URL into a browser or scan the QR code. Then select
                            your {schoolEasyLoginEnabled && <span>class, </span>}
                            user icon and picture password to login.
                          </div>

                          <div className="sdm-student-badges__easy-login-url">{easyLoginUrl}</div>
                        </div>

                        <div className="sdm-student-badges__easy-login-column">
                          {easyLoginUrl && <QRCode value={easyLoginUrl} level="Q" size={100} />}
                        </div>
                      </div>

                      <div className="sdm-student-badges__easy-login-section sdm-student-badges__easy-login-section--content-center">
                        {schoolEasyLoginEnabled && (
                          <div className="sdm-student-badges__easy-login-icon">
                            <img
                              className="sdm-student-badges__easy-login-svg"
                              src={`resources/images/easyLogin/${avatar.name ||
                                'Class-Icon-Aqua'}.svg`}
                              alt="Easy Login Logo"
                            />

                            <span className="sdm-student-badges__easy-login-icon-label">
                              {`${teacherDisplayName}'s`} {nickname}
                            </span>
                          </div>
                        )}

                        <div className="sdm-student-badges__easy-login-icon">
                          <button
                            type="button"
                            className="sdm-student-badges__easy-login-button"
                            ref={element => {
                              if (!element) return;

                              // manually set the background color as `!important`, because:
                              //
                              // a) it's needed in order for the style not to be ignored in print
                              // b) react doesn't support inline `!important` styles
                              element.style.setProperty(
                                'background-color',
                                student.avatar?.color || '',
                                'important',
                              );
                            }}
                          >
                            <i
                              className={classNames(
                                'fa',
                                'fa-2x',
                                student.avatar?.class,
                                'fa-inverse',
                              )}
                            />
                          </button>

                          <span className="sdm-student-badges__easy-login-icon-label">
                            USER ICON
                          </span>
                        </div>

                        <div className="sdm-student-badges__easy-login-icon">
                          <button
                            type="button"
                            className="sdm-student-badges__easy-login-button"
                            ref={element => {
                              if (!element) return;

                              // manually set the background color as `!important`, because:
                              //
                              // a) it's needed in order for the style not to be ignored in print
                              // b) react doesn't support inline `!important` styles
                              element.style.setProperty(
                                'background-color',
                                student.secret?.color || '',
                                'important',
                              );
                            }}
                          >
                            <i
                              className={classNames(
                                'fa',
                                'fa-2x',
                                student.secret?.class,
                                'fa-inverse',
                              )}
                            />
                          </button>

                          <span className="sdm-student-badges__easy-login-icon-label">
                            PICTURE PASSWORD
                          </span>
                        </div>
                      </div>
                    </div>
                  )}

                  {!(easyLoginEnabled && showEasyLogin) && (
                    <div>
                      <div className="sdm-student-badges__easy-login-text-snippet">
                        To access Scholastic resources go to:
                      </div>

                      <div className="sdm-student-badges__final-host-url">{finalHostUrl}</div>

                      <div className="sdm-student-badges__easy-login-text-snippet">
                        Then enter your username and password:
                      </div>

                      <div>
                        <div className="sdm-student-badges__easy-login-credentials">
                          <div className="sdm-student-badges__easy-login-credentials-label">
                            Username
                          </div>

                          <div className="sdm-student-badges__easy-login-credentials-text">
                            {student.credentials.username}
                          </div>
                        </div>

                        <div className="sdm-student-badges__easy-login-credentials">
                          <div className="sdm-student-badges__easy-login-credentials-label">
                            Password
                          </div>

                          <div className="sdm-student-badges__easy-login-credentials-text">
                            {student.credentials.password}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};

export default StudentBadges;
