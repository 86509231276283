import React, { useCallback, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { notify, useStoreObject, useWindowQuery } from '@scholastic/volume-react';

import './ClassDetailsEasyLogin.scss';
import iconEasyLogin from '../../../assets/img/icon-easy-login.svg';
import iconClass from '../../../assets/img/icon-class.svg';
import iconSchool from '../../../assets/img/icon-school.svg';
import { ClassDetailsProps, ClassDetailsSelectedClass } from '../ClassDetails';
import CustomBulletList from './CustomBulletList/CustomBulletList';
import PrivacyAndSecurity from './PrivacyAndSecurity/PrivacyAndSecurity';
import EasyLoginSwitchSection from './EasyLoginSwitchSection/EasyLoginSwitchSection';
import ShareDevicesSection from './ShareDevicesSection/ShareDevicesSection';
import LoginSection from './LoginSection/LoginSection';
import FaqSection from './FaqSection/FaqSection';
import defaultErrorMessage from '../../../utils/messages';
import { LoginInfoPopoverProps } from '../../../components/LoginInfoPopover/LoginInfoPopover';

export interface ClassDetailsEasyLoginProps
  extends Pick<ClassDetailsProps, 'getSchoolEasyLoginCred' | 'toggleEasyLogin'> {
  selectedClass: ClassDetailsSelectedClass
  students: LoginInfoPopoverProps['students'];
}

const useGenerateEasyLoginUrl = ({
  selectedClass,
  getSchoolEasyLoginCred
}: Omit<ClassDetailsEasyLoginProps, 'toggleEasyLogin' | 'students'>) => {
  const [url, setUrl] = useState<string>(selectedClass.easyLogin.token);
  const { easyLogin, id, organizationId } = selectedClass;

  const generateEasyLoginUrl = useCallback(async () => {
    if (easyLogin && easyLogin.enabled) {
      const { host, hostname } = window.location;
      let finalHostUrl = host;

      if (hostname === 'localhost' || hostname === 'l.scholastic-labs.io') {
        finalHostUrl = `${host}/dp-portal`;
      }

      const fallbackUrl = `${finalHostUrl}/#/cz/${id}/${easyLogin.token}`;

      if (easyLogin.showInEasyLoginSchool) {
        try {
          const { orgId, token } = await getSchoolEasyLoginCred(organizationId);
          setUrl(`${finalHostUrl}/#/sz/${orgId}/${token}`);
        } catch (e) {
          setUrl(fallbackUrl);
        }
      } else {
        setUrl(fallbackUrl);
      }
    }
  }, [getSchoolEasyLoginCred, easyLogin, id, organizationId]);

  useEffect(() => {
    (async () => generateEasyLoginUrl())();
  }, [generateEasyLoginUrl]);

  return url;
};

export const ClassDetailsEasyLogin = ({
  selectedClass,
  toggleEasyLogin,
  getSchoolEasyLoginCred,
  students
}: ClassDetailsEasyLoginProps) => {
  const baseClass = 'sdm-class-details-easy-login';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const [easyLoginTipsDictionary, setEasyLoginTipsDictionary] = useStoreObject<
    Record<string, boolean>
  >('sdmEasyLoginTipsDictionary');

  useEffect(() => {
    if (!selectedClass || !selectedClass.id) return;

    setEasyLoginTipsDictionary({ ...easyLoginTipsDictionary, [selectedClass.id]: true });
  }, [selectedClass, easyLoginTipsDictionary, setEasyLoginTipsDictionary]);

  const isEasyLoginEnabled = selectedClass.easyLogin.enabled;
  const [isToggled, setIsToggled] = useState(isEasyLoginEnabled);

  useEffect(() => setIsToggled(isEasyLoginEnabled), [isEasyLoginEnabled]);

  const { showInEasyLoginSchool } = selectedClass.easyLogin;
  const [areDevicesShared, setAreDevicesShared] = useState(showInEasyLoginSchool);

  useEffect(() => setAreDevicesShared(showInEasyLoginSchool), [showInEasyLoginSchool]);

  const listTitle = <h1 className={`${baseClass}__list-title`}>What is Easy Login?</h1>;

  const logo = (
    <img className={`${baseClass}__easy-login-logo`} src={iconEasyLogin} alt="Easy Login" />
  );

  const easyLoginUrl = useGenerateEasyLoginUrl({ selectedClass, getSchoolEasyLoginCred });

  return (
    <div className={responsiveClassName}>
      <div className={`${baseClass}__info`}>
        {!isMedium && (
          <div className={`${baseClass}__title-with-logo-wrapper`}>
            {listTitle}

            {logo}
          </div>
        )}

        {isMedium ? (
          <>
            {logo}

            <div className={`${baseClass}__list-wrapper`}>
              {listTitle}

              <CustomBulletList className={`${baseClass}__custom-bullet-list`} />
            </div>
          </>
        ) : (
          <CustomBulletList className={`${baseClass}__custom-bullet-list`} />
        )}

        <PrivacyAndSecurity className={`${baseClass}__privacy-and-security`} students={students} />
      </div>

      <div className={`${baseClass}__box`}>
        <EasyLoginSwitchSection
          className={`${baseClass}__easy-login-switch`}
          classDisplayName={selectedClass.displayName}
          isToggled={isToggled}
          toggle={async toggleState => {
            setIsToggled(toggleState);

            try {
              await toggleEasyLogin({ isEasyLoginEnabled: toggleState });
            } catch (e) {
              notify.warning(defaultErrorMessage);
            }
          }}
        />

        <AnimatePresence>
          {isToggled && (
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <ShareDevicesSection
                areDevicesShared={areDevicesShared}
                toggleAreDevicesShared={async toggleState => {
                  setAreDevicesShared(toggleState);

                  try {
                    await toggleEasyLogin({ showInEasyLoginSchool: toggleState });
                  } catch (e) {
                    notify.warning(defaultErrorMessage);
                  }
                }}
              />

              <LoginSection
                classDisplayName={selectedClass.displayName}
                titleIcon={areDevicesShared ? iconSchool : iconClass}
                title={areDevicesShared ? 'School Login Page' : 'Class Login Page'}
                shareType={areDevicesShared ? 'School' : 'Class'}
                url={easyLoginUrl}
              />

              <FaqSection />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ClassDetailsEasyLogin;
