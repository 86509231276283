import { useMemo } from 'react';

export interface EasyLoginAvatar {
  id: string;
  class: string;
  description: string;
  color: string;
}

export const useGetEasyLoginAvatars = (): EasyLoginAvatar[] =>
  useMemo(
    () =>
      [
        { id: '1', class: 'fa-snowflake-o', description: 'snowflake', color: '#75d104' },
        { id: '2', class: 'fa-space-shuttle', description: 'space shuttle', color: '#f74b61' },
        { id: '3', class: 'fa-clock-o', description: 'clock', color: '#a678fc' },
        { id: '4', class: 'fa-play', description: 'triangle', color: '#75d104' },
        { id: '5', class: 'fa-paperclip', description: 'paperclip', color: '#f74b61' },
        { id: '6', class: 'fa-smile-o', description: 'smile', color: '#fcc100' },
        { id: '7', class: 'fa-pagelines', description: 'branch', color: '#fcc100' },
        { id: '8', class: 'fa-suitcase', description: 'suitcase', color: '#a678fc' },
        { id: '9', class: 'fa-umbrella', description: 'umbrella', color: '#f74b61' },
        { id: '10', class: 'fa-envelope', description: 'envelope ', color: '#01c0da' },
        { id: '11', class: 'fa-heart', description: 'heart', color: '#75d104' },
        { id: '12', class: 'fa-motorcycle', description: 'motorcycle', color: '#fcc100' },
        { id: '13', class: 'fa-paint-brush', description: 'paint brush', color: '#fcc100' },
        { id: '14', class: 'fa-laptop', description: 'laptop', color: '#a678fc' },
        { id: '15', class: 'fa-gamepad', description: 'gamepad ', color: '#a678fc' },
        { id: '16', class: 'fa-flag', description: 'flag', color: '#01c0da' },
        { id: '17', class: 'fa-binoculars', description: 'binoculars', color: '#fcc100' },
        { id: '18', class: 'fa-square', description: 'square', color: '#01c0da' },
        { id: '19', class: 'fa-book', description: 'book', color: '#a678fc' },
        { id: '20', class: 'fa-pencil', description: 'pencil', color: '#f74b61' },
        { id: '21', class: 'fa-cloud', description: 'cloud', color: '#01c0da' },
        { id: '22', class: 'fa-cut', description: 'scissors', color: '#75d104' },
        { id: '23', class: 'fa-cube', description: 'cube', color: '#01c0da' },
        { id: '24', class: 'fa-star', description: 'star', color: '#f74b61' },
        { id: '25', class: 'fa-circle', description: 'circle', color: '#75d104' },
      ].sort(({ description: a }, { description: b }) => a.localeCompare(b)),
    [],
  );

export default useGetEasyLoginAvatars;
