import React, { FunctionComponent, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import {
  Application,
  Modal,
  ModalBody,
  ModalHeader,
  ModalProps,
  ModalSizes,
  Notifier,
  Product,
  notify,
  setStoreItem,
} from '@scholastic/volume-react';

import { teacherProgramsRefreshTrigger } from '../../behaviors/use-api/use-teacher-programs-api/use-teacher-programs-api';

import { TeacherProgramsProps } from '../../views/TeacherPrograms/TeacherPrograms';

import { defaultErrorMessage } from '../../utils/messages';
import getGenericErrorPrefix from '../../utils/get-generic-error-prefix';
import addPeriodToString from '../../utils/add-period-to-string';

import StepEnterCode, {
  StepEnterCodeFormData,
  StepEnterCodeMetaData,
} from './StepEnterCode/StepEnterCode';

import StepConfirmSubscription, {
  StepConfirmSubscriptionFormData,
  StepConfirmSubscriptionMetaData,
} from './StepConfirmSubscription/StepConfirmSubscription';

import StepAgreement, { StepAgreementFormData } from './StepAgreement/StepAgreement';
import StepFinal from './StepFinal/StepFinal';

import './RedeemAccessModal.scss';

enum RedeemAccessStep {
  EnterCode,
  ConfirmSubscription,
  Agreement,
  Final,
}

export interface School {
  id: string;
  name: string;
  identifiers: { ucn: string };
  address: { zipCode: string };
}

// This needs to be turned into a shape in volume-react
export interface RedeemedSubscription {
  schools: School[];
  application: Application;
  subscriptionProduct: Product;
  promptToAcceptLicense: boolean;
  universalAccessCode: boolean;
}

export interface Settings {
  homeSchoolUCN: string;
}

export interface RedeemAccessModalProps
  extends Pick<
      TeacherProgramsProps,
      | 'redeemAccess'
      | 'getSettings'
      | 'searchOrgsByZip'
      | 'confirmSubscription'
    >,
    ModalProps {
  showSuccessMessage: (message: ReactNode) => void;
}

export type RedeemAccessModalFormState = { dpOrgId: string } & StepEnterCodeFormData &
  StepConfirmSubscriptionFormData &
  StepAgreementFormData;

export type RedeemAccessModalMetaData = StepEnterCodeMetaData & StepConfirmSubscriptionMetaData;

const getDefaultFormState = (): RedeemAccessModalFormState => ({
  dpOrgId: '',
  accessCode: '',
  schoolZipCode: '',
  school: { name: '', ucn: '' },
  subscriptionIsConfirmed: false,
  agreementIsConfirmed: false,
});

const getDefaultMetaData = (): RedeemAccessModalMetaData => ({
  schools: [],
  homeSchoolIsSelected: false,
  schoolFromZipSearchIsSelected: false,
  defaultSchoolFromProfileIsSelected: false,
  promptToAcceptLicense: true,
});

export const RedeemAccessModal: FunctionComponent<RedeemAccessModalProps> = ({
  isOpen,
  size,
  toggle,
  redeemAccess,
  searchOrgsByZip,
  showSuccessMessage,
  getSettings,
  confirmSubscription,
}: RedeemAccessModalProps) => {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const [modalSize, setModalSize] = useState<ModalSizes>(size || 'extra-small');

  const isExtraSmallDown = useMediaQuery({ maxWidth: 719 });
  const isSmall = useMediaQuery({ minWidth: 720, maxWidth: 959 });
  const isMedium = useMediaQuery({ minWidth: 960, maxWidth: 1279 });
  const isLargeUp = useMediaQuery({ minWidth: 1280 });

  const [settings, setSettings] = useState<Settings>();
  const [redeemAccessCodeResponse, setRedeemAccessCodeResponse] = useState<RedeemedSubscription>();
  const [formState, setFormState] = useState<RedeemAccessModalFormState>(getDefaultFormState());
  const [metaData, setMetaData] = useState<RedeemAccessModalMetaData>(getDefaultMetaData());
  const [currentStep, setCurrentStep] = useState<RedeemAccessStep>(RedeemAccessStep.EnterCode);

  useEffect(() => {
    (async () => setSettings(await getSettings()))();
  }, []);

  // reset the modal's local state on close
  useEffect(() => {
    if (modalOpen) return;

    setRedeemAccessCodeResponse(undefined);
    setFormState(getDefaultFormState());
    setMetaData(getDefaultMetaData());
    setCurrentStep(RedeemAccessStep.EnterCode);
  }, [modalOpen]);

  const productLogo = useMemo(
    () => (
      <img
        className="sdm-redeem-access-modal__logo"
        alt={redeemAccessCodeResponse?.application.name || 'Application'}
        src={redeemAccessCodeResponse?.application.thumbnail}
      />
    ),
    [redeemAccessCodeResponse?.application.name, redeemAccessCodeResponse?.application.thumbnail],
  );

  useEffect(() => setModalOpen(isOpen), [isOpen]);
  useEffect(() => {
    if (isSmall) {
      setModalSize('small');
      return;
    }

    if (isMedium) {
      setModalSize('medium');
      return;
    }

    if (isLargeUp) {
      setModalSize('large');
      return;
    }

    setModalSize('extra-small');
  }, [isExtraSmallDown, isSmall, isMedium, isLargeUp]);

  const showWarning = (error: Error) =>
    notify.warning(
      addPeriodToString(
        (error.message && `${getGenericErrorPrefix()} ${error.message}`) || defaultErrorMessage,
      ),
    );

  return (
    <Modal
      className={classNames('sdm-redeem-access-modal', {
        'sdm-redeem-access-modal--extra-small-down': isExtraSmallDown,
        'sdm-redeem-access-modal--small': isSmall,
        'sdm-redeem-access-modal--medium': isMedium,
        'sdm-redeem-access-modal--large-up': isLargeUp,
      })}
      isOpen={modalOpen}
      size={modalSize}
      toggle={toggle}
    >
      <ModalHeader className="sdm-redeem-access-modal__header" />

      <ModalBody className="sdm-redeem-access-modal__body">
        {currentStep === RedeemAccessStep.EnterCode && (
          <StepEnterCode
            goToNext={async ({ formData }) => {
              try {
                const response = await redeemAccess(formData.accessCode);

                setRedeemAccessCodeResponse(response);

                if (response.universalAccessCode) {
                  setCurrentStep(RedeemAccessStep.ConfirmSubscription);

                  setFormState(prevState => ({ ...prevState, ...formData }));
                  setMetaData(prevState => ({
                    ...prevState,
                    promptToAcceptLicense: response.promptToAcceptLicense,
                  }));
                } else {
                  // if there's no UAC, redeem access right away,
                  // switch to the correct org and close the modal
                  setStoreItem('dpOrgId', response.schools[0]?.id);

                  teacherProgramsRefreshTrigger();

                  setModalOpen(false);
                  showSuccessMessage(
                    <>
                      You have successfully redeemed your access code for{' '}
                      <strong>{response.application.name}</strong>. You can launch the product from
                      your dashboard.
                    </>,
                  );
                }
              } catch (error) {
                showWarning(error);
              }
            }}
          />
        )}

        {currentStep === RedeemAccessStep.ConfirmSubscription && redeemAccessCodeResponse && (
          <StepConfirmSubscription
            goToNext={async ({ formData, metaData: metaDataFromConfirmSubscriptionStep }) => {
              try {
                const response = await redeemAccess(formState.accessCode, formData.school.ucn);
                const dpOrgId = response.schools[0].id;

                setFormState(prevState => ({ ...prevState, ...formData, dpOrgId }));
                setMetaData(prevState => ({
                  ...prevState,
                  ...metaDataFromConfirmSubscriptionStep,
                }));

                setCurrentStep(RedeemAccessStep.Agreement);
              } catch (error) {
                showWarning(error);
              }
            }}
            subscriptionProductName={redeemAccessCodeResponse.application.name}
            searchOrgsByZip={searchOrgsByZip}
            homeSchoolUcn={settings?.homeSchoolUCN}
            productLogo={productLogo}
          />
        )}

        {currentStep === RedeemAccessStep.Agreement && redeemAccessCodeResponse && (
          <StepAgreement
            goToNext={({ formData }) => {
              setFormState(prevState => ({ ...prevState, ...formData }));

              // switch to the chosen school
              setStoreItem('dpOrgId', formState.dpOrgId);

              setCurrentStep(RedeemAccessStep.Final);
            }}
            goToPrevious={() => {
              setCurrentStep(RedeemAccessStep.ConfirmSubscription);
            }}
            promptToAcceptLicence={redeemAccessCodeResponse.promptToAcceptLicense}
            subscriptionProductName={redeemAccessCodeResponse.subscriptionProduct.name}
            schoolName={formState.school.name}
            productLogo={productLogo}
          />
        )}

        {currentStep === RedeemAccessStep.Final && redeemAccessCodeResponse && (
          <StepFinal
            goToNext={async () => {
              try {
                await confirmSubscription(formState, metaData);

                teacherProgramsRefreshTrigger();

                // close the modal
                setModalOpen(false);
                showSuccessMessage(
                  <>
                    You have successfully redeemed your access code for{' '}
                    <strong>{redeemAccessCodeResponse?.application.name}</strong>. You can launch
                    the product from your dashboard.
                  </>,
                );
              } catch (error) {
                showWarning(error);
              }
            }}
            productLogo={productLogo}
            subscriptionProductName={redeemAccessCodeResponse.subscriptionProduct.name}
            onSavePassCode={() => showSuccessMessage('Successfully updated class passcode')}
          />
        )}
      </ModalBody>
      <Notifier />
    </Modal>
  );
};

export default RedeemAccessModal;
