import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  BannerMessage,
  BannerMessageTheme,
  Container,
  FeaturesMap,
  FeaturesSchema,
  GradeKey,
  Metric,
  MetricProps,
  MetricTextSize,
  Notifier,
  ResponsivityProvider,
  SchoolCalendar,
  SectionHeader,
  Student,
  UserType,
  UserTypeFeatureFlagsProvider,
  useClasses,
  useFeatures,
  useObjectStorage,
  useStoreItem,
  useWindowQuery,
} from '@scholastic/volume-react';

import './ClassDetails.scss';
import useGetSelectedClassGrade from './use-get-selected-class-grade';
import useGetSelectedSchoolYear from './use-get-selected-school-year';
import useGetTabs from './use-get-tabs';
import useGetStudentsWithAvatars from '../../behaviors/use-get-students-with-avatars/use-get-students-with-avatars';
import useGetSelectedClassWithEasyLoginInfo from './use-get-selected-class-with-easy-login-info';
import useGetShowEasyLogin from './use-get-show-easy-login';
import useGetSortedStudents from './use-get-sorted-students';
import { StudentFormInputStudent } from '../../components/StudentForm/StudentFormInput/StudentFormInput';
import ClassDetailsPageHeader from './ClassDetailsPageHeader/ClassDetailsPageHeader';
import { LoginInfoPopoverClass } from '../../components/LoginInfoPopover/LoginInfoPopover';
import ClassDetailsStudents from './ClassDetailsStudents/ClassDetailsStudents';
import ClassDetailsEasyLogin from './ClassDetailsEasyLogin/ClassDetailsEasyLogin';
import { StudentFormData } from '../../components/StudentForm/StudentForm';
import useGetTeacherFullNames from './use-get-teacher-full-names';
import useShowEasyLogin from './use-show-easy-login';
import useSchoolYears from '../../behaviors/use-school-years/use-school-years';
import ClassDetailsApps from './ClassDetailsApps/ClassDetailsApps';

export interface ClassDetailsClassListItem {
  id: string;
  lowGrade: string;
  highGrade: string;
}

export interface ClassDetailsStaff {
  primaryTeacher?: { id?: string; firstName?: string; lastName?: string };
  teachers?: { id?: string; firstName?: string; lastName?: string }[];
}

export interface ClassDetailsSelectedClass {
  id: string;
  nickname: string;
  displayName: string;
  active: boolean;
  staff?: ClassDetailsStaff;
  easyLogin: { enabled: boolean; showInEasyLoginSchool: boolean; token: string };
  lowGrade: GradeKey;
  highGrade: GradeKey;
  organizationId: string;
}

export interface ClassDetailsSelectedSchoolYear {
  startDate: string;
  endDate: string;
}

export interface ClassDetailsSelectedSchool {
  name: string;
}

export interface ClassDetailsSubscription {
  application: {
    active: boolean;
    id: string;
    thumbnail: string;
    url: string;
    name: string;
  };
  subscription: {
    id: string;
  };
}

export interface ClassDetailsProps {
  addExistingStudent: (student: StudentFormInputStudent, sectionId: string) => Promise<void>;
  classList?: ClassDetailsClassListItem[];
  createUpdateClass: (sectionId?: string) => void;
  checkEntitlement: (studentId: string, subscriptionId: string) => boolean;
  editStudentId?: string;
  getEasyLoginInfo: (selectedClass?: ClassDetailsSelectedClass) => Promise<LoginInfoPopoverClass>;
  getOptionValue: (key: string) => boolean;
  getSchoolEasyLoginCred: (orgId: string) => Promise<{ orgId: string; token: string }>;
  getShowEasyLogin: () => Promise<boolean>;
  getStudentsInClassWithAvatars: (selectedClass: { id: string }) => Promise<Student[]>;
  isAddEditMode?: boolean;
  isAppsPage?: boolean;
  isEasyLoginPage?: boolean;
  loadCalendars: (orgId: string) => SchoolCalendar[];
  navigateTo: (path: string) => void;
  navigateToEditStudents: (studentId?: string, toAddEdit?: boolean) => void;
  removeStudentFromClass: (studentId: string) => Promise<void>;
  school?: ClassDetailsSelectedSchool;
  searchForStudent: (searchParams: {
    firstName?: string;
    lastName?: string;
    externalStudentId?: string;
  }) => Promise<StudentFormInputStudent[]>;
  selectedClass?: ClassDetailsSelectedClass;
  setActive: (isActive: boolean) => Promise<void>;
  students?: Student[];
  subscriptions?: ClassDetailsSubscription[];
  toggleAllEntitlements: (subscriptionId: string, areToggled: boolean) => Promise<void>;
  toggleEasyLogin: (params: {
    isEasyLoginEnabled?: boolean;
    showInEasyLoginSchool?: boolean;
  }) => Promise<ClassDetailsSelectedClass>;
  toggleStudentEnrollment: (
    studentId: string,
    subscriptionId: string,
    isToggled: boolean,
  ) => Promise<void>;
  updateStudent: (
    student: Student | StudentFormData,
    sectionId: string,
    studentId?: string,
  ) => Promise<Student>;
}

export const classDetailsFeaturesSchema: FeaturesSchema<UserType> = {
  canGetLoginInfo: ['manual'],
  canManage: ['manual'],
  canAddStudents: ['manual'],
  canViewEasyLogin: ['manual'],
  canEditStudents: ['manual'],
  canViewStudentDetails: ['manual'],
};

export interface ClassDetailsFeatures
  extends FeaturesMap<UserType, typeof classDetailsFeaturesSchema> {
  canGetLoginInfo: boolean;
  canManage: boolean;
  canAddStudents: boolean;
  canViewEasyLogin: boolean;
  canEditStudents: boolean;
  canViewStudentDetails: boolean;
}

export const useFetchCalendars = ({ loadCalendars }: Pick<ClassDetailsProps, 'loadCalendars'>) => {
  const [schoolYears, setSchoolYears] = useState<SchoolCalendar[]>([]);
  const [orgId] = useStoreItem('dpOrgId');

  useEffect(() => {
    (async () => {
      if (!orgId) return;

      // load the classes
      const data = await loadCalendars(orgId);

      if (data) setSchoolYears(data);
    })();
  }, [loadCalendars, orgId]);

  return schoolYears;
};

export const ClassDetails = ({
  addExistingStudent,
  classList,
  createUpdateClass,
  checkEntitlement,
  editStudentId,
  getEasyLoginInfo,
  getOptionValue,
  getSchoolEasyLoginCred,
  getShowEasyLogin,
  getStudentsInClassWithAvatars,
  isAddEditMode,
  isAppsPage,
  isEasyLoginPage,
  loadCalendars,
  navigateTo,
  navigateToEditStudents,
  removeStudentFromClass,
  school,
  searchForStudent,
  selectedClass,
  setActive,
  students,
  subscriptions,
  toggleAllEntitlements,
  toggleEasyLogin,
  toggleStudentEnrollment,
  updateStudent,
}: ClassDetailsProps) => {
  const baseClass = 'sdm-class-details';

  const { responsiveClassName, isMedium } = useWindowQuery({ className: baseClass });

  const schoolYears = useFetchCalendars({ loadCalendars });
  const { selectedSchoolYear, isPastSchoolYear, isCurrentSchoolYear } = useSchoolYears(schoolYears);

  const classes = useClasses({ baseClass }, [
    responsiveClassName,
    { [`${baseClass}--is-add-edit-mode`]: isPastSchoolYear ? false : isAddEditMode },
  ]);

  const { canViewEasyLogin } = useFeatures<
    UserType,
    FeaturesSchema<UserType>
  >() as ClassDetailsFeatures;

  const sortedStudents = useGetSortedStudents(students);

  const selectedClassGrade = useGetSelectedClassGrade({ selectedClass, classList });
  const selectedClassSchoolYear = useGetSelectedSchoolYear({ selectedSchoolYear });
  const selectedClassCoTeacherFullNames = useGetTeacherFullNames({ selectedClass });

  const [showClassInfo, setShowClassInfo] = useObjectStorage(
    'sdmTeacherClassDetailsShowClassInfo',
    { value: false },
  );

  const { tabs, activeTab } = useGetTabs({
    canViewEasyLogin,
    isAppsPage,
    isClassInfoPage: !!showClassInfo?.value,
    isCurrentSchoolYear,
    isEasyLoginPage,
    isMedium,
    isPastSchoolYear,
    selectedClass,
    students: sortedStudents,
    subscriptions,
  });

  const showEasyLogin = useGetShowEasyLogin({ getShowEasyLogin });

  const selectedClassWithEasyLoginInfo = useGetSelectedClassWithEasyLoginInfo({
    selectedClass,
    getEasyLoginInfo,
  });

  const isActive = selectedClassWithEasyLoginInfo?.active;

  const studentsWithAvatars = useGetStudentsWithAvatars({
    selectedClass,
    getStudentsInClassWithAvatars,
  });

  useEffect(() => {
    if (isMedium && showClassInfo?.value) {
      setShowClassInfo({ value: false });
    }
  }, [isMedium, setShowClassInfo, showClassInfo]);

  const metrics = useMemo<MetricProps[]>(
    () => [
      { definition: 'Grade', datum: selectedClassGrade },
      { definition: 'School Year', datum: selectedClassSchoolYear || 'No School Year' },
      { definition: 'Teacher', datum: selectedClassCoTeacherFullNames },
    ],
    [selectedClassGrade, selectedClassSchoolYear, selectedClassCoTeacherFullNames],
  );

  const { bannerIsShown, hideBanner } = useShowEasyLogin({ selectedClass });

  const toggleStudentEnrollmentHandler = useCallback(
    (subscriptionId: string) => (studentId: string, isToggled: boolean) =>
      toggleStudentEnrollment(studentId, subscriptionId, isToggled),
    [toggleStudentEnrollment],
  );

  const Metrics = () => (
    <div className={`${baseClass}__metrics`}>
      {metrics.map(({ definition, datum }) => (
        <Metric
          className={`${baseClass}__metric`}
          key={definition}
          definition={definition}
          datum={datum}
          textSize={MetricTextSize.Small}
          reversed
        />
      ))}
    </div>
  );

  return (
    <>
      <ClassDetailsPageHeader
        navigateToEditStudents={navigateToEditStudents}
        showAddStudent={isPastSchoolYear ? false : !isAddEditMode}
        students={studentsWithAvatars}
        selectedClass={selectedClassWithEasyLoginInfo}
        showEasyLogin={showEasyLogin}
        schoolName={school?.name}
        schoolYears={schoolYears}
        classDisplayName={selectedClass?.displayName}
        navigateToEditClassPage={() => createUpdateClass(selectedClass?.id)}
        setActive={setActive}
      />

      <div className={classes} role="main">
        <Container className={`${baseClass}__container`}>
          {isMedium && <Metrics />}

          <SectionHeader
            className={`${baseClass}__header`}
            tabs={tabs}
            activeTab={activeTab}
            onSelect={tab => {
              const toClassInfo = tab.value === 'class-info';
              const pathSuffix = tab.value === 'students' || toClassInfo ? '' : tab.value;
              navigateTo(`/students/show-details/${pathSuffix}`);
              setShowClassInfo({ value: toClassInfo });
            }}
          />

          {!isEasyLoginPage && !isAppsPage && !showClassInfo?.value && (
            <>
              <ClassDetailsStudents
                addExistingStudent={addExistingStudent}
                className={`${baseClass}__students`}
                editStudentId={isPastSchoolYear ? undefined : editStudentId}
                getOptionValue={getOptionValue}
                isAddEditMode={isPastSchoolYear ? false : isAddEditMode}
                navigateTo={navigateTo}
                navigateToEditStudents={navigateToEditStudents}
                removeStudentFromClass={removeStudentFromClass}
                schoolYears={schoolYears}
                searchForStudent={searchForStudent}
                selectedClass={selectedClass}
                students={sortedStudents}
                updateStudent={updateStudent}
              />

              {!isPastSchoolYear && canViewEasyLogin && bannerIsShown && isActive && (
                <BannerMessage
                  theme={BannerMessageTheme.Info}
                  className={`${baseClass}__banner-message`}
                  onClose={hideBanner}
                  actions={[
                    {
                      label: 'Manage login method',
                      onClick: () => navigateTo('/students/show-details/easy-login'),
                    },
                  ]}
                >
                  <strong>Easy Login</strong> is available for your students.
                </BannerMessage>
              )}
            </>
          )}

          {isEasyLoginPage && selectedClass?.easyLogin && isActive && (
            <ClassDetailsEasyLogin
              selectedClass={selectedClass}
              students={studentsWithAvatars || []}
              getSchoolEasyLoginCred={getSchoolEasyLoginCred}
              toggleEasyLogin={toggleEasyLogin}
            />
          )}

          {isAppsPage && isCurrentSchoolYear && isActive && (
            <ClassDetailsApps
              subscriptions={subscriptions}
              students={sortedStudents}
              checkEntitlement={checkEntitlement}
              toggleAllEntitlements={toggleAllEntitlements}
              createToggleStudentEnrollment={toggleStudentEnrollmentHandler}
              selectedClass={selectedClass}
            />
          )}

          {showClassInfo?.value && !isMedium && <Metrics />}
        </Container>
      </div>
    </>
  );
};

export const ClassDetailsWrapper = (props: ClassDetailsProps) => (
  <ResponsivityProvider>
    <UserTypeFeatureFlagsProvider featuresSchema={classDetailsFeaturesSchema}>
      <ClassDetails {...props} />
      <Notifier pollForDeferredNotifications />
    </UserTypeFeatureFlagsProvider>
  </ResponsivityProvider>
);

export default ClassDetailsWrapper;
