import { useMemo } from 'react';

export interface EasyLoginSecret {
  id: string;
  class: string;
  description: string;
  color: string;
}

export const useGetEasyLoginSecrets = (): EasyLoginSecret[] =>
  useMemo(
    () =>
      [
        { id: '1', class: 'fa-camera', description: 'camera', color: '#75d104' },
        { id: '2', class: 'fa-tree', description: 'tree', color: '#a678fc' },
        { id: '3', class: 'fa-lightbulb-o', description: 'lightbulb', color: '#fcc100' },
        { id: '4', class: 'fa-truck', description: 'truck', color: '#01c0da' },
        { id: '5', class: 'fa-music', description: 'music', color: '#01c0da' },
        { id: '6', class: 'fa-trophy', description: 'trophy', color: '#f74b61' },
        { id: '7', class: 'fa-bug', description: 'bug', color: '#fcc100' },
        { id: '8', class: 'fa-subway', description: 'subway', color: '#f74b61' },
        { id: '9', class: 'fa-puzzle-piece', description: 'puzzle piece', color: '#01c0da' },
        { id: '10', class: 'fa-paw', description: 'paw', color: '#a678fc' },
        { id: '11', class: 'fa-rocket', description: 'rocket', color: '#a678fc' },
        { id: '12', class: 'fa-leaf', description: 'leaf', color: '#fcc100' },
        { id: '13', class: 'fa-moon-o', description: 'moon', color: '#f74b61' },
        { id: '14', class: 'fa-bicycle', description: 'bicycle', color: '#f74b61' },
        { id: '15', class: 'fa-ship', description: 'ship', color: '#75d104' },
        { id: '16', class: 'fa-car', description: 'car', color: '#01c0da' },
        { id: '17', class: 'fa-plane', description: 'plane', color: '#f74b61' },
        { id: '18', class: 'fa-paper-plane', description: 'paper plane', color: '#75d104' },
        { id: '19', class: 'fa-key', description: 'key', color: '#a678fc' },
        { id: '20', class: 'fa-anchor', description: 'anchor', color: '#75d104' },
        { id: '21', class: 'fa-futbol-o', description: 'soccerball', color: '#01c0da' },
        { id: '22', class: 'fa-bus', description: 'bus', color: '#a678fc' },
        { id: '23', class: 'fa-dribbble', description: 'basketball', color: '#75d104' },
        { id: '24', class: 'fa-sun-o', description: 'sun', color: '#fcc100' },
        { id: '25', class: 'fa-bell', description: 'bell', color: '#fcc100' },
      ].sort(({ description: a }, { description: b }) => a.localeCompare(b)),
    [],
  );

export default useGetEasyLoginSecrets;
