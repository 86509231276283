import React, { useMemo, useState } from 'react';
import { notify, Student, useWindowQuery } from '@scholastic/volume-react';

import './ClassDetailsApps.scss';
import AppCard from './AppCard/AppCard';
import { ClassDetailsProps, ClassDetailsSubscription } from '../ClassDetails';

export interface ClassDetailsAppsProps
  extends Pick<
    ClassDetailsProps,
    'subscriptions' | 'students' | 'checkEntitlement' | 'toggleAllEntitlements' | 'selectedClass'
  > {
  createToggleStudentEnrollment: (
    subscriptionId: string,
  ) => (studentId: string, isToggled: boolean) => Promise<void>;
}

export interface UseSubscriptionsWithStudents
  extends Pick<ClassDetailsProps, 'subscriptions' | 'students' | 'checkEntitlement'> {}

export interface StudentWithEntitlementData extends Student {
  isEntitled: boolean;
}

export interface SubscriptionWithStudents extends ClassDetailsSubscription {
  studentsWithEntitlementData: StudentWithEntitlementData[];
  entitledStudentsCount: number;
  areAllStudentsEntitled: boolean;
  areNoStudentsEntitled: boolean;
}

const useSubscriptionsWithStudents = ({
  subscriptions,
  students,
  checkEntitlement,
}: UseSubscriptionsWithStudents): SubscriptionWithStudents[] =>
  useMemo(
    () =>
      subscriptions?.map(subscription => {
        const studentsWithEntitlementData =
          students?.map(student => ({
            ...student,
            isEntitled: checkEntitlement(student.id, subscription.subscription.id),
          })) || [];

        const entitledStudentsCount =
          studentsWithEntitlementData.filter(({ isEntitled }) => isEntitled).length || 0;

        return {
          ...subscription,
          studentsWithEntitlementData,
          entitledStudentsCount,
          areAllStudentsEntitled:
            entitledStudentsCount >= 1 && entitledStudentsCount === students?.length,
          areNoStudentsEntitled: entitledStudentsCount === 0,
        };
      }) || [],
    [subscriptions, students, checkEntitlement],
  );

const ClassDetailsApps = ({
  checkEntitlement,
  students,
  subscriptions,
  toggleAllEntitlements,
  createToggleStudentEnrollment,
  selectedClass,
}: ClassDetailsAppsProps) => {
  const baseClass = 'sdm-class-details-apps';

  const { responsiveClassName } = useWindowQuery({ className: baseClass });

  const [expandedCardId, setExpandedCardId] = useState<string>();

  const subscriptionsWithStudents = useSubscriptionsWithStudents({
    checkEntitlement,
    students,
    subscriptions,
  });

  return (
    <div className={responsiveClassName}>
      {subscriptionsWithStudents.map(
        ({
          application: { id, thumbnail, name },
          studentsWithEntitlementData,
          areAllStudentsEntitled,
          areNoStudentsEntitled,
          entitledStudentsCount,
          subscription: { id: subscriptionId },
        }) => (
          <AppCard
            areAllStudentsEntitled={areAllStudentsEntitled}
            areNoStudentsEntitled={areNoStudentsEntitled}
            className={`${baseClass}__app-card`}
            isArchived={!selectedClass?.active}
            disableAllEntitlements={async () => {
              try {
                await toggleAllEntitlements(subscriptionId, false);

                notify.success(
                  <>
                    Your students have been removed from <strong>{name}</strong>. If you change your
                    mind, you can add them again at any time.
                  </>,
                );
              } catch (e) {
                notify.warning('An error occurred. Please try again.');
              }
            }}
            enableAllEntitlements={async () => {
              try {
                await toggleAllEntitlements(subscriptionId, true);

                notify.success(
                  <>
                    Congratulations! Your class is now set up to use <strong>{name}</strong>.
                  </>,
                );
              } catch (e) {
                notify.warning('An error occurred. Please try again.');
              }
            }}
            entitledStudentsCount={entitledStudentsCount}
            key={id}
            isExpanded={id === expandedCardId}
            name={name}
            onCaretClick={() => setExpandedCardId(id === expandedCardId ? undefined : id)}
            studentsWithEntitlementData={studentsWithEntitlementData}
            thumbnail={thumbnail}
            toggleStudentEnrollment={createToggleStudentEnrollment(subscriptionId)}
          />
        ),
      )}
    </div>
  );
};

export default ClassDetailsApps;
