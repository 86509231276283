export interface DownloadCsvProps {
  /**
   * Name for the downloaded csv file
   */
  fileName: string;

  /**
   * Headings
   */
  headings: string[];

  /**
   * Rows
   */
  rows?: string[][];
}

export const downloadCsv = ({ headings, rows = [[]], fileName }: DownloadCsvProps) => {
  const csvString = [headings.join(','), ...rows.map(row => row.join(','))].join('\n');
  const fileNameWithExtension = `${fileName}.csv`;

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(
      new Blob([csvString], { type: 'text/csv;charset=utf-8;' }),
      fileNameWithExtension,
    );
  } else {
    const element = document.createElement('a');

    element.setAttribute('style', 'display: none');
    element.href = `data:application/octet-stream;base64,${btoa(csvString)}`;
    element.download = fileNameWithExtension;
    element.target = '_blank';
    element.click();
  }
};

export default downloadCsv;
