import React, { useEffect } from 'react';
import { Control, Controller, FieldError, UseFormMethods } from 'react-hook-form';
import { FormFeedback, SelectList } from '@scholastic/volume-react';
import { DeepMap } from 'react-hook-form/dist/types/utils';

import { TeacherClassCreateFormData } from './TeacherClassCreate';
import { CalendarSelectOption, UseCalendarsReturn } from './use-calendars';

export interface SelectCalendarProps extends UseCalendarsReturn {
  baseClass: string;
  control: Control<TeacherClassCreateFormData>;
  errors: DeepMap<TeacherClassCreateFormData, FieldError>;
  setValue: UseFormMethods<TeacherClassCreateFormData>['setValue'];
}

export const SelectCalendar = ({
  baseClass,
  errors,
  control,
  setValue,
  calendarOptions,
  selectedCalendarOption,
  setSelectedCalendarOption,
}: SelectCalendarProps) => {
  useEffect(() => {
    if (!selectedCalendarOption) return;

    setValue('schoolCalendarId', selectedCalendarOption.value);
  }, [selectedCalendarOption, setValue]);

  return (
    <>
      <div className={`${baseClass}__select`}>
        <label
          className={`${baseClass}__select-label`}
          htmlFor="schoolCalendarId"
          id="teacher-class-create-school-calendar"
        >
          School Year
        </label>

        <Controller
          control={control}
          name="schoolCalendarId"
          render={() => (
            <SelectList
              aria-labelledby="teacher-class-create-school-calendar"
              placeholder="Choose a school year"
              className={`${baseClass}__select-input`}
              disabled={!calendarOptions.length}
              invalid={!!errors.schoolCalendarId}
              options={calendarOptions}
              value={selectedCalendarOption}
              onChange={(option: CalendarSelectOption) => setSelectedCalendarOption(option)}
            />
          )}
        />
      </div>

      <FormFeedback className={`${baseClass}__feedback`}>
        {errors.schoolCalendarId?.message}
      </FormFeedback>
    </>
  );
};

export default SelectCalendar;
