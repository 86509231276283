import React from 'react';
import { createConfirmationDialog } from '@scholastic/volume-react';

import {
  ClassDetailsPageHeaderProps,
  notifyRestoreClassError,
  notifyRestoreClassSuccess,
} from './ClassDetailsPageHeader';

export interface CreateRestoreClassDialogProps {
  classDisplayName: ClassDetailsPageHeaderProps['classDisplayName'];
  setActive: ClassDetailsPageHeaderProps['setActive'];
}

export const createRestoreClassDialog = ({
  classDisplayName,
  setActive,
}: CreateRestoreClassDialogProps) =>
  createConfirmationDialog({
    title: (
      <>
        Restore Class <strong>{classDisplayName}</strong>.
      </>
    ),
    message: 'Are you sure that you want to restore the class?',
    confirmButtonTitle: 'Restore',
    cancelButtonTitle: 'Cancel',
    onConfirm: async () => {
      try {
        await setActive(true);

        notifyRestoreClassSuccess(classDisplayName || '');
      } catch (e) {
        notifyRestoreClassError(classDisplayName || '');
      }
    },
  });

export default createRestoreClassDialog;
