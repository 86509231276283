import React, { FunctionComponent, ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import {
  Application,
  Button,
  Container,
  FabItem,
  FabMenu,
  ModalSizes,
  Notifier,
  OrganizationAndApplications,
  PageHeader,
  PageHeaderProps,
  PageLoader,
  ResponsivityProvider,
  SubscriptionData,
  notify,
  useClasses,
  useStoreItem,
  useWindowQuery,
  FeaturesSchema,
  UserType,
  FeaturesMap,
  UserTypeFeatureFlagsProvider,
} from '@scholastic/volume-react';

import useTeacherProgramsApi from '../../behaviors/use-api/use-teacher-programs-api/use-teacher-programs-api';

import ProgramCardListEmpty from '../../components/ProgramCardList/ProgramCardListEmpty/ProgramCardListEmpty';
import RedeemAccessModal, {
  RedeemAccessModalFormState,
  RedeemAccessModalMetaData,
  RedeemedSubscription,
  School,
  Settings,
} from '../../components/RedeemAccessModal/RedeemAccessModal';

import isClassroomMagazine from '../../utils/is-classroom-magazine';

import TeacherCurriculumResourceList from './TeacherCurriculumResourceList';
import TeacherClassroomMagazineList from './TeacherClassroomMagazineList';

import './TeacherPrograms.scss';

export type SchoolWithoutId = Omit<School, 'id'>;

export interface TeacherProgramsProps {
  onOrgSelected: PageHeaderProps['onOrgSelected'];
  onCardClick: (e: SyntheticEvent, application: Application) => void;
  redeemAccess: (accessCode: string, ucn?: string) => Promise<RedeemedSubscription>;
  getSettings: () => Promise<Settings>;
  searchOrgsByZip: (zip: string) => Promise<SchoolWithoutId[]>;
  confirmSubscription: (
    formState: RedeemAccessModalFormState,
    metaData: RedeemAccessModalMetaData,
  ) => Promise<void>;
  onSavePassCode: (e: SyntheticEvent) => void;
  setInitialized: (initialized: boolean) => void;
  getIsAddNewProgramDisabled: (orgId: string | null) => Promise<boolean>;
}

export const teacherProgramsFeaturesSchema: FeaturesSchema<UserType> = {
  canUseClassPasscode: ['manual'],
};

export interface TeacherProgramsFeatures
  extends FeaturesMap<UserType, typeof teacherProgramsFeaturesSchema> {
  canUseClassPasscode: boolean;
}

export const TeacherPrograms: FunctionComponent<TeacherProgramsProps> = ({
  onOrgSelected,
  onCardClick,
  onSavePassCode,
  redeemAccess,
  getSettings,
  searchOrgsByZip,
  confirmSubscription,
  setInitialized,
  getIsAddNewProgramDisabled,
}: TeacherProgramsProps) => {
  const baseClass = 'sdm-teacher-programs';

  const [applications, setApplications] = useState<Application[]>();
  const [curriculumResources, setCurriculumResources] = useState<SubscriptionData[]>();
  const [classroomMagazines, setClassroomMagazines] = useState<SubscriptionData[]>();

  const [orgId] = useStoreItem('dpOrgId');

  const { entitlementsResponseData } = useTeacherProgramsApi();

  const { responsiveClassName, isMedium, isLarge, isExtraLarge } = useWindowQuery({
    className: baseClass,
  });

  const [isEmpty, setIsEmpty] = useState(true);

  const [redeemAccessModalOpen, setRedeemAccessModalOpen] = useState(false);
  const [redeemAccessModalSize, setRedeemAccessModalSize] = useState<ModalSizes>('small');

  const classes = useClasses({ baseClass }, [
    responsiveClassName,
    isEmpty && 'sdm-teacher-programs--empty',
  ]);

  const toggleRedeemAccessModal = () => setRedeemAccessModalOpen(!redeemAccessModalOpen);
  const showSuccessMessage = (message: ReactNode) => notify.success(message, {});

  useEffect(() => {
    if (isMedium) {
      setRedeemAccessModalSize('small');
      return;
    }

    if (isLarge) {
      setRedeemAccessModalSize('medium');
      return;
    }

    if (isExtraLarge) {
      setRedeemAccessModalSize('large');
      return;
    }

    setRedeemAccessModalSize('extra-small');
  }, [isMedium, isLarge, isExtraLarge]);

  useEffect(() => {
    setInitialized(false);

    if (entitlementsResponseData && entitlementsResponseData.length) {
      const orgEntitlements = entitlementsResponseData.find(
        (entitlement: OrganizationAndApplications) => entitlement.org.id === orgId,
      );

      if (orgEntitlements) {
        setApplications(
          orgEntitlements.applications.filter(
            (application: Application) => application.appType !== 'navbar',
          ),
        );
      } else {
        setApplications([]);
      }
    }

    setInitialized(true);

    return () => {
      setInitialized(false);
      setApplications(undefined);
      setCurriculumResources(undefined);
      setClassroomMagazines(undefined);
    };
  }, [entitlementsResponseData, orgId, setInitialized]);

  useEffect(() => {
    if (!applications) {
      return;
    }

    if (!applications.length) {
      setIsEmpty(true);

      return;
    }

    const curriculumResourcesArr: SubscriptionData[] = [];
    const classroomMagazinesArr: SubscriptionData[] = [];

    setIsEmpty(false);

    applications.forEach((application: Application) => {
      // We use empty subscription and product objects because the ProgramCard is built to accept
      // them, but the entitlements endpoint does not include them. We need an updated
      // entitlements endpoint that includes the product data, and to make the subscription prop
      // on the ProgramCard accept either response type

      const { id, name, rosterSupport } = application;

      const product = {
        subscription: {
          id,
          subscriptionProductId: id,
          startDate: '',
          expireDate: '',
          expired: false,
          gracePeriodDays: 0,
          rosterSource: '',
          teacherDirected: false,
          license: { type: '', quantity: 0, remaining: 0 },
        },
        product: { id, name },
        application,
      };

      if (isClassroomMagazine(rosterSupport)) {
        classroomMagazinesArr.push(product);
      } else {
        curriculumResourcesArr.push(product);
      }
    });

    setCurriculumResources(curriculumResourcesArr);
    setClassroomMagazines(classroomMagazinesArr);
  }, [applications, setInitialized]);

  const [isAddNewProgramDisabled, setIsAddNewProgramDisabled] = useState(false);

  useEffect(() => {
    (async () => setIsAddNewProgramDisabled(await getIsAddNewProgramDisabled(orgId)))();
  }, [getIsAddNewProgramDisabled, orgId]);

  return (
    <>
      <PageHeader filterByRole showWelcomeMessage onOrgSelected={onOrgSelected}>
        {!isAddNewProgramDisabled && (
          <FabMenu placement="bottom">
            <FabItem onClick={toggleRedeemAccessModal}>Add new Program</FabItem>
          </FabMenu>
        )}
      </PageHeader>

      <div className={classes} role="main">
        <Container className="sdm-teacher-programs__container">
          {applications ? (
            <>
              {isEmpty ? (
                <ProgramCardListEmpty
                  className="sdm-teacher-programs__empty-splash"
                  isAddNewProgramDisabled={isAddNewProgramDisabled}
                >
                  <Button color="primary" onClick={toggleRedeemAccessModal}>
                    ADD PROGRAM
                  </Button>
                </ProgramCardListEmpty>
              ) : (
                <>
                  {curriculumResources && !!curriculumResources.length && (
                    <div className="sdm-teacher-programs__curriculum-resources">
                      <TeacherCurriculumResourceList
                        subscriptions={curriculumResources}
                        onCardClick={onCardClick}
                        onProgramCardClick={toggleRedeemAccessModal}
                        isAddNewProgramDisabled={isAddNewProgramDisabled}
                      />
                    </div>
                  )}

                  {classroomMagazines && !!classroomMagazines.length && (
                    <div className="sdm-teacher-programs__classroom-magazines">
                      <TeacherClassroomMagazineList
                        subscriptions={classroomMagazines}
                        onCardClick={onCardClick}
                        onProgramCardClick={toggleRedeemAccessModal}
                        onSavePassCode={(e: SyntheticEvent) => {
                          e.preventDefault();
                          showSuccessMessage('Successfully updated class passcode');
                          onSavePassCode(e);
                        }}
                        isAddNewProgramDisabled={isAddNewProgramDisabled}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <PageLoader size="large">Loading</PageLoader>
            </div>
          )}

          <RedeemAccessModal
            isOpen={redeemAccessModalOpen}
            size={redeemAccessModalSize}
            toggle={toggleRedeemAccessModal}
            redeemAccess={redeemAccess}
            showSuccessMessage={showSuccessMessage}
            getSettings={getSettings}
            searchOrgsByZip={searchOrgsByZip}
            confirmSubscription={confirmSubscription}
          />
        </Container>
      </div>
    </>
  );
};

export const TeacherProgramsWrapper = (props: TeacherProgramsProps) => (
  <ResponsivityProvider>
    <UserTypeFeatureFlagsProvider featuresSchema={teacherProgramsFeaturesSchema}>
      <TeacherPrograms {...props} />

      <Notifier />
    </UserTypeFeatureFlagsProvider>
  </ResponsivityProvider>
);

export default TeacherProgramsWrapper;
