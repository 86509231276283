import React, { useState } from 'react';
import classNames from 'classnames';
import { Control, Controller, FieldError, useFieldArray, UseFormMethods } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import {
  Button,
  FormFeedback,
  PersonSearchOptionData,
  TeacherSearch,
  useDeepEqualEffect,
} from '@scholastic/volume-react';

import TrashIconURL from '../../assets/img/icon-trash-dark.svg';
import { getEmptyFormTeacher, TeacherClassCreateFormData } from './TeacherClassCreate';
export interface AddCoTeacherProps {
  baseClass: string;
  control: Control<TeacherClassCreateFormData>;
  errors: DeepMap<TeacherClassCreateFormData, FieldError>;
  setValue: UseFormMethods<TeacherClassCreateFormData>['setValue'];
  existingCoTeachers?: PersonSearchOptionData[];
  isEditMode: boolean;
  filterTeacherId?: string;
}

const MIN_FIELDS = 1;
const MAX_FIELDS = 5;

export const AddCoTeachers = ({
  baseClass,
  control,
  errors,
  setValue,
  existingCoTeachers = [],
  isEditMode,
  filterTeacherId,
}: AddCoTeacherProps) => {
  const [selectedTeachers, setSelectedTeachers] = useState<PersonSearchOptionData[]>(
    existingCoTeachers,
  );

  useDeepEqualEffect(() => setSelectedTeachers(existingCoTeachers), [existingCoTeachers]);

  const { fields, append, remove } = useFieldArray({ name: 'coTeachers', control });

  // show remove button only when there's a single populated
  // control OR when there is more than a single field present
  const shouldShowRemoveButton =
    (fields.length <= MIN_FIELDS && !!selectedTeachers[0]) || fields.length > MIN_FIELDS;
  return (
    <>
      {fields.map(({ id }, index, { length: arrayLength }) => {
        const name = `coTeachers[${index}].value`;
        const labelId = `teacher-class-create-co-teacher-label-${id}`;

        return (
          <div
            key={id}
            className={classNames(`${baseClass}__add-co-teacher`, {
              [`${baseClass}__add-co-teacher--no-margin`]: index === arrayLength - 1,
              [`${baseClass}__add-co-teacher--no-remove-button`]: !shouldShowRemoveButton,
            })}
          >
            <label className={`${baseClass}__input-label`} htmlFor={name} id={labelId}>
              <span className={`${baseClass}__input-label-text`}>Co-teacher (optional)</span>

              <Controller
                defaultValue={isEditMode ? undefined : getEmptyFormTeacher().value}
                control={control}
                name={name}
                render={({ onChange }) => (
                  <TeacherSearch
                    // passing in a key makes the underlying <ReactSelect />
                    // component to re-mount, thereby clearing the selected value
                    // @see https://stackoverflow.com/a/55142916
                    key={JSON.stringify(selectedTeachers[index])}
                    aria-labelledby={labelId}
                    placeholder="Search email"
                    onChange={(option: PersonSearchOptionData) => {
                      setSelectedTeachers(prev => {
                        const arr = [...prev];

                        arr[index] = option;

                        return arr;
                      });

                      onChange({ value: option?.value, email: option?.dataPoints?.[0] });
                    }}
                    // register the input value on every keystroke for validation purposes
                    onInputChange={(value: string) => onChange({ value: '', email: value })}
                    value={selectedTeachers[index]}
                    filterTeacherId={filterTeacherId}
                    noOptionsMessage={() => 'No teachers found'}
                  />
                )}
              />
            </label>

            {shouldShowRemoveButton && (
              <button
                className={`${baseClass}__remove-button`}
                type="button"
                onClick={() => {
                  if (fields.length <= MIN_FIELDS) {
                    setValue(name, getEmptyFormTeacher().value);

                    setSelectedTeachers([]);
                  } else {
                    remove(index);

                    setSelectedTeachers(prev => [
                      ...prev.slice(0, index),
                      ...prev.slice(index + 1, prev.length),
                    ]);
                  }
                }}
                aria-label="Remove"
              >
                <img className={`${baseClass}__remove-icon`} src={TrashIconURL} alt="Trash Icon" />
              </button>
            )}

            <FormFeedback className={`${baseClass}__feedback`}>
              {errors.coTeachers?.[index]?.value?.email?.message}
            </FormFeedback>
          </div>
        );
      })}

      <div className={`${baseClass}__section ${baseClass}__section--margin-small`}>
        {fields.length < MAX_FIELDS && (
          <Button
            className={`${baseClass}__add-teacher`}
            type="button"
            color="default"
            link
            onClick={() => append({})}
          >
            Add a teacher
          </Button>
        )}
      </div>
    </>
  );
};

export default AddCoTeachers;
