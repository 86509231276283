import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Student } from '@scholastic/volume-react';

export interface StudentHandoutsContentProps {
  students: Student[]
}

export const StudentHandoutsContent = forwardRef<HTMLDivElement, StudentHandoutsContentProps>(({ students = [] }, ref) => {
  return (
    <div className="sdm-student-handouts">
      <div ref={ref}>
        {students.map(({ firstName, lastName, credentials: { username, password } }, index) => (
          <div key={index} className={classNames({ last: index === students.length - 1 })}>
            <div className="sdm-student-handouts__logo">
              <img src="resources/images/logo-schl.png" alt="Scholastic Logo" />
            </div>

            <div className="sdm-student-handouts__print-info">
              <div className="sdm-student-handouts__section">Hello Families!</div>

              <div className="sdm-student-handouts__section">
                Did you know that{' '}
                <span className="sdm-student-handouts__color-text sdm-student-handouts__bold-text">
                  {firstName} {lastName}
                </span>{' '}
                has access to Scholastic digital programs through their school? These programs
                live on <strong>Scholastic Digital Manager</strong>, where your child can sign
                in and make the most of their digital learning—at school <strong>and</strong> at
                home!
              </div>

              <div className="sdm-student-handouts__section">
                <strong>Scholastic Digital Manager</strong> can be accessed on most devices
                already used at home. Before you begin, please ensure your selected device
                matches the system requirements for whichever programs your child is using. You
                can find our system requirements in our Help & Resources:{' '}
                <strong>https://help.digital.scholastic.com/</strong>
              </div>

              <div className="sdm-student-handouts__section">
                <ol>
                  <li>
                    Open your browser and go to <strong>https://digital.scholastic.com</strong>
                  </li>
                  <li>
                    Bookmark this page the way you would bookmark any other website. This will
                    make it easier for your child to return to{' '}
                    <strong>Scholastic Digital Manager</strong> next time.
                  </li>
                  <li>
                    Sign in using your child’s username and password:
                    <div className="sdm-student-handouts__credentials">
                      <div className="sdm-student-handouts__credentials-sub-div sdm-student-handouts__credentials-sub-div--margin-right sdm-student-handouts__student-info">
                        <div className="sdm-student-handouts__student-details">
                          <div>USERNAME</div>
                          <strong>{username}</strong>
                        </div>

                        <div className="sdm-student-handouts__student-details">
                          <div>PASSWORD</div>
                          <strong>{password}</strong>
                        </div>
                      </div>

                      <div className="sdm-student-handouts__credentials-sub-div" />
                    </div>
                  </li>
                </ol>
              </div>

              <div className="sdm-student-handouts__section">
                <strong>HINT:</strong> You can also tell your browser to remember the username
                and password for the next time your child signs in!
              </div>

              <div className="sdm-student-handouts__section">
                If you have any questions about accessing{' '}
                <strong>Scholastic Digital Manager</strong> or the resources your child will be
                using, please reach out to your child’s teacher.
              </div>
            </div>

            <div className="sdm-student-handouts__page-break" />
            {/* IE hack in order to print the page break */}
            <div />
          </div>
        ))}
      </div>
    </div>
  )
});

export default StudentHandoutsContent;
