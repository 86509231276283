import React, { FunctionComponent, useState } from 'react';

import { useWindowQuery, useClasses } from '@scholastic/volume-react';
import VolumeComponentProps from '@scholastic/volume-react/dist/utils/props';

import emptyClasses from '../../../assets/img/empty-classes.png';
import emptyClasses2x from '../../../assets/img/empty-classes@2x.png';
import emptyClasses3x from '../../../assets/img/empty-classes@3x.png';

import './ClassCardListEmpty.scss';

export type ClassCardListEmptyProps = VolumeComponentProps & {
  canAddClass?: boolean;
};

export const ClassCardListEmpty: FunctionComponent<ClassCardListEmptyProps> = ({
  children,
  canAddClass,
}: ClassCardListEmptyProps) => {
  const baseClass = 'sdm-class-card-list-empty';
  const { responsiveClassName } = useWindowQuery({ className: baseClass });
  const classes = useClasses({ baseClass }, [responsiveClassName]);

  return (
    <div className={classes}>
      <img
        alt="Empty Programs List"
        src={`${emptyClasses}`}
        srcSet={`${emptyClasses2x} 2x, ${emptyClasses3x} 3x`}
        className="sdm-class-card-list-empty__img"
      />
      <h2 className="sdm-class-card-list-empty__text">
        Looks empty here.
        {canAddClass && (
          <>
            <br />
            <span>Let’s add your first class.</span>
          </>
        )}
      </h2>
      {children}
    </div>
  );
};

export default ClassCardListEmpty;
